import { CloudUploadOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import { parse, Options } from 'csv-parse/browser/esm/sync';
import React from 'react';
import styled from 'styled-components';
import StepDescription from '../StepDescription';
import StepTitle from '../StepTitle';

/**
 * A promisified version of csv-parse's parse function
 *
 * @param text The text to convert
 * @param options csv-parse module options
 */
const parseCsvPromisifed = (
  text: string,
  options: Options = {},
): Promise<string[][]> => new Promise((resolve, reject) => {
  resolve(parse(text, options))
});

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

function beforeUpload(file: File) {
  const smallFile = file.size / 1024 / 1024 < 1;
  if (!smallFile) {
    message.error('File must be smaller than 1MB!');
    return false;
  }
  return true;
}

interface UploadCsvProps {
  onFileUploaded: (csvData: string[][]) => void;
  onCancel: () => void;
}

const UploadCsv: React.FC<UploadCsvProps> = ({
  onFileUploaded,
  onCancel,
}) => {
  const onFileAttached = async (file: File) => {
    try {
      const parsedCsv = await parseCsvPromisifed(await file.text(), {
        skipRecordsWithEmptyValues: true,
        skipEmptyLines: true,
      });
      if (parsedCsv.length === 0) {
        message.error('Your file is empty!');
        return;
      }
      onFileUploaded(parsedCsv);
    } catch (err) {
      message.error(`Failed to read file (${(err as Error).message})`);
    }
  };

  return (
    <Wrapper>
      <StepTitle>Upload your board</StepTitle>
      <StepDescription>(Only .CSV files)</StepDescription>
      <Dragger
        accept=".csv"
        beforeUpload={beforeUpload}
        // Don't show the file uploading or upload complete since it's usually instant
        fileList={[]}
        style={{
          padding: '2rem 6rem',
          marginTop: '2.5rem',
          marginBottom: '2.5rem',
        }}
        customRequest={async ({ file }) => onFileAttached(file as File)}
      >
        <p className="ant-upload-drag-icon">
          <CloudUploadOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag files to this area to upload.
        </p>
      </Dragger>
      <Button
        onClick={onCancel}
      >
        Cancel
      </Button>
    </Wrapper>
  );
};

export default UploadCsv;

/* eslint-disable import/no-extraneous-dependencies */
import generateCalendar from 'antd/lib/calendar/generateCalendar';
import { Dayjs } from 'dayjs';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';

/**
 * A custom Calendar based off of Ant Design to accept dayjs objects rather than the default
 * moment objects
 *
 * https://ant.design/docs/react/replace-moment
 */
const Calendar = generateCalendar<Dayjs>(dayjsGenerateConfig);

export default Calendar;

import JobAppSearchResult from '../../types/JobAppSearchResult';
import { getJson } from '../xhr';


export default function xhrGetJobApps(
  search: string,
  accessToken: string,
): Promise<JobAppSearchResult[]> {
  return getJson(`/jobapps?search=${encodeURIComponent(search)}`, accessToken);
}

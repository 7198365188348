import { Drawer, Space, Typography } from "antd"
import { useContext, useState } from "react"
import { AppOrganizationViewContext } from "../../../../contexts/AppOrganizationViewProvider"
import { useOrganizationGoals } from "../../hooks"
import { GoalStatus } from "../../types"
import { OrganizationGoalCardList } from "../OrganizationGoalCardList"
import { OrganizationGoalNestedDrawerContent } from "../OrganizationGoalNestedDrawerContent"
import { OrganizationGoalsHeader } from "../OrganizationGoalsHeader"

enum GoalViewType {
  Current = 'current',
  Past = 'past'
}

interface SelectedGoal {
  id: string;
  title: string;
  description: string | null;
  status: GoalStatus;
  dueTimestamp?: number | null;
  canBeUpdated: boolean;
  recurringInterval?: {
    unit: string;
    value: number;
  } | null;
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const OrganizationGoalsMobile = ({
  isOpen,
  onClose
}: Props) => {
  const { selectedOrganization } = useContext(AppOrganizationViewContext)
  const [viewGoalType, setViewGoalType] = useState<GoalViewType>(GoalViewType.Current)
  const [selectedGoal, setSelectedGoal] = useState<SelectedGoal | null>(null)
  const { data, status, fetchNextPage, hasNextPage, isFetchingNextPage } = useOrganizationGoals({
    filters: {
      organizationId: selectedOrganization?.id || '',
      type: viewGoalType,
    },
    limit: 25,
    offset: 0
  })

  const goalsToList = data?.pages.map((p) => p.results).flat()

  return <Drawer 
    placement="right"
    title={<OrganizationGoalsHeader 
      onChangeViewType={(type) => setViewGoalType(type)}
      viewType={viewGoalType}
    />}
    open={isOpen}
    onClose={onClose}
    
  >
    <Space direction="vertical" size="middle">
      <Typography.Text>
        {viewGoalType === GoalViewType.Current && 'These are goals that you currently have to complete.'}
        {viewGoalType === GoalViewType.Past && 'These are goals that are either completed or are past their due date.'}
      </Typography.Text>
      <div style={{
        paddingBottom: '24px'
      }}>
        <OrganizationGoalCardList
          emptyText={viewGoalType === GoalViewType.Current ? 'You have no goals to complete' : 'You have no past goals'}
          goals={goalsToList || []}
          hasMore={hasNextPage}
          isFetchingMore={isFetchingNextPage}
          loading={status === 'loading'}
          onClickFetchMore={() => fetchNextPage()}
          onClickGoal={(goal) => setSelectedGoal({
            id: goal.id,
            title: goal.name,
            description: goal.checklistTaskTemplate.description,
            status: goal.status,
            dueTimestamp: goal.dueTimestamp,
            canBeUpdated: goal.canBeUpdated,
            recurringInterval: goal.checklistTaskTemplate.recurringInterval
          })}
        />
      </div>
    </Space>
    <Drawer 
      width={300}
      onClose={() => setSelectedGoal(null)}
      open={!!selectedGoal}
    >
      {selectedGoal && <OrganizationGoalNestedDrawerContent
        title={selectedGoal.title}
        description={selectedGoal.description}
        id={selectedGoal.id}
        dueTimestamp={selectedGoal.dueTimestamp}
        status={selectedGoal.status}
        canBeUpdated={selectedGoal.canBeUpdated}
        onClickClose={() => setSelectedGoal(null)}
        recurringInterval={selectedGoal.recurringInterval}
      />}
    </Drawer>
  </Drawer>
}

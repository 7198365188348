import { Button, Form, Input } from 'antd';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { UserContext } from '../../../contexts/UserProvider';
import generateFullName from '../../../util/generateFullName';

const LabelStyles = styled.span`
  font-weight: 600;
`

const SettingsAccountNameForm: React.FC = () => {
  const [form] = Form.useForm();
  const { userProfile, updateDisplayName } = useContext(UserContext);
  const [editingDisplayName, setEditingDisplayName] = useState(false);
  const [saving, setSaving] = useState(false);

  const onDisplayNameSubmit = async ({ firstName, lastName }: { firstName: string, lastName: string }) => {
    if (generateFullName(firstName, lastName) === userProfile?.fullName) {
      setEditingDisplayName(false);
      return;
    }
  
    setSaving(true);
    await updateDisplayName({
      firstName,
      lastName
    });
    setSaving(false);
    setEditingDisplayName(false);
  };

  const onCancel = () => {
    setEditingDisplayName(false);
    form.resetFields();
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={(vals: any) => onDisplayNameSubmit(vals)}
    >
      {!editingDisplayName && (
        <>
          <Form.Item label={<LabelStyles>Name</LabelStyles>}>
            <span className="ant-form-text">{userProfile?.fullName}</span>
            <Button onClick={() => setEditingDisplayName(true)}>
            Edit
            </Button>
          </Form.Item>
        </>
      )}
      {editingDisplayName && (
        <>
          <Form.Item
            label="First name"
            name="firstName"
            initialValue={userProfile?.firstName || ''}
            rules={[{ required: true, message: 'Please input your first name' }]}
          >
            <Input placeholder={userProfile?.lastName} />
          </Form.Item>
          <Form.Item
            label="Last name"
            name="lastName"
            initialValue={userProfile?.lastName || ''}
            rules={[{ required: true, message: 'Please input your last name' }]}
          >
            <Input placeholder={userProfile?.lastName} />
          </Form.Item>
          <Form.Item>
            <Button
              disabled={saving}
              type="primary"
              htmlType="submit"
              style={{ marginRight: '0.5rem' }}
            >
            Save
            </Button>
            <Button disabled={saving} onClick={onCancel}>
            Cancel
            </Button>
          </Form.Item>
        </>
      )}
    </Form>
  );
};

export default SettingsAccountNameForm;

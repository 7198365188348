import { useContext } from "react";
import { UserContext } from "../../../contexts/UserProvider";
import {
  MenuSideBarPersonalCardAvatar,
  MenuSideBarPersonalCardName,
} from "./MenuSideBarPersonalCard.styles"
import {Space} from 'antd'

const MenuSideBarPersonalCard: React.FC = () => {
  const { userProfile } = useContext(UserContext);

  return (
    <Space size="middle" style={{
      paddingLeft: '1rem'
    }}>
      <MenuSideBarPersonalCardAvatar src={userProfile?.picture}>
        {userProfile?.fullName?.slice(0, 1).toUpperCase()}
      </MenuSideBarPersonalCardAvatar>
      <MenuSideBarPersonalCardName>
        {userProfile?.fullName}
      </MenuSideBarPersonalCardName>
    </Space>
  )
}
  
export default MenuSideBarPersonalCard
  
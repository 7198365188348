import CopyBoardToOrgBody from '../../types/CopyBoardToOrgBody';
import { postForJson } from '../xhr';

export default function xhrCopyobAppBoardToOrg(
  boardId: string,
  details: CopyBoardToOrgBody,
  accessToken: string,
) {
  return postForJson(`/jobappboards/${boardId}/to-organization`, details, accessToken);
}

import React, { useContext, useState } from 'react';
import { Dropdown, Button } from 'antd';
import { InferProps } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { jobAppPropTypes } from '../types/jobApp';
import notifyError from '../../../util/notifyError';
import { JobAppContext } from '../../../contexts/JobAppProvider';
import { JobAppBoardContext } from '../../../contexts/JobAppBoardProvider';
import { JobAppColumnContext } from '../../../contexts/JobAppColumnProvider';

function JobAppStatusDropdown({ jobApp }: InferProps<typeof JobAppStatusDropdown.propTypes>) {
  const [changing, setChanging] = useState(false);
  const { updateJobApp, updateJobAppVisualPosition } = useContext(JobAppContext);
  const { activeBoardId } = useContext(JobAppBoardContext);
  const { jobAppColumns } = useContext(JobAppColumnContext);
  const column = jobAppColumns?.find((c) => c.id === jobApp?.columnId);

  async function edit(columnId: string) {
    if (!activeBoardId) {
      throw new Error('Missing active board ID for edit job');
    }
    if (jobApp.columnId === columnId) {
      return;
    }
    setChanging(true);
    try {
      const payload = {
        sourceColumnId: jobApp.columnId,
        jobAppId: jobApp.id,
        destColumnId: columnId as string,
        boardId: activeBoardId,
      };
      await updateJobApp(jobApp.id, {
        columnId,
      });
      updateJobAppVisualPosition(payload);
    } catch (err) {
      notifyError('Unable to edit job app status', (err as Error).message);
    } finally {
      setChanging(false);
    }
  }

  return (
    <Dropdown
      menu={{
        items: jobAppColumns?.map((col) => ({
          key: col.id,
          label: col.name,
          onClick: () => edit(col.id),
        })),
      }}
      disabled={!jobAppColumns || jobAppColumns.length <= 1 || changing}
      trigger={['click']}
    >
      <Button
        block
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {column?.name}
        </span>
        <FontAwesomeIcon icon={faChevronDown} style={{ color: 'rgba(0, 0, 0, 0.25)' }} />
      </Button>
    </Dropdown>
  );
}

JobAppStatusDropdown.propTypes = {
  jobApp: jobAppPropTypes.isRequired,
};

export default JobAppStatusDropdown;

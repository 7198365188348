import { deleteFor } from '../xhr';

export default function xhrRemoveContactFromJobApp(
  jobAppId: string,
  contactId: string,
  accessToken: string,
) {
  return deleteFor(
    `/jobapps/${jobAppId}/contacts/${contactId}`,
    accessToken,
  );
}

import JobAppInterview from '../../types/JobAppInterview';
import { postForJson } from '../xhr';

export default function xhrCreateInterview(
  details: {
    title: string,
    date: string,
    jobAppId: string
  },
  accessToken: string,
): Promise<JobAppInterview> {
  return postForJson(
    `/interviews`,
    details,
    accessToken,
  );
}

import { Layout } from "antd";
import styled from "styled-components";
import themeColors from "../../../constants/themeColors";

export const BoardContainer = styled(Layout)`
  display: flex;
  flex-direction: column;
  /** 45px for the top header */
  height: calc(100vh - 50px);
  overflow: hidden;
  background: ${themeColors.background.faintBlue};
`;

export const BoardManagementContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
  padding: 30px 30px 0 30px;
`

export const BoardColumnContainer = styled.div`
  display: flex;
  overflow-x: auto;
  flex-grow: 1;
  height: 100%;
`;

export const BoardPaddedInnerWrap = styled.div`
  display: flex;
  /** Must match the parent page's margin */
  padding-right: 30px;
  padding-left: 30px;
`;

export const BoardDroppableColumnContainer = styled.div`
  display: flex;
  height: 100%;
`;

export const BoardSearchContainer = styled.div`
  margin: 0 15px 15px 0px;
  padding-left: 30px;
  padding-right: 30px;
`;

export const BoardNoSearchResultsFoundContainer = styled.div`
  margin: 20px auto 0 auto;
  padding-right: calc(5 % - 70px); /* minus the parent's padding */
  color: #595959;
  font-size: 0.95rem;
`;
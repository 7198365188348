import { Drawer, Space, Typography } from "antd"
import Sider from "antd/es/layout/Sider"
import { transparentize } from "polished"
import { useContext, useState } from "react"
import { HEADER_HEIGHT } from "../../../../constants/headerHeight"
import themeColors from "../../../../constants/themeColors"
import { AppOrganizationViewContext } from "../../../../contexts/AppOrganizationViewProvider"
import { useOrganizationGoals } from "../../hooks"
import { GoalStatus } from "../../types"
import { OrganizationGoalCardList } from "../OrganizationGoalCardList"
import { OrganizationGoalNestedDrawerContent } from "../OrganizationGoalNestedDrawerContent"
import { OrganizationGoalsHeader } from "../OrganizationGoalsHeader"

enum GoalViewType {
  Current = 'current',
  Past = 'past'
}

interface SelectedGoal {
  id: string;
  title: string;
  description: string | null;
  status: GoalStatus;
  dueTimestamp?: number | null;
  canBeUpdated: boolean;
  recurringInterval?: {
    unit: string;
    value: number;
  } | null;
}

interface Props {
  siderBreakpoint: React.ComponentProps<typeof Sider>['breakpoint'];
}

export const OrganizationGoalsDesktop = ({ siderBreakpoint }: Props) => {
  const { selectedOrganization } = useContext(AppOrganizationViewContext)
  const [viewGoalType, setViewGoalType] = useState<GoalViewType>(GoalViewType.Current)
  const [selectedGoal, setSelectedGoal] = useState<SelectedGoal | null>(null)
  const { data, status, fetchNextPage, hasNextPage, isFetchingNextPage } = useOrganizationGoals({
    filters: {
      organizationId: selectedOrganization?.id || '',
      type: viewGoalType,
    },
    limit: 25,
    offset: 0
  })

  const goalsToList = data?.pages.map((p) => p.results).flat()

  return <Sider
    width={375}
    breakpoint={siderBreakpoint}
    collapsedWidth="0"
    zeroWidthTriggerStyle={{
      display: 'none'
    }}
    style={{
      background: 'white',
      height: `calc(100vh - ${HEADER_HEIGHT}px)`,
    }}
  >
    <div style={{
      position: 'relative',
      background: 'white',
      height: '100%',
      boxShadow: `0px 1px 20px ${transparentize(0.9, themeColors.background.dark)}`,
    }}>
      <div style={{
        padding: '24px',
      }}>
        <OrganizationGoalsHeader 
          viewType={viewGoalType}
          onChangeViewType={(type) => setViewGoalType(type)} 
        />
      </div>
      <Space direction="vertical" size="middle">
        <div style={{ padding: '0 24px 0 24px' }}>
          <Typography.Text>
            {viewGoalType === GoalViewType.Current && 'These are goals that you currently have to complete.'}
            {viewGoalType === GoalViewType.Past && 'These are goals that are either completed or are past their due date.'}
          </Typography.Text>
        </div>
        <div style={{
          height: 'calc(100vh - 200px)',
          overflow: 'auto',
          padding: '0 24px 24px 24px',
        }}>
        
          <OrganizationGoalCardList
            emptyText={viewGoalType === GoalViewType.Current ? 'You have no goals to complete' : 'You have no past goals'}
            goals={goalsToList || []}
            hasMore={hasNextPage}
            isFetchingMore={isFetchingNextPage}
            loading={status === 'loading'}
            onClickFetchMore={() => fetchNextPage()}
            onClickGoal={(goal) => setSelectedGoal({
              id: goal.id,
              title: goal.name,
              description: goal.checklistTaskTemplate.description,
              status: goal.status,
              dueTimestamp: goal.dueTimestamp,
              canBeUpdated: goal.canBeUpdated,
              recurringInterval: goal.checklistTaskTemplate.recurringInterval
            })}
          />
        </div>
      </Space>
      <Drawer 
        onClose={() => setSelectedGoal(null)}
        open={!!selectedGoal}
        getContainer={false}
        mask={false}
        closable={false}
      >
        {selectedGoal && <OrganizationGoalNestedDrawerContent
          title={selectedGoal.title}
          description={selectedGoal.description}
          id={selectedGoal.id}
          dueTimestamp={selectedGoal.dueTimestamp}
          status={selectedGoal.status}
          canBeUpdated={selectedGoal.canBeUpdated}
          onClickClose={() => setSelectedGoal(null)}
          recurringInterval={selectedGoal.recurringInterval}
        />}
      </Drawer>
    </div>
  </Sider>
}

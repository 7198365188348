import styled from 'styled-components';
import themeColors from '../../../constants/themeColors';

const StepTitle = styled.h1`
  margin-top: 1rem;
  color: ${themeColors.background.dark};
  font-weight: 500;
  font-size: 1.5rem;
`;

export default StepTitle;

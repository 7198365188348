import React from 'react';
import styled from 'styled-components';
import { transparentize } from 'polished';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import themeColors from '../../constants/themeColors';

const Column = styled.div`
  width: 335px;
  background: ${themeColors.background.white};
  color: ${themeColors.background.dark};
  margin-right: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  border: dashed 1px ${themeColors.background.gray};
  box-shadow: 0px 1px 20px ${transparentize(0.9, themeColors.background.dark)};
  font-size: 1rem;
  user-select: none;
  cursor: pointer;
`;

interface AddColumnBoxProps {
  onClickAddColumn: () => void
}

const AddColumnBox: React.FC<AddColumnBoxProps> = ({
  onClickAddColumn,
}) => {
  const addButton = (
    <Column onClick={onClickAddColumn} role="button" tabIndex={0}>
      <FontAwesomeIcon icon={faPlus} style={{ marginRight: '10px' }} />
      Add new list
    </Column>
  );

  return addButton;
};

export default AddColumnBox;

import { Button, Space } from "antd";
import { useState } from "react";
import styled from "styled-components";

const OrgWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 0.5rem;
`;

interface Props {
  organization: {
    id: string;
    name: string;
  }
  onAccept: () => Promise<void>;
  onDecline: () => Promise<void>;
}

export const InviteRow: React.FC<Props> = ({
  organization: {
    id,
    name,
  },
  onAccept,
  onDecline,
}) => {
  const [isAccepting, setIsAccepting] = useState<boolean>(false)
  const [isDeclining, setIsDeclining] = useState<boolean>(false)

  const onAccepted = async () => {
    setIsAccepting(true)
    onAccept()
    setIsAccepting(false)
  }

  const onDeclined = async () => {
    setIsDeclining(true)
    onDecline()
    setIsDeclining(false)
  }

  return (
    <OrgWrapper>
      <span style={{
        fontWeight: 500
      }}>
        {name}
      </span>
      <Space>
        <Button 
          size="middle"
          onClick={onAccepted} 
          type="primary"
          disabled={isDeclining || isDeclining}
          loading={isAccepting}
        >
          Accept
        </Button>
        <Button
          size="middle"
          onClick={onDeclined}
          disabled={isAccepting || isDeclining}
          loading={isDeclining}
          danger
        >
            Decline
        </Button>
      </Space>
    </OrgWrapper>
  )
}

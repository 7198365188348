import { faCircle as faCircleSolid } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider } from "antd";
import { useContext, useState } from "react";
import themeColors from "../../../../../constants/themeColors";
import { MemberNotificationsContext } from "../../../../../contexts/MemberNotificationsProvider";
import MemberNotification from "../../../../../types/MemberNotification";
import notifyError from "../../../../../util/notifyError";
import { NotificationContainer, NotificationSeenButton } from "./Notification.styles";
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { JobAppContext } from "../../../../../contexts/JobAppProvider";

dayjs.extend(utc)

interface Props {
  notification: MemberNotification;
  onClose: () => void;
}

const Notification: React.FC<Props> = ({
  notification,
  onClose,
}) => {
  const {
    updateNotification,
    notifications,
  } = useContext(MemberNotificationsContext)
  const { 
    setEditingJobAppId
  } = useContext(JobAppContext);

  const [updating, setUpdating] = useState(false)

  const isSeen = notification.status === 'seen'

  const onToggleSeen = async () => {
    if (!notifications) {
      return;
    }

    try {
      setUpdating(true)
      await updateNotification(notification.id, {
        status: isSeen ? 'unseen' : 'seen',
      })
    } catch (err) {
      console.error(err)
      notifyError(`Failed to update notification`, (err as Error).message)
    } finally {
      setUpdating(false)
    }
  }

  const onClickNotification = () => {
    if (!notification.jobAppId) {
      return;
    }

    setEditingJobAppId(notification.jobAppId)
    onClose()
  }

  const dividerStyle = {
    margin: '0.25rem 0'
  }

  return <>
    <NotificationContainer isSeen={isSeen}>
      <div 
        onClick={onClickNotification}
        role="button"
        tabIndex={0}
        style={{
          cursor: 'pointer',
        }}
      >
        <span>
          {notification.message}
        </span>
        <span style={{
          color: themeColors.background.gray,
          display: 'block',
          fontSize: '0.8rem'
        }}>
          {dayjs.utc(notification.scheduledAt).fromNow()}
        </span>
      </div>
      <NotificationSeenButton
        isSeen={isSeen}
        shape="circle"
        size="small"
        type="text"
        disabled={updating}
        onClick={onToggleSeen}
        icon={<FontAwesomeIcon
          icon={isSeen ? faCircle : faCircleSolid}
          size="1x"
        />} 
        style={{
          marginLeft: '0.5rem'
        }}
      />
    </NotificationContainer>
    <Divider style={dividerStyle} />
  </>
}

export default Notification;

import MemberNotification from '../../types/MemberNotification';
import { patchForJson } from '../xhr';


export default function xhrUpdateMemberNotification(
  id: string,
  updates: {
    status?: 'seen'|'unseen',
  },
  accessToken: string,
): Promise<MemberNotification> {
  return patchForJson(`/users/@me/member-notifications/${id}`, updates, accessToken);
}

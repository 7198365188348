/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary  */
import { useContext, useEffect, useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import browserTabVisiblityConstants from '../../constants/browerTabVisibility';
import chromeExtension from '../../constants/chromeExtension';
import { JobAppBoardContext } from '../../contexts/JobAppBoardProvider';
import { JobAppColumnContext } from '../../contexts/JobAppColumnProvider';
import { JobAppContext } from '../../contexts/JobAppProvider';
import { MemberNotificationsContext } from '../../contexts/MemberNotificationsProvider';
import JobApp from '../../types/JobApp';
import JobAppColumn from '../../types/JobAppColumn';
import * as Sentry from '@sentry/react'
interface BoardLogicProps {
  boardId?: string
}

const BoardLogic = ({ boardId }: BoardLogicProps) => {
  const [searchTerm, setSearchTerm] = useState('');
  const { getJobAppBoard, setActiveBoardId } = useContext(JobAppBoardContext);
  const {
    jobApps,
    setJobApps,
    getBoardJobApps,
    moveJobApp,
  } = useContext(JobAppContext);
  const {
    jobAppColumns: columns,
    setJobAppColumns: setColumns,
    getJobAppColumns,
    moveColumn,
  } = useContext(JobAppColumnContext);
  const {
    getNotifications,
    setNotifications,
  } = useContext(MemberNotificationsContext)
  const [jobAppsSearched, setJobAppsSearched] = useState<JobApp[]>([]);
  const [columnsSearched, setColumnsSearched] = useState<JobAppColumn[]|null>([]);
  const board = boardId && getJobAppBoard(boardId);

  // Used for other components that need access to the active board as a global variable
  useEffect(() => {
    setActiveBoardId(boardId || null);
  }, [boardId, setActiveBoardId]);

  // When we navigate away from this page, set data to null to indicate they must be fetched
  // It will let the skeleton board show as a result
  useEffect(() => () => {
    setColumns(null);
    setJobApps(null);
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);

  // Handle columns/board fetching when board ID changes
  useEffect(() => {
    if (!boardId) {
      setColumns(null);
      setJobApps(null);
      setSearchTerm('');
    } else {
      setColumns(null);
      setJobApps(null);
      getJobAppColumns(boardId).then((fetchedColumns) => {
        setColumns(fetchedColumns);
        return Promise.all(fetchedColumns.map((column) => getBoardJobApps(boardId, column.id)));
      }).then((jobAppsOfColumns) => {
        setJobApps(jobAppsOfColumns.flat());
      });
    }
  }, [boardId, getJobAppColumns, getBoardJobApps, setJobApps, setColumns]);

  // Handle search term
  useEffect(() => {
    if (!searchTerm || !jobApps) {
      return;
    }
    const matchingColumnIds = new Set();
    const matchingJobApps = jobApps.filter((app) => {
      const { company, title } = app;
      if (company.toLowerCase().includes(searchTerm.toLowerCase())) {
        matchingColumnIds.add(app.columnId);
        return true;
      }
      if (title && title.toLowerCase().includes(searchTerm.toLowerCase())) {
        matchingColumnIds.add(app.columnId);
        return true;
      }
      return false;
    });
    setJobAppsSearched(matchingJobApps);
    const matchingColumns = columns?.filter(({ id }) => matchingColumnIds.has(id));
    setColumnsSearched(matchingColumns || []);
  }, [searchTerm, boardId, columns, jobApps]);

  /**
   * When the user makes this tab active after adding a job via extension in another tab, update
   * the redux state
   */
  useEffect(() => {
    const {
      eventName,
      hidden,
    } = browserTabVisiblityConstants;

    const onTabActive = () => {
      // @ts-ignore - TS complains about an index signature on document that we don't need
      const documentIsHidden = document[hidden];
      if (documentIsHidden) {
        return;
      }
      // Other browsers will eventually be supported.
      const chromeExists = !!window.chrome;
      if (chromeExists) {
        chromeExtension.extensionIds.forEach((id) => {
          chrome.runtime.sendMessage(id, { foo: 1 }, (newJobApps?: JobApp[]) => {
            if (newJobApps && jobApps) {
              setJobApps([
                ...newJobApps,
                ...jobApps,
              ]);
            }
          });
        });
      }
    };

    window.addEventListener(eventName, onTabActive);
    return () => window.removeEventListener(eventName, onTabActive);
  }, [jobApps, setJobApps]);

  const onJobAppColumnMoved = async (result: DropResult) => {
    if (!boardId) {
      throw new Error('No boardId for job app column movement');
    }
    await moveColumn(boardId, result);
  };

  const onJobAppMoved = async (result: DropResult) => {
    if (!boardId) {
      throw new Error('No boardId for job app movement');
    }
    await moveJobApp(boardId, result);
    /**
     * Notifications may be created after a job app is moved into a specific column. Sometimes it
     * is instantly created, but since it is an async operation, we need to wait for it to be
     * created before we can get the notifications. 2000 ms is generally suffucient time to wait 
     * for the notification to be created.
     */
    setTimeout(() => {
      getNotifications()
        .then(setNotifications)
        .catch(err => {
          Sentry.captureException(err);
          console.error(`Failed to get notifications after job app moved`)
        })
    }, 2000)
    
  };

  return {
    board,
    jobAppsDisplayed: !searchTerm ? jobApps : jobAppsSearched,
    columnsDisplayed: !searchTerm ? columns : columnsSearched,
    searchTerm,
    setSearchTerm,
    onJobAppMoved,
    onJobAppColumnMoved,
  };
};

export default BoardLogic;

import React, { useState } from 'react';
import ConfirmModal from '../components/ConfirmModal';

export type ConfirmModalContextData = {
  okButton?: React.ReactElement,
  onOk: () => Promise<void>;
  title?: string;
  content?: string;
};

export type ConfirmModalContextState = {
  showConfirmModal: (data: ConfirmModalContextData) => void;
  hideConfirmModal: () => void;
};

export const ConfirmModalContext = React.createContext<ConfirmModalContextState>({
  showConfirmModal: () => {},
  hideConfirmModal: () => {},
});

type ConfirmModalState = ConfirmModalContextData & { visible: boolean };

const initialConfirmModalState: ConfirmModalState = {
  visible: false,
  onOk: async () => { },
};

export const ConfirmModalProvider: React.FC = ({ children }) => {
  const [confirmModalData, setConfirModalData] = useState(initialConfirmModalState);


  function showConfirmModal(data: ConfirmModalContextData) {
    setConfirModalData({
      visible: true,
      ...data,
    });
  }

  function hideConfirmModal() {
    setConfirModalData(initialConfirmModalState);
  }

  return <ConfirmModalContext.Provider value={{
    showConfirmModal,
    hideConfirmModal,
  }}>
    <ConfirmModal
      visible={confirmModalData.visible}
      confirmModalData={confirmModalData}
      onClose={hideConfirmModal}
    />
    {children}
  </ConfirmModalContext.Provider>;
}

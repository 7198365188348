import JobAppColumn from '../../types/JobAppColumn';
import { postForJson } from '../xhr';

export default function xhrCreateJobAppColumn(
  boardId: string,
  details: Partial<JobAppColumn>,
  accessToken: string,
) {
  return postForJson(`/jobappboards/${boardId}/columns`, details, accessToken);
}

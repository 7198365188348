import { useContext, useState } from "react";
import { JobAppInterviewContext } from "../../../../contexts/JobAppInterviewProvider";
import JobAppInterview from "../../../../types/JobAppInterview";
import notifyError from "../../../../util/notifyError";
import dayjs from 'dayjs'
import { DATE_FORMAT_WITH_TIME } from "../../../../constants/dateTimeFormats";
import { InterviewDateEditableDatePicker } from "./InterviewDateEditable.styles";

interface Props {
  interview: JobAppInterview;
}

const InterviewDateEditable: React.FC<Props> = ({
  interview,
}) => {
  const {
    updateJobAppInterview
  } = useContext(JobAppInterviewContext);
  const [showDate, setShowDate] = useState(interview.date)

  const saveChanges = async () => {
    try {
      await updateJobAppInterview(interview.id, {
        date: showDate
      })
    } catch (err) {
      notifyError(`Failed to update interview`, (err as Error).message)
      setShowDate(interview.date)
    }
  }

  return <InterviewDateEditableDatePicker
    allowClear={false}
    format={DATE_FORMAT_WITH_TIME}
    // @ts-ignore
    showTime={{ format: 'h:mm A' }}
    style={{
      boxShadow: 'none',
      padding: 0,
      background: 'none',
      border: 'none',
      cursor: 'pointer !important'
    }}
    defaultValue={dayjs(showDate).utc()}
    onChange={(date) => {
      if (!date) {
        /**
         * DatePicker typically allows you to clear dates, but we disallow that here with
         * allowClear={false}.
         */
        return;
      }
      setShowDate(date.utc().toISOString())
    }}
    suffixIcon={null}
    onBlur={saveChanges}
  />
}

export default InterviewDateEditable;

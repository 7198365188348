import { putFor } from '../xhr';

export default function xhrAttachJobAppDocument(
  boardId: string,
  columnId: string,
  jobAppId: string,
  documentId: string,
  accessToken: string,
) {
  return putFor(
    `/jobappboards/${boardId}/columns/${columnId}/jobapps/${jobAppId}/documents/${documentId}`,
    accessToken,
  );
}

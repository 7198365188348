import { useEffect } from 'react';
import './App.less';
import {
  RouteComponentProps, Router,
} from '@reach/router';
import { Helmet } from 'react-helmet';
import * as Sentry from '@sentry/react';
import Login from './js/pages/Login';
import JobAppTracker from './js/pages/JobAppTracker';
import pages from './js/constants/pages';
import CrashError from './js/pages/CrashError';
import GoogleAnalytics from './js/components/GoogleAnalytics';
import GenericError from './js/components/Error';
import useAuthZero from './js/hooks/useAuthZero';
import { getJson } from './js/util/fetchWrapper';
import APIRoutes from './js/constants/APIRoutes';
import { ConfirmModalProvider } from './js/contexts/ConfirmModalProvider';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GoogleAnalyticsRoute = (props: RouteComponentProps) => <GoogleAnalytics />;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LoginRoute = (props: RouteComponentProps) => <Login />;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const JobAppTrackerRoute = (props: RouteComponentProps) => {
  return <JobAppTracker />
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NotFoundRoute = (props: RouteComponentProps) => (
  <GenericError
    title="This page doesn't exist"
    description="Looks like you took a wrong turn!"
  />
);

function App() {
  const { user, getAccessToken } = useAuthZero();

  /**
   * Check if they're logged in, and if they are, check their mailchimp status
   */
  useEffect(() => {
    async function checkIfUserInMailchimp() {
      const accessToken = await getAccessToken('read')();
      if (!user?.sub) {
        return
      }

      try {
        await getJson(APIRoutes.user(user.sub).checkIfInMailchimp, accessToken);
      } catch (err) {
        Sentry.captureException(err);
      }
    }
    if (user) {
      checkIfUserInMailchimp();
    }
  }, [user, getAccessToken]);

  return (
    <ConfirmModalProvider>
      <div className="App">
        <Helmet titleTemplate="Stipplo - %s" defaultTitle="Stipplo - Job Application Tracker | Job Tracking Software" />
        {
          /**
           * Wrap the core app with a error boundary. If a crash
           * happens within this boundary, CrashError will be
           * shown and the details will be sent to Sentry
           */
        }
        <Sentry.ErrorBoundary fallback={CrashError}>
          {/** Render Google Analytics on every page change to track everything */}
          <Router primary={false}>
            <GoogleAnalyticsRoute path="*" />
          </Router>
          {/** Render the app contents based on route */}
          <Router>
            <LoginRoute path={pages.LOGIN} />
            <JobAppTrackerRoute default />
          </Router>
        </Sentry.ErrorBoundary>
      </div>
    </ConfirmModalProvider>
  );
}

export default App;

import React, { useCallback, useContext } from 'react';
import { UserContext } from './UserProvider';
import JobAppNote from '../types/JobAppNote';
import xhrCreateJobAppNote from '../adapters/jobAppNotes/xhrCreateJobAppNote';
import xhrUpdateJobAppNote from '../adapters/jobAppNotes/xhrUpdateJobAppNote';
import xhrDeleteJobAppNote from '../adapters/jobAppNotes/xhrDeleteJobAppNote';

type JobAppNoteContextData = {
  createJobAppNote: (data: { jobAppId: string, name: string }) => Promise<JobAppNote>
  updateJobAppNote: (id: string, data: {name?: string, text?: string}) => Promise<JobAppNote>
  deleteJobAppNote: (id: string) => Promise<void>
};

const defaultFunction = () => {
  throw new Error('Not implemented');
};

export const JobAppNoteContext = React.createContext<JobAppNoteContextData>({
  createJobAppNote: defaultFunction,
  updateJobAppNote: defaultFunction,
  deleteJobAppNote: defaultFunction,
});

export const JobAppNoteProvider: React.FC = ({ children }) => {
  const { getAccessToken } = useContext(UserContext);

  const createJobAppNote: JobAppNoteContextData['createJobAppNote'] = useCallback(async (data) => {
    const accessToken = await getAccessToken();
    return xhrCreateJobAppNote(accessToken, {
      jobAppId: data.jobAppId,
      name: data.name,
    });
  }, [getAccessToken]);

  const updateJobAppNote: JobAppNoteContextData['updateJobAppNote'] = useCallback(async (id, data) => {
    const accessToken = await getAccessToken();
    return xhrUpdateJobAppNote(accessToken, id, data)
  }, [getAccessToken]);

  const deleteJobAppNote: JobAppNoteContextData['deleteJobAppNote'] = useCallback(async (id) => {
    const accessToken = await getAccessToken();
    return xhrDeleteJobAppNote(accessToken, id);
  }, [getAccessToken]);

  return (
    <JobAppNoteContext.Provider value={{
      createJobAppNote,
      updateJobAppNote,
      deleteJobAppNote
    }}
    >
      {children}
    </JobAppNoteContext.Provider>
  );
};

import JobAppBoard from '../../types/JobAppBoard';
import { patchForJson } from '../xhr';

export default function xhrUpdateJobAppBoard(
  id: string,
  details: Partial<JobAppBoard>,
  accessToken: string,
) {
  return patchForJson(`/jobappboards/${id}`, details, accessToken);
}

import React, { useContext } from 'react';
import {
  Button,
  Form, Input, Space,
} from 'antd';
import { Store } from 'antd/lib/form/interface';
import { Helmet } from 'react-helmet';
import ThemedModal from '../ThemedModal';
import notifyError from '../../util/notifyError';
import { JobAppColumnContext } from '../../contexts/JobAppColumnProvider';

interface AddJobColumnModalProps {
  boardId?: string,
  onAdded: () => void
  visible: boolean
  onClose: () => void
}

const AddJobColumnModal: React.FC<AddJobColumnModalProps> = ({
  boardId,
  onAdded,
  onClose,
  visible,
}) => {
  const [form] = Form.useForm();
  const { createJobAppColumn } = useContext(JobAppColumnContext);
  function clear() {
    form.resetFields();
  }

  function resetAndClose() {
    clear();
    onClose();
  }

  async function onOk(data: Store) {
    if (!boardId) {
      throw new Error('No boardId defined for add job column');
    }
    try {
      await createJobAppColumn(boardId, {
        name: data.name,
      });
      resetAndClose();
      if (onAdded) {
        onAdded();
      }
    } catch (err) {
      notifyError('Unable to add job column', (err as Error).message);
    }
  }

  const formName = 'add-job-column';

  return (
    <ThemedModal
      title="Add a new column"
      open={visible}
      onCancel={resetAndClose}
      destroyOnClose
      okButtonProps={{
        htmlType: 'submit',
        form: formName,
      }}
      footer={(
        <Space>
          <Button
            onClick={resetAndClose}
          >
            Close
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            form={formName}
          >
            Add
          </Button>
        </Space>
      )}
    >
      <Helmet>
        <title>Add a new job list</title>
      </Helmet>
      <Form
        name={formName}
        onFinish={onOk}
        layout="vertical"
        form={form}
      >
        <Form.Item
          label="List Name"
          name="name"
          rules={[{
            required: true,
            message: 'Name is required',
          }, {
            type: 'string',
            max: 40,
            message: 'Name is too long',
          }]}
        >
          <Input
            autoFocus
            placeholder="Name of your new job list"
          />
        </Form.Item>
      </Form>
    </ThemedModal>
  );
};

export default AddJobColumnModal;

import React, { useState } from 'react';
import UploadArea from './UploadArea';
import ThemedModal from './ThemedModal';
import { Button } from 'antd';

function UploadDocumentButton({
  trigger,
}: { trigger: React.ReactElement }) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <ThemedModal
        open={showModal}
        onCancel={() => setShowModal(false)}
        destroyOnClose
        title="Upload your documents"
        cancelText="Close"
        footer={(
          <Button onClick={() => setShowModal(false)}>
            Close
          </Button>
        )}
      >
        <UploadArea />
      </ThemedModal>
      {React.cloneElement(trigger, {
        onClick: () => setShowModal(true),
      })}
    </>
  );
}

export default UploadDocumentButton;

import React, { useContext, useEffect, useState } from 'react';
import {
  Button as AntButton, Button, Form, Input, Popover,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import JobAppNote from '../../../../types/JobAppNote';
import { Store } from 'rc-field-form/lib/interface';
import { JobAppNoteContext } from '../../../../contexts/JobAppNoteProvider';
import JobApp from '../../../../types/JobApp';
import notifyError from '../../../../util/notifyError';

interface Props {
  onAddedNote: (note: JobAppNote) => void;
  jobApp: JobApp
}

const AddNotePopover: React.FC<Props> = ({
  onAddedNote,
  jobApp,
}) => {
  const {
    createJobAppNote,
  } = useContext(JobAppNoteContext);
  const [showPopover, setShowPopover] = useState(false);
  const [form] = Form.useForm();
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (!showPopover) {
      form.resetFields();
    }
  }, [form, showPopover]);

  const onFormFinish = async (data: Store) => {
    const {name} = data;

    try {
      setSaving(true);
      const createdNote = await createJobAppNote({
        jobAppId: jobApp.id,
        name,
      })
      onAddedNote(createdNote)
      setShowPopover(false)
    } catch (err) {
      notifyError(`Failed to add note`, (err as Error).message)
    } finally {
      setSaving(false)
    }
  }

  return (
    <Popover
      placement="bottomRight"
      trigger="click"
      onOpenChange={(visible) => setShowPopover(visible)}
      open={showPopover}
      content={(
        <Form
          form={form}
          onFinish={onFormFinish}
          layout="vertical"
        >
          <Form.Item
            style={{
              maxWidth: '200px'
            }}
            name="name"
            label="Note name"
            rules={[{
              required: true,
              message: '',
            }]}
          >
            <Input />
          </Form.Item>
          <Form.Item style={{
            marginBottom: 0,
            display: 'flex',
            justifyContent: 'center',
          }}
          >
            <Button
              htmlType="submit"              
              type="primary"
              style={{
                width: '100%',
              }}
              disabled={saving}
            >
                Add
            </Button>
          </Form.Item>
        </Form>
      )}
    >
      <AntButton
        onClick={() => setShowPopover(true)}
        icon={<FontAwesomeIcon icon={faPlus} />}
        size="middle"
      >
        <span style={{ paddingLeft: '0.5rem' }}>New</span>
      </AntButton>
    </Popover>
  );
};
export default AddNotePopover;

import styled from "styled-components";
import themeColors from "../../../../constants/themeColors";

const HTMLContent = styled.div`
  background: ${themeColors.background.white};
  padding: 10px;
  border: solid 1px ${themeColors.background.mediumGray};
  width: 100%;
  border-radius: 5px;
  overflow: auto;
  // The rich text editor has no margins on paragraphs so the div has to reflect this
  p {
    margin: 0;
  }
`

export default HTMLContent
import { Link, Redirect } from "@reach/router";
import { Avatar, Breadcrumb, Button, Skeleton, Space, Tabs } from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import HTMLContent from "../components/JobAppBoard/InterviewComponents/HTMLContent/HTMLContent";
import InterviewQuestionList from "../components/JobAppBoard/InterviewComponents/InterviewQuestionList/InterviewQuestionList";
import InterviewQuestionNewPrompt from "../components/JobAppBoard/InterviewComponents/InterviewQuestionList/InterviewQuestionNewPrompt/InterviewQuestionNewPrompt";
import RichTextFormInput from "../components/JobAppBoard/RichTextFormInput";
import pages from "../constants/pages";
import { JobAppInterviewContext } from "../contexts/JobAppInterviewProvider";
import { JobAppContext } from "../contexts/JobAppProvider";
import JobApp from "../types/JobApp";
import JobAppInterview from "../types/JobAppInterview";
import JobAppInterviewQuestion from "../types/JobAppInterviewQuestion";
import notifyError from '../util/notifyError'
import InterviewHeadingEditable from "../components/JobAppBoard/InterviewComponents/InterviewHeadingEditable/InterviewHeadingEditable";
import InterviewDateEditable from "../components/JobAppBoard/InterviewComponents/InterviewDateEditable/InterviewDateEditable";
import NotesList from "../components/JobAppBoard/EditJobAppModal/NotesList/NotesList";
import JobAppNote from "../types/JobAppNote";

interface RichEditorProps {
  jobApp: JobApp;
  field: 'notes'|'description';
  onSave: (newContent: string) => void;
  onCancel: () => void
}

const RichEditor: React.FC<RichEditorProps> = ({
  jobApp,
  field,
  onSave,
  onCancel,
}) => {
  const [newContent, setNewContent] = useState<string | undefined>(undefined);
  const { updateJobApp } = useContext(JobAppContext);

  function discardChanges() {
    setNewContent(jobApp[field] || undefined);
    onCancel();
  }

  async function saveChanges() {
    if (newContent === undefined || newContent === jobApp?.[field]) {
      // The user didn't do anything
      discardChanges()
      return;
    }
    const toSave = newContent === '<p><br></p>' ? '' : newContent;
    onSave(newContent);
    try {
      const toPost = {
        [field]: toSave,
      };
      await updateJobApp(jobApp.id, toPost);
    } catch (err) {
      notifyError('Unable to edit job app', (err as Error).message);
      discardChanges()
    }
  }

  function onContentChange(editorState: string) {
    const text = editorState;
    if (!jobApp?.[field] && !text) {
      setNewContent(undefined);
    } else {
      setNewContent(editorState);
    }
  }

  return (
    <div>
      <RichTextFormInput
        value={newContent || jobApp?.[field] || ''}
        onChange={onContentChange}
        withToolbar
        style={{width: '100%'}}
      />
      <Space style={{marginTop: '0.5rem'}}>
        <Button onClick={saveChanges} type="primary">Save</Button>
        <Button onClick={discardChanges}>Cancel</Button>
      </Space>
    </div>
  );
}

interface Props {
  interviewId?: string;
}

const Interview: React.FC<Props> = ({
  interviewId
}) => {
  const {
    getUserInterviewWithJobApps
  } = useContext(JobAppInterviewContext);
  const {
    jobApps,
    setJobApps,
  } = useContext(JobAppContext);
  const [interview, setInterview] = useState<(JobAppInterview & { jobApp: JobApp })|null>(null);
  const [editingDescription, setEditingDescription] = useState(false);
  const [addingInterviewQuestion, setAddingInterviewQuestion] = useState(false);
  const [newDescription, setNewDescription] = useState('')

  const initializePageWithInterview = useCallback(async (id: string) => {
    try {
      const fetchedInterview = await getUserInterviewWithJobApps(id)
      setInterview(fetchedInterview)
    } catch (err) {
      console.error(err);
      notifyError('Unable to load interview', (err as Error).message);
    }
  }, [getUserInterviewWithJobApps])

  useEffect(() => {
    if (interviewId) {
      initializePageWithInterview(interviewId);
    }
  }, [initializePageWithInterview, interviewId])

  const onEditDescription = () => {
    setEditingDescription(true)
  }

  const onAddedNote = (note: JobAppNote) => {
    if (!interview?.jobApp) {
      return;
    }
  
    const jobApp = interview.jobApp;
  
    setJobApps(jobApps?.map(curJobApp => {
      if (curJobApp.id === jobApp.id) {
        return {
          ...curJobApp,
          notesList: [...curJobApp.notesList, note],
        };
      }
      return curJobApp;
    }) || jobApps);
    setInterview({
      ...interview,
      jobApp: {
        ...jobApp,
        notesList: [...jobApp.notesList, note],
      },
    });
  }

  const onRemovedNote = (note: JobAppNote) => {
    if (!interview?.jobApp) {
      return;
    }
  
    const jobApp = interview.jobApp;
  
    setJobApps(jobApps?.map(curJobApp => {
      if (curJobApp.id === jobApp.id) {
        return {
          ...curJobApp,
          notesList: curJobApp.notesList.filter(curNote => curNote.id !== note.id),
        };
      }
      return curJobApp;
    }) || jobApps);
    setInterview({
      ...interview,
      jobApp: {
        ...jobApp,
        notesList: jobApp.notesList.filter(curNote => curNote.id !== note.id),
      },
    });
  }

  const onUpdatedNote = (note: JobAppNote) => {
    if (!interview?.jobApp) {
      return;
    }
  
    const jobApp = interview.jobApp;

    setJobApps(jobApps?.map(curJobApp => {
      if (curJobApp.id === jobApp.id) {
        return {
          ...curJobApp,
          notesList: curJobApp.notesList.map(curNote => {
            if (curNote.id === note.id) {
              return note;
            }
            return curNote;
          }),
        };
      }
      return curJobApp;
    }) || jobApps);
    setInterview({
      ...interview,
      jobApp: {
        ...jobApp,
        notesList: jobApp.notesList.map(curNote => {
          if (curNote.id === note.id) {
            return note;
          }
          return curNote;
        }),
      },
    });
  }


  const onAddedInterviewQuestion = (added: JobAppInterviewQuestion) => {
    if (!interview) {
      return;
    }
    const newInterview: typeof interview = {
      ...interview,
      questions: [
        added,
        ...interview?.questions,
      ]
    }
    setAddingInterviewQuestion(false);
    setInterview(newInterview);
  }

  const onDeletedInterviewQuestion = (removed: JobAppInterviewQuestion) => {
    if (!interview) {
      return;
    }
    const newInterview: typeof interview = {
      ...interview,
      questions: interview.questions.filter(q => q.id !== removed.id)
    }
    setInterview(newInterview);
  }

  const onUpdatedInterviewQuestion = (updated: JobAppInterviewQuestion) => {
    if (!interview) {
      return;
    }
    const newInterview: typeof interview = {
      ...interview,
      questions: interview.questions.map(q => q.id === updated.id ? updated : q)
    }
    setInterview(newInterview);
  }

  const onSaveDescription = (newContent: string) => {
    if (!interview) {
      return;
    }
    setEditingDescription(false)
    setNewDescription(newDescription)
    setInterview({
      ...interview,
      jobApp: {
        ...interview.jobApp,
        description: newContent
      }
    })
  }

  if (!interviewId) {
    return <Redirect to={pages.INTERVIEWS} noThrow />
  }

  if (!interview) {
    return <><Skeleton /><Skeleton /></>
  }

  const shownDescription = newDescription || interview.jobApp.description || '';

  return <div style={{
    display: 'grid',
    rowGap: '30px'
  }}>
    <Breadcrumb separator='>' style={{ fontSize: '1rem' }}>
      <Breadcrumb.Item>
        <Link to={pages.INTERVIEWS}>
        Interviews
        </Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item> 
        <Space>
          <Avatar src={interview.jobApp.companyLogoUrl} size="small" />
          {interview.jobApp.company}{interview.jobApp.title ? ` (${interview.jobApp.title})` : ''}
        </Space>
      </Breadcrumb.Item>
    </Breadcrumb>
    <div>
      <InterviewHeadingEditable interview={interview} />
      <InterviewDateEditable interview={interview} />
    </div>
    <Tabs
      items={[{
        key: 'interview-questions',
        label: 'Interview Questions',
        children: <>
          {!addingInterviewQuestion && <Button style={{marginBottom: '0.5rem'}}
            onClick={() => setAddingInterviewQuestion(true)}>
            Add Question
          </Button>}
          {addingInterviewQuestion &&
            <div style={{marginBottom: '15px'}}> 
              <InterviewQuestionNewPrompt 
                interview={interview}
                onClose={() => setAddingInterviewQuestion(false)} 
                onAdded={onAddedInterviewQuestion} 
              />
            </div>
          }
          <InterviewQuestionList
            questions={interview.questions}
            onDeletedInterviewQuestion={onDeletedInterviewQuestion}
            onUpdatedInterviewQuestion={onUpdatedInterviewQuestion}
          />
        </>
      }, {
        key: 'description',
        label: 'Job Description',
        children: <div style={{
          display: 'grid',
          rowGap: '0.5rem',
          width: 'initial'
        }}>
          {editingDescription && <RichEditor
            jobApp={interview.jobApp} 
            field="description" 
            onSave={onSaveDescription}
            onCancel={() => setEditingDescription(false)} />
          }
          {!editingDescription && shownDescription && <HTMLContent 
            onClick={onEditDescription}
            style={{
              cursor: 'pointer',
              minHeight: 'initial',
              maxHeight: 'initial'
            }}
            tabIndex={0}
            dangerouslySetInnerHTML={{
              __html: shownDescription,
            }}>
          </HTMLContent>}
          {!editingDescription && <Button
            style={{width: 'min-content'}} 
            onClick={onEditDescription}
          >
            Edit
          </Button>}
        </div>
      }, {
        key: 'notes',
        label: 'Notes',
        children: <div style={{
          display: 'grid',
          rowGap: '0.5rem',
        }}>
          <NotesList
            jobApp={interview.jobApp}
            onAddedNote={onAddedNote} 
            onRemovedNote={onRemovedNote}
            onUpdatedNote={onUpdatedNote}
          />
        </div>
      }]}
    >
    </Tabs>
  </div>
}

export default Interview;

import React from 'react';
import { Spin } from 'antd';
import styled from 'styled-components';

const Container = styled.div`
  position: absolute;
  margin: 0 auto;
  bottom: 50%;
  /** 32px is a Spin antd component whose size is large */
  left: calc(50% - (32px / 2));
`;

const Loading = () => (
  <Container>
    <Spin size="large" />
  </Container>
);

export default Loading;

import styled from 'styled-components';
import { transparentize } from 'polished';
import colors from '../../../constants/colors';
import themeColors from '../../../constants/themeColors';
import {Button} from 'antd'
interface DroppableAreaProps {
  readonly isDraggingOver: boolean;
}

export const ColumnStyles = styled.div`
  width: 335px;
  background: white;
  margin-right: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  box-shadow: 0px 1px 20px ${transparentize(0.9, themeColors.background.dark)};
`;

export const ColumnDroppableAreaStyles = styled.div<DroppableAreaProps>`
  height: 100%;
`;

export const ColumnHeaderStyles = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${colors.gray};
  padding: 12px 24px 7px 24px;
  border-radius: 5px;
  margin-bottom: 10px;
  > div:first-child {
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const ColumnBodyStyles = styled.div`
  padding: 15px 20px;
  height: 100%;
  overflow: auto;
`;

export const ColumnAddJobButtonStyles = styled(Button)`
  margin: 0 20px 5px 20px;
  width: calc(100% - 15px - 15px) !important;
`;

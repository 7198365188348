import UserProfile from '../../types/UserProfile';
import { patchForJson } from '../xhr';

interface UpdateDetails {
  password?: string;
  firstName?: string;
  lastName?: string;
}

export default function xhrUpdateUser(data: UpdateDetails, accessToken: string): Promise<UserProfile> {
  return patchForJson(`/users/@me/`, data, accessToken);
}


import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Router, RouteComponentProps, Redirect,
} from '@reach/router';
import {
  Button, notification, Space, Typography,
} from 'antd';
import {
  MenuOutlined,
} from '@ant-design/icons';
import * as Sentry from '@sentry/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import useAuthZero from '../hooks/useAuthZero';
import Documents from './Documents';
import BoardImport from '../components/JobAppBoard/BoardImport';
import MenuSideBarDesktop from '../components/JobAppBoard/MenuSideBarDesktop';
import MenuSideBarMobile from '../components/JobAppBoard/MenuSideBarMobile';
import pages from '../constants/pages';
import themeColors from '../constants/themeColors';
import Boards from '../components/JobAppBoard/Boards/Boards';
import Board from '../components/JobAppBoard/Board/Board';
import CrashError from './CrashError';
import Loading from '../components/JobAppBoard/Loading';
import SupportModal from '../components/SupportModal';
import SettingsAccount from './SettingsAccount';
import { UserContext, UserProvider } from '../contexts/UserProvider';
import { StripeProvider } from '../contexts/StripeProvider';
import { UserSubscriptionsProvider } from '../contexts/UserSubscriptionsProvider';
import { DocumentProvider } from '../contexts/DocumentProvider';
import { JobAppBoardContext, JobAppBoardProvider } from '../contexts/JobAppBoardProvider';
import { JobAppColumnProvider } from '../contexts/JobAppColumnProvider';
import { JobAppProvider } from '../contexts/JobAppProvider';
import { JobAppInterviewProvider } from '../contexts/JobAppInterviewProvider';
import UserPopover from '../components/JobAppBoard/JobAppTracker/UserPopover';
import { AppOrganizationViewContext, AppOrganizationViewProvider } from '../contexts/AppOrganizationViewProvider';
import PageConstrained from '../components/JobAppBoard/common/PageConstrained/PageConstrained';
import chromeExtensionConstants from '../constants/chromeExtension';
import BoardCopyToOrg from "./BoardCopyToOrg"
import TourProvider from '../contexts/TourProvider';
import { FeatureFlagProvider } from '../contexts/FeatureFlagProvider';
import Interviews from './Interviews';
import Interview from './Interview';
import { InterviewQuestionProvider } from '../contexts/InterviewQuestionProvider';
import { ContactsProvider } from '../contexts/ContactsProvider';
import { JobAppNoteProvider } from '../contexts/JobAppNoteProvider';
import { MemberNotificationsProvider } from '../contexts/MemberNotificationsProvider';
import NotificationsPopover from '../components/JobAppBoard/JobAppTracker/NotificationsPopover/NotificationsPopover';
import { LocationsProvider } from '../contexts/LocationsProvider';
import OrganizationInviteModal from '../features/organizations/components/OrganizationInviteModal';
import { ForceNamePrompt } from '../features/users';
import { ShepherdTourContext } from 'react-shepherd';
import localStorageConstants from '../constants/localStorage';
import isMobileBrowser from '../util/isMobileBrowser';

const PageWrapper = styled.div`
  height: 100vh;
  display: flex;
  background: ${themeColors.background.faintBlue};
`;

const HeaderStyles = styled.div`
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  align-items: center;
  > div:first-child {
    display: flex;
    align-items: center;
    overflow: hidden;
  }
  h1 {
    font-size: 1.65em;
    margin: 0;
    color: ${themeColors.background.dark};
    font-weight: 600;
  }
`;

interface HeaderProps {
  smallScreen: boolean,
  openSideMenu: () => void,
}

/**
 * Page header that shows the page name and user dropdown
 * Shared across multiple pages on the tracker
 */
const Header: React.FC<HeaderProps> = ({
  smallScreen,
  openSideMenu,
}) => {
  const { user, logout } = useContext(UserContext);
  const { organizationId } = useContext(AppOrganizationViewContext)

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      boxShadow: '0 2px 2px 0 rgb(0 0 0 / 10%)',
      zIndex: 10,
      background: themeColors.background.faintBlue
    }}
    >
      <HeaderStyles>
        <div style={smallScreen
          ? { display: 'flex', justifyContent: 'space-between', width: '100%' }
          : {}}
        >
          {/** Only render the menu button on small screns */}
          {smallScreen && (
            <Button
              onClick={openSideMenu}
              type="text"
              icon={<MenuOutlined style={{ fontSize: '1.5em' }} />}
            />
          )}
          {smallScreen && (
            <div style={{ display: 'flex' }}>
              {organizationId && <NotificationsPopover />}
              <SupportModal trigger={(
                <Button
                  shape="circle"
                  type="text"
                  icon={<FontAwesomeIcon icon={faQuestion} />}
                  size="large"
                />
              )}
              />

            </div>
          ) }
        </div>
        {/** Don't render the dropdown on small screens */}
        {!smallScreen && (
          <Space>
            {organizationId && <NotificationsPopover />}
            <SupportModal trigger={(
              <Button
                shape="circle"
                type="text"
                icon={<FontAwesomeIcon icon={faQuestion} />}
                size="large"
              />
            )}
            />
            <UserPopover onLogout={logout} user={user} />
          </Space>
        )}
      </HeaderStyles>
    </div>
  );
};

interface BoardRouteProps extends RouteComponentProps {
  boardId?: string
}

interface InterviewRouteProps extends RouteComponentProps {
  interviewId?: string
}

const { organizationsWelcomed } = localStorageConstants

/**
 * Compare the props to decide whether to re-render Without this,
 * every time the side bar is open, the page is re-rendered.
 * Equivalent to a PureComponent.
 */
const PageRender = React.memo(() => {
  const {
    organizations,
    switchToOrganization,
  } = useContext(AppOrganizationViewContext)
  const orgTour = useContext(ShepherdTourContext);
  const { boards } = useContext(JobAppBoardContext);
  const [hasOpenedTour, setHasOpenedTour] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const BoardsHomeRoute = (props: RouteComponentProps) => <PageConstrained>
    <Boards />
  </PageConstrained>;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const BoardRoute: React.FC<BoardRouteProps> = ({ boardId }) => <Board boardId={boardId} />;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const BoardImportRoute = (props: RouteComponentProps) => <BoardImport />;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const BoardCopyToOrgRoute = (props: RouteComponentProps) => <PageConstrained><BoardCopyToOrg /></PageConstrained>;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const DocumentsRoute = (props: RouteComponentProps) => <PageConstrained><Documents /></PageConstrained>;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const SettingsAccountRoute = (props: RouteComponentProps) => <PageConstrained><SettingsAccount /></PageConstrained>;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const InterviewRoute: React.FC<InterviewRouteProps> = ({ interviewId }) => <PageConstrained><Interview interviewId={interviewId} /></PageConstrained>;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const InterviewsRoute = (props: RouteComponentProps) => <PageConstrained size="md"><Interviews /></PageConstrained>;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const DefaultRoute = (props: RouteComponentProps) => <Redirect to={pages.BOARD} noThrow />;


  useEffect(() => {
    if (hasOpenedTour || isMobileBrowser()) {
      return;
    }
    if (!organizations?.length || !boards) {
      return;
    }
    const firstOrg = organizations[0];
    const firstOrgBoards = boards.filter(board => board.organizationId === firstOrg.id);
    if (firstOrgBoards.length) {
      return
    }
    const organizationIds: string[] = JSON.parse(
      localStorage.getItem(organizationsWelcomed) || '[]')
    if (organizationIds.includes(firstOrg.id)) {
      return;
    }

    notification.success({
      key: 'welcome-to-org',
      message: `You've been added to ${firstOrg.name}!`,
      duration: 0,
      closeIcon: <div />,
      placement: 'bottomRight',
      style: {
        width: '500px'
      },
      btn: <Button
        type="primary"
        onClick={() => {
          orgTour?.start()
          notification.destroy('welcome-to-org')
        }}
      >
        Start tour
      </Button>,
      description: (
        <div>
          <Typography.Paragraph style={{
            marginBottom: "1.5rem"
          }}>
          You are now part of {firstOrg.name}, where {firstOrg.name} can help you track and optimize your job search
          progress. You have two kinds of boards:
            <br/>
            <br/>
            <strong>Organization Board</strong>
            <br/>
          This is a single job board that is visible to {firstOrg.name}, and {firstOrg.name} can track your progress in there.
            <br/>
            <br/>
            <strong>Personal Boards</strong>
            <br/>
          These are boards that are visible only to you.
          </Typography.Paragraph>
        </div>
      ),
    })
    setHasOpenedTour(true)
  }, [
    organizations,
    boards,
    switchToOrganization,
    orgTour,
    hasOpenedTour,
  ]);

  return (
    <StripeProvider>
      <JobAppColumnProvider>
        <JobAppInterviewProvider>
          <InterviewQuestionProvider>
            <JobAppNoteProvider>
              <Router style={{height: '100%'}}>
                <BoardsHomeRoute path={pages.BOARD} />
                <BoardImportRoute path={pages.IMPORT_BOARD} />
                <BoardCopyToOrgRoute path={pages.COPY_TO_ORG} />
                <BoardRoute path={`${pages.BOARD}/:boardId`} />
                <DocumentsRoute path={`${pages.DOCUMENTS}/*`} />
                <SettingsAccountRoute path={pages.SETTINGS_ACCOUNT} />
                <InterviewRoute path={`${pages.INTERVIEWS}/:interviewId`} />
                <InterviewsRoute path={pages.INTERVIEWS} />
                <DefaultRoute default />
              </Router>
            </JobAppNoteProvider>
          </InterviewQuestionProvider>
        </JobAppInterviewProvider>
      </JobAppColumnProvider>
    </StripeProvider>
  );
});

/**
 * Main component
 */
function JobAppTracker() {
  const { isAuthenticated, isLoading, error } = useAuthZero();
  const [sideBarVisible, setSideBarVisible] = useState(window.innerWidth > 800);

  useEffect(() => {
    if (error) {
      Sentry.captureException(error);
    }
  }, [error]);

  useEffect(() => {
    if (isAuthenticated && !!window.chrome?.runtime?.sendMessage) {
      chromeExtensionConstants.extensionIds.forEach((id) => {
        chrome.runtime.sendMessage(id, {
          type: 'STIPPLO_WEBAPP_CONFIRMED_LOGIN'
        })
      })
    }
  }, [isAuthenticated])

  if (isLoading) {
    return (
      <PageWrapper>
        <Loading />
      </PageWrapper>
    );
  }

  if (error) {
    return (
      <PageWrapper>
        <CrashError eventId={error.message} />
      </PageWrapper>
    );
  }

  if (!isAuthenticated) {
    return <Redirect to={pages.LOGIN} noThrow />;
  }

  const smallScreen = window.innerWidth < 1280;

  return (
    <UserProvider>
      <FeatureFlagProvider>
        <LocationsProvider>
          <AppOrganizationViewProvider>
            <MemberNotificationsProvider>
              <TourProvider>
                <DocumentProvider>
                  <UserSubscriptionsProvider>
                    <JobAppProvider>
                      <JobAppBoardProvider>
                        <ContactsProvider>
                          <PageWrapper>
                            <ForceNamePrompt />
                            {/** Smaller screen/mobile side bar */}
                            {smallScreen && (
                              <MenuSideBarMobile
                                onClose={() => setSideBarVisible(false)}
                                visible={sideBarVisible}
                              />
                            )}
                            {/** Desktop side bar */}
                            {!smallScreen && <MenuSideBarDesktop />}
                            <div className='abar' style={{
                              width: '100%',
                              overflow: 'hidden',
                              height: '100vh',
                              display: 'flex',
                              flexDirection: 'column',
                            }}>
                              <Header
                                openSideMenu={() => setSideBarVisible(true)}
                                smallScreen={smallScreen}
                              />
                

                              {/** Page contents */}
                              <PageRender />
                              <OrganizationInviteModal />
                            </div>
                          </PageWrapper>
                        </ContactsProvider>
                      </JobAppBoardProvider>
                    </JobAppProvider>
                  </UserSubscriptionsProvider>
                </DocumentProvider>
              </TourProvider>
            </MemberNotificationsProvider>
          </AppOrganizationViewProvider>
        </LocationsProvider>
      </FeatureFlagProvider>
    </UserProvider>
  );
}

export default JobAppTracker;

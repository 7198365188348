import React, { useEffect, useState } from 'react';
import { loadStripe, Stripe } from '@stripe/stripe-js';

type StripeContextData = {
  stripe: Stripe|null,
};

export const StripeContext = React.createContext<StripeContextData>({
  stripe: null,
});

export const StripeProvider: React.FC = ({ children }) => {
  const [stripe, setStripe] = useState<Stripe|null>(null);

  useEffect(() => {
    loadStripe(
      process.env.REACT_APP_STRIPE_KEY as string,
    ).then(setStripe);
  }, []);

  return (
    <StripeContext.Provider value={{
      stripe,
    }}
    >
      {children}
    </StripeContext.Provider>
  );
};

import React from 'react';
import themeColors from '../../constants/themeColors';

const SuccessText: React.FC = ({ children }) => (
  <span style={{
    color: themeColors.semantic.success,
  }}
  >
    {children}
  </span>
);

export default SuccessText;

import { patchForJson } from '../xhr';

export default async function xhrUpdateDocument(
  documentId: string, updates: { name: string }, token: string,
) {
  const endpoint = `/documents/${documentId}`;
  const updatedDoc = await patchForJson(endpoint, {
    name: updates.name,
  }, token);
  return updatedDoc;
}

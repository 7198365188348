import pages from "../../../../constants/pages";
import { Footer, PageConstrainedCentered, PageConstrainedTopContent, PageConstrainedWrapper } from "./PageConstrained.styles";

interface Props {
  size?: "small" | "md"
}

const PageConstrained: React.FC<Props> = ({ children, size }) => {
  return (
    <PageConstrainedWrapper>
      <PageConstrainedCentered size={size}>
        <PageConstrainedTopContent>
          {children}
        </PageConstrainedTopContent>
        <Footer>
          <a rel="noopener noreferrer" target="_blank" href={pages.HOME}>Home</a>
          <a rel="noopener noreferrer" target="_blank" href={pages.PRIVACY_POLICY}>Privacy</a>
          <a rel="noopener noreferrer" target="_blank" href={pages.TERMS_OF_SERVICE}>Terms</a>
          <a rel="noopener noreferrer" target="_blank" href={pages.COOKIES_POLICY}>Cookies</a>
          <a href="mailto:support@stipplo.com">Contact</a>
        </Footer>
      </PageConstrainedCentered>
    </PageConstrainedWrapper>
  )
}

export default PageConstrained;

import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button as AntButton,
} from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { InterviewQuestionContext } from '../../../../contexts/InterviewQuestionProvider';
import { JobAppBoardContext } from '../../../../contexts/JobAppBoardProvider';
import JobApp from '../../../../types/JobApp';
import JobAppInterview from '../../../../types/JobAppInterview';
import JobAppInterviewQuestion from '../../../../types/JobAppInterviewQuestion';
import InterviewQuestion from '../../InterviewComponents/InterviewQuestionList/InterviewQuestion/InterviewQuestion';
import InterviewQuestionNewPrompt from '../../InterviewComponents/InterviewQuestionList/InterviewQuestionNewPrompt/InterviewQuestionNewPrompt';

const useJobAppInterviewQuestions = (jobApp: JobApp, jobAppInterview: JobAppInterview) => {
  const [questions, setQuestions] = useState<JobAppInterviewQuestion[]|null>(null)
  const { activeBoardId } = useContext(JobAppBoardContext);
  const { getInterviewQuestions } = useContext(InterviewQuestionContext);

  useEffect(() => {
    getInterviewQuestions(jobAppInterview.id)
      .then(questions => {
        setQuestions(questions)
      })
      .catch(console.error);
  }, [activeBoardId, getInterviewQuestions, jobAppInterview.id])

  return {
    interviewQuestions: questions || [],
    setInterviewQuestions: setQuestions,
  }
};

interface InterviewItemProps {
  jobApp: JobApp;
  interview: JobAppInterview;
}

const InterviewItem: React.FC<InterviewItemProps> = ({
  jobApp,
  interview,
}) => {
  const {
    interviewQuestions,
    setInterviewQuestions,
  } = useJobAppInterviewQuestions(jobApp, interview);
  const [addingQuestion, setAddingQuestion] = useState(false);

  const onAddedInterviewQuestion = async (added: JobAppInterviewQuestion) => {
    setInterviewQuestions([
      ...interviewQuestions,
      added,
    ]);
    setAddingQuestion(false);
  };

  const onDeletedInterviewQuestion = (removed: JobAppInterviewQuestion) => {
    const newInterviewQuestions = [
      ...interviewQuestions.filter(q => q.id !== removed.id)
    ]

    setInterviewQuestions(newInterviewQuestions)
  }

  const onUpdatedInterviewQuestion = (updated: JobAppInterviewQuestion) => {
    const newInterviewQuestions = [
      ...interviewQuestions.map(q => {
        if (q.id === updated.id) {
          return updated;
        }
        return q;
      })
    ]
    setInterviewQuestions(newInterviewQuestions)
  }

  return (
  /** Add horizontal padding to prevent the items from touching the scrollbar */
    <div style={{ padding: '0 1.5rem' }}>
      {interviewQuestions.map((question) => {
        return <div key={question.id} style={{marginBottom: '1rem', display: 'grid', rowGap: '0.5rem'}}>
          <InterviewQuestion 
            question={question}
            onDeleted={onDeletedInterviewQuestion}
            onUpdated={onUpdatedInterviewQuestion}
          />
        </div>
      })}
      {addingQuestion && (
        <InterviewQuestionNewPrompt
          onClose={() => setAddingQuestion(false)}
          onAdded={onAddedInterviewQuestion}
          interview={interview}
        />
      )}
      <AntButton
        icon={<FontAwesomeIcon icon={faPlus} />}
        onClick={() => setAddingQuestion(true)}
        hidden={addingQuestion}
      >
        <span style={{ paddingLeft: '0.5rem' }}>
          Add a question
        </span>
      </AntButton>
    </div>
  );
};

export default InterviewItem;

import React from 'react';
import { Avatar } from 'antd';
import JobApp from '../../types/JobApp';

interface JobAppAvatarProps {
  size: number;
  jobApp: JobApp;
  style: React.CSSProperties;
}

const JobAppAvatar: React.FC<JobAppAvatarProps> = ({
  size, jobApp, style,
}) => {
  const fontSize = size > 16 * 2 ? size / 2 : '1rem';
  // Prevent the image from being squished by other elements
  const styles: React.CSSProperties = {
    minHeight: `${size}px`,
    minWidth: `${size}px`,
    fontSize,
    ...style,
  };

  return (
    <Avatar
      size={size}
      style={styles}
      src={jobApp.companyLogoUrl || undefined}
    >
      {jobApp.companyLogoUrl ? '' : jobApp.company.slice(0, 1).toUpperCase()}
    </Avatar>
  );
};

export default JobAppAvatar;

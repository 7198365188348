import Contact from '../../types/Contact';
import { postForJson } from '../xhr';

export default function xhrCreateContactForJobApp(
  jobAppId: string,
  contactToCreate: Partial<Contact>,
  accessToken: string,
) {
  return postForJson(
    `/jobapps/${jobAppId}/contacts`,
    contactToCreate,
    accessToken,
  );
}

import React, { useState } from 'react';
import {
  Button, Tabs,
} from 'antd';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import EditJobAppDocuments from './EditJobAppDocuments';
import JobAppStatusDropdown from './JobAppStatusDropdown';
import JobAppInput from './JobAppInput';
import JobAppColorSelection from './JobAppColorSelection';
import JobAppAppliedDatePicker from './JobAppAppliedDatePicker';
import JobApp from '../../../types/JobApp';
import themeColors from '../../../constants/themeColors';
import JobAppAvatar from '../JobAppAvatar';
import InterviewSection from './InterviewSection/InterviewSection';
import { DATE_FORMAT_WITH_TIME } from '../../../constants/dateTimeFormats';
import JobAppEditCompany from './JobAppEditCompany';
import JobAppRichTextEditor from './JobAppRichTextEditor';
import ContactsArea from './ContactsArea/ContactsArea';
import Contact from '../../../types/Contact';
import JobAppNote from '../../../types/JobAppNote';
import NotesList from './NotesList/NotesList';
import { faBookmark, faComments, faAddressBook, faClipboard } from '@fortawesome/free-regular-svg-icons';
import JobAppEditTitle from './JobAppEditTitle';

const ModalMobileContent = styled.div`
  overflow: auto;
  display: block;
  padding: 15px 24px 24px 24px;
`;

const ModalSettings = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  > span {
    color: ${themeColors.background.gray};
    font-size: 0.9rem;
  }
`;

const ModalLeftPanelHeader = styled.div`
   display: flex;
   align-items: center;
   justify-content: space-between;
   > div:first-child {
    flex: 1;
    margin-right: 1rem;
   }
`;

const Field = styled.div`
  padding-bottom: 20px;
`;

const BoxShadow = styled.div`
  box-shadow: 0 1px 5px rgba(71, 89, 107, 0.1);
`;

const JobAppDescriptionBoxShadow = styled.div`
  .text-editor {
    box-shadow: 0 1px 5px rgba(71, 89, 107, 0.1);
  }
`;

const Label = styled.span`
  font-weight: 500;
`;

const StyledTabs = styled(Tabs)`
  .ant-tabs-content-holder {
    overflow: auto;
  }
`;

const TabContent = styled.span`
  span:last-child {
    padding-left: 0.5rem;
  }
`;

interface EditJobAppModalMobileContentProps {
  jobApp?: JobApp;
  onAddedContact: (contact: Contact) => void;
  onRemovedContact: (contact: Contact) => void;
  onUpdatedContact: (contact: Contact) => void;
  onAddedNote: (note: JobAppNote) => void
  onRemovedNote: (note: JobAppNote) => void;
  onUpdatedNote: (note: JobAppNote) => void;
  onUpdatedJobApp: (jobApp: JobApp) => void;
}

const EditJobAppModalMobileContent: React.FC<EditJobAppModalMobileContentProps> = ({
  jobApp,
  onAddedContact,
  onUpdatedContact,
  onRemovedContact,
  onAddedNote,
  onRemovedNote,
  onUpdatedNote,
  onUpdatedJobApp,
}) => {
  const [newCompanySelected, setNewCompanySelected] = useState<{
    value: string,
    logoUrl?: string
  }|null>(null);

  if (!jobApp) {
    return <div />;
  }

  const {
    url,
  } = jobApp;

  let jobAppUrl = url || '';
  if (jobAppUrl && !jobAppUrl.match(/^https?:\/\//i)) {
    jobAppUrl = `https://${jobAppUrl}`;
  }
  const jobAppUrlLinkButton = (
    <Button
      icon={<FontAwesomeIcon icon={faExternalLinkAlt} />}
      type="link"
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 0,
        background: 'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgb(255, 255, 255) 10%)',
        borderStyle: 'none',
        margin: '4px',
        height: '24px',
      }}
      href={jobApp.url || ''}
      target="_blank"
      rel="noreferrer noopener"
      disabled={!jobApp.url}
    />
  );

  const updatedAtText = `Updated at ${dayjs(jobApp.updatedAt).format(DATE_FORMAT_WITH_TIME)}`;

  const mobileView = (
    <ModalMobileContent>
      <ModalSettings>
        <span>
          {updatedAtText}
        </span>
      </ModalSettings>
      <Field>
        <ModalLeftPanelHeader>
          <div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}>
              <JobAppColorSelection jobApp={jobApp} />
              <JobAppEditCompany jobApp={jobApp} onSelected={setNewCompanySelected} />
            </div>
            <JobAppEditTitle jobApp={jobApp} />
          </div>
          <JobAppAvatar
            size={64}
            style={{
              marginRight: '1rem',
            }}
            jobApp={{
              ...jobApp,
              companyLogoUrl: newCompanySelected?.logoUrl || jobApp.companyLogoUrl,
            }}
          />
        </ModalLeftPanelHeader>
      </Field>
      <StyledTabs
        defaultActiveKey="description"
        /**
         * For any rich text editors, the toolbar can only properly be mounted once, otherwise it
         * will show blank buttons. Here, we unmount the tab contents when we switch tabs so the
         * toolbar can be mounted in other tabs if needed.
         */
        destroyInactiveTabPane
        items={[{
          key: 'description',
          label: <TabContent>
            <FontAwesomeIcon icon={faBookmark} size="lg" />
            <span>Description</span>
          </TabContent>,
          children: <><Field>
            <JobAppDescriptionBoxShadow>
              <JobAppRichTextEditor 
                jobApp={jobApp}
                jobAppField='description' 
                onUpdatedJobApp={onUpdatedJobApp}
              />
            </JobAppDescriptionBoxShadow>
          </Field>
          <Field className="job-status-dropdown-header">
            <Label>Status</Label>
            <br />
            <BoxShadow>
              <JobAppStatusDropdown jobApp={jobApp} />
            </BoxShadow>
          </Field>
          <Field>
            <Label>Date Created</Label>
            <br />
            <BoxShadow>
              <JobAppAppliedDatePicker jobApp={jobApp} style={{ width: '100%' }} />
            </BoxShadow>
          </Field>
          <Field>
            <Label>Salary</Label>
            <BoxShadow>
              <JobAppInput property="salary" jobApp={jobApp} />
            </BoxShadow>
          </Field>
          <Field>
            <Label as="label" htmlFor="edit-jobapp-url">URL</Label>
            <BoxShadow style={{ position: 'relative' }}>
              <JobAppInput property="url" jobApp={jobApp} />
              {jobAppUrlLinkButton}
            </BoxShadow>
          </Field>
          <Field>
            <EditJobAppDocuments jobApp={jobApp} />
          </Field>
          </>
        }, {
          key: 'interviews',
          label: <TabContent>
            <FontAwesomeIcon icon={faComments} />
            <span>Interviews</span>
          </TabContent>,
          children: <>
            <InterviewSection jobApp={jobApp} />
          </>
        }, {
          key: 'contacts',
          label: <TabContent>
            <FontAwesomeIcon icon={faAddressBook} size="lg" />
            <span>Contacts</span>
          </TabContent>,
          children: <ContactsArea
            onAddedContact={onAddedContact}
            jobApp={jobApp}
            onRemovedContact={onRemovedContact}
            onUpdatedContact={onUpdatedContact}
          />
        }, {
          key: 'notes',
          label: <TabContent>
            <FontAwesomeIcon icon={faClipboard} size="lg" />
            <span>Notes</span>
          </TabContent>,
          children: <NotesList
            jobApp={jobApp}
            onAddedNote={onAddedNote}
            onRemovedNote={onRemovedNote}
            onUpdatedNote={onUpdatedNote}
          />
        }]}
      >
      </StyledTabs>
    </ModalMobileContent>
  );

  return mobileView;
};

export default EditJobAppModalMobileContent;

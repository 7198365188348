const chromeExtensionConstants = {
  downloadUrl: 'https://chrome.google.com/webstore/detail/eelojdbiagbabehiijgghfmdgnggdfna',
  /**
   * To know where the message should be sent. There can be multiple IDs if we have multiple
   * environments and/or different versions.
   */
  extensionIds: [
    // Michael's locally installed v1,
    'bnjhgjdiaffkbommpgclldgjfffmnjec',
    // Preview
    'flenaknkaikkljhfpgkajajpbdpgmhoh',
    // Production
    'eelojdbiagbabehiijgghfmdgnggdfna',
  ],
};

export default chromeExtensionConstants;

import React, { useContext } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import ResponsiveModal from '../ResizeableModal';
import EditJobAppModalDesktopContent from './EditJobAppModal/EditJobAppModalDesktopContent';
import EditJobAppModalMobileContent from './EditJobAppModal/EditJobAppModalMobileContent';
import { JobAppContext } from '../../contexts/JobAppProvider';
import Contact from '../../types/Contact';
import JobAppNote from '../../types/JobAppNote';
import JobApp from '../../types/JobApp';
import themeColors from '../../constants/themeColors';

const EditJobModal = styled(ResponsiveModal)`
  height: 85%;
  .ant-modal-content {
    height: 100%;
  }
  .ant-modal-body {
    padding-bottom: 0;
    padding: 0;
    margin-bottom: 2rem;
    height: 100%;
  }
`;

const ModalContent = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  > div {
    flex: 1;
  }
`;

// TODO: Pressing escape while editing input will close the modal. This should not happen.

const EditJobAppModal: React.FC = () => {
  const {
    editingJobApp: jobApp,
    setEditingJobAppId,
    jobApps,
    setJobApps,
  } = useContext(JobAppContext);

  function resetAndClose() {
    setEditingJobAppId(null);
  }


  if (!jobApp) {
    return <div />;
  }

  const onUpdatedJobApp = (updated: JobApp) => {
    const updatedJobApps = (jobApps || []).map((jobApp) => {
      if (jobApp.id === updated.id) {
        return updated;
      }
      return jobApp;
    });
    setJobApps(updatedJobApps);
  } 
  
  const onAddedContact = (contact: Contact) => {
    setJobApps(jobApps?.map(curJobApp => {
      if (curJobApp.id === jobApp.id) {
        return {
          ...curJobApp,
          contacts: [...curJobApp.contacts, contact],
        };
      }
      return curJobApp;
    }) || jobApps);
  }

  const onUpdatedContact = (contact: Contact) => {
    setJobApps(jobApps?.map(curJobApp => {
      if (curJobApp.id === jobApp.id) {
        return {
          ...curJobApp,
          contacts: curJobApp.contacts.map(curContact => {
            if (curContact.id === contact.id) {
              return contact;
            }
            return curContact;
          }),
        };
      }
      return curJobApp;
    }) || jobApps);
  }

  const onRemovedContact = (contact: Contact) => {
    setJobApps(jobApps?.map(curJobApp => {
      if (curJobApp.id === jobApp.id) {
        return {
          ...curJobApp,
          contacts: curJobApp.contacts.filter(curContact => curContact.id !== contact.id),
        };
      }
      return curJobApp;
    }) || jobApps);
  }

  const onAddedNote = (note: JobAppNote) => {
    setJobApps(jobApps?.map(curJobApp => {
      if (curJobApp.id === jobApp.id) {
        return {
          ...curJobApp,
          notesList: [...curJobApp.notesList, note],
        };
      }
      return curJobApp;
    }) || jobApps);
  }

  const onRemovedNote = (note: JobAppNote) => {
    setJobApps(jobApps?.map(curJobApp => {
      if (curJobApp.id === jobApp.id) {
        return {
          ...curJobApp,
          notesList: curJobApp.notesList.filter(curNote => curNote.id !== note.id),
        };
      }
      return curJobApp;
    }) || jobApps);
  }

  const onUpdatedNote = (note: JobAppNote) => {
    setJobApps(jobApps?.map(curJobApp => {
      if (curJobApp.id === jobApp.id) {
        return {
          ...curJobApp,
          notesList: curJobApp.notesList.map(curNote => {
            if (curNote.id === note.id) {
              return note;
            }
            return curNote;
          }),
        };
      }
      return curJobApp;
    }) || jobApps);
  }

  // const nextApp = columnJobApps?.find((app) => app.position === jobApp.position + 1);
  // const previousApp = columnJobApps?.find((app) => app.position === jobApp.position - 1);
  const {
    url,
  } = jobApp;

  let jobAppUrl = url || '';
  if (jobAppUrl && !jobAppUrl.match(/^https?:\/\//i)) {
    jobAppUrl = `https://${jobAppUrl}`;
  }

  return (
    <EditJobModal
      visible={!!jobApp}
      footer={null}
      // width={window.innerWidth * .5}
      onCancel={resetAndClose}
      bodyStyle={{
        background: themeColors.background.white
      }}
    >
      <Helmet>
        <title>
          Edit
          {' '}
          {`${jobApp?.company || 'unknown company'} (${jobApp?.title || 'unknown position'})`}
        </title>
      </Helmet>
      <ModalContent>
        {window.innerWidth < 750
          ? <EditJobAppModalMobileContent
            jobApp={jobApp} 
            onAddedContact={onAddedContact}
            onUpdatedContact={onUpdatedContact}
            onRemovedContact={onRemovedContact}
            onAddedNote={onAddedNote}
            onRemovedNote={onRemovedNote}
            onUpdatedNote={onUpdatedNote}
            onUpdatedJobApp={onUpdatedJobApp}
          />
          : <EditJobAppModalDesktopContent
            jobApp={jobApp}
            onAddedContact={onAddedContact} 
            onRemovedContact={onRemovedContact}
            onAddedNote={onAddedNote}
            onRemovedNote={onRemovedNote}
            onUpdatedNote={onUpdatedNote}
            onUpdatedJobApp={onUpdatedJobApp}
            onUpdatedContact={onUpdatedContact}
          />}
      </ModalContent>
    </EditJobModal>
  );
};

export default EditJobAppModal;

import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import AddJobBoardModal from '../AddJobBoardModal';
import JobAppBoard from '../../../types/JobAppBoard';
import BoardListItem from '../BoardListItem';
import { JobAppBoardContext } from '../../../contexts/JobAppBoardProvider';
import PageTitle from '../common/PageTitle/PageTitle';
import { AppOrganizationViewContext } from '../../../contexts/AppOrganizationViewProvider';
import { Link, navigate, Redirect } from '@reach/router';
import pages from '../../../constants/pages';
import { Button as AntButton, Skeleton, Space } from 'antd'
import notifyError from '../../../util/notifyError';

const Wrapper = styled.div`
  width: 100%;
  padding-top: 30px;
`;

const SkeletonButton = styled(Skeleton.Button)`
  span {
    min-height: 5rem;
  }
`

function Boards() {
  const [showAddBoardModal, setShowAddBoardModal] = useState(false);
  const { boards, addJobAppOrganizationBoard } = useContext(JobAppBoardContext);
  const {
    selectedOrganization, 
  } = useContext(AppOrganizationViewContext)
  const [creatingBoard, setCreatingBoard] = useState(false);

  function onAddBoardModalClose() {
    setShowAddBoardModal(false);
  }

  async function onClickAddBoard() {
    if (!selectedOrganization) {
      setShowAddBoardModal(true);
      return
    }
    try {
      setCreatingBoard(true);
      const newBoard = await addJobAppOrganizationBoard(selectedOrganization.id);
      navigate(`${pages.BOARD}/${newBoard.id}`)
    } catch (err) {
      notifyError('Failed to create board', (err as Error).message)
    } finally {
      setCreatingBoard(false);
    }
  }


  const visibleBoards = selectedOrganization
    ? (boards || [])?.filter((board) => board.organizationId === selectedOrganization.id)
    : (boards || [])?.filter((board) => !board.organizationId)

  if (selectedOrganization && visibleBoards.length) {
    return <Redirect to={`${pages.BOARD}/${visibleBoards[0].id}`} noThrow />
  }

  const skeletonBoards = !boards && new Array(3).fill(null).map((_, index) => (
    <SkeletonButton block size="large" key={index} />
  ))

  return (
    <>
      <Helmet>
        <title>Job Boards</title>
      </Helmet>
      <Wrapper>
        <PageTitle>{selectedOrganization && `${selectedOrganization.name} `}Boards</PageTitle>
        <AddJobBoardModal
          onClose={onAddBoardModalClose}
          visible={showAddBoardModal}
        />
        <Space direction="vertical" size="large" style={{width: '100%'}}>
          <Space direction="vertical" size="small" style={{width: '100%'}}>
            <AntButton
              className='product-tour-add-board-button'
              type="primary"
              block
              style={{
                width: '100%'
              }}
              size="large"
              icon={<FontAwesomeIcon icon={faPlus} style={{ marginRight: '0.5rem' }} />}
              shape="round"
              onClick={onClickAddBoard}
              disabled={creatingBoard}
            >
          
              <span>Create Board</span>
            </AntButton>
            <div className='product-tour-copy-board-button' style={{
              display: 'flex',
              justifyContent: 'center',
            }}>
              {selectedOrganization
            && <Link to={pages.COPY_TO_ORG}>
              <AntButton type='link'>Import from Personal Board</AntButton>
            </Link>
              }
            </div>
          </Space>
          <Space direction="vertical" size="middle" style={{
            width: '100%'
          }}>
            {skeletonBoards}
            {
              visibleBoards.map((board: JobAppBoard) => (
                <BoardListItem key={board.id} board={board} />
              ))
            }
          </Space>
        </Space>
      </Wrapper>
    </>
  );
}

export default Boards;

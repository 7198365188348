import JobAppInterviewQuestion from "../../../../types/JobAppInterviewQuestion";
import InterviewQuestion from "./InterviewQuestion/InterviewQuestion";

interface Props {
  questions: Array<JobAppInterviewQuestion>;
  onDeletedInterviewQuestion: (question: JobAppInterviewQuestion) => void;
  onUpdatedInterviewQuestion: (question: JobAppInterviewQuestion) => void;
}

const InterviewQuestionList: React.FC<Props> = ({
  questions,
  onDeletedInterviewQuestion,
  onUpdatedInterviewQuestion,
}) => {
  const questionsSorted = questions.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

  return <div style={{
    display: 'grid',
    rowGap: '15px',
  }}>
    {questionsSorted.map((question) => {
      return <div key={question.id} style={{
        display: 'grid',
        rowGap: '5px'
      }}>
        <InterviewQuestion 
          question={question}
          onDeleted={onDeletedInterviewQuestion}
          onUpdated={onUpdatedInterviewQuestion}
        />
      </div>
    })}
  </div>
}

export default InterviewQuestionList;

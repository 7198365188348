import JobAppUpdate from '../../types/JobAppUpdate';
import { patchForJson } from '../xhr';

export default function xhrUpdateJobApp(
  jobAppId: string,
  updates: JobAppUpdate,
  accessToken: string,
) {
  return patchForJson(
    `/jobapps/${jobAppId}`,
    updates,
    accessToken,
  );
}

import { Button, Empty, Space, Spin } from "antd"
import Loading from "../../../../components/JobAppBoard/Loading";
import { OrganizationGoal } from "../../types/organization-goal.type";
import { OrganizationGoalCard } from "./OrganizationGoalCard";

interface Props {
  goals: OrganizationGoal[]
  loading?: boolean
  onClickGoal?: (goal: OrganizationGoal) => void
  isFetchingMore?: boolean
  hasMore?: boolean;
  onClickFetchMore?: () => void
  emptyText: string
}

export const OrganizationGoalCardList = ({
  goals,
  loading,
  onClickGoal,
  isFetchingMore,
  hasMore,
  onClickFetchMore,
  emptyText
}: Props) => {

  return <Space
    direction="vertical"
    style={{
      display: 'flex',
      flexDirection: 'column',
    }}
    size="middle"
  >
    {loading && <Loading />}
    {goals?.length === 0 && 
      <div style={{display: 'flex', justifyContent: 'center'}}>
        {/* <Typography.Text type="secondary">{emptyText}</Typography.Text> */}
        <Empty description={emptyText} image={Empty.PRESENTED_IMAGE_SIMPLE}  />
      </div>
    }
    {goals?.map((goal) => <OrganizationGoalCard
      title={goal.name}
      status={goal.status}
      dueTimestamp={goal.dueTimestamp}
      onClick={() => onClickGoal?.(goal)}
      recurringInterval={goal.checklistTaskTemplate.recurringInterval}
    />)}
    {isFetchingMore && <div
      style={{
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <Spin />
    </div>}
    {!isFetchingMore && hasMore && <Button 
      type="ghost"
      block 
      onClick={() => onClickFetchMore?.()}
    >
      Load more
    </Button>}
  </Space>
}

import React, { useCallback, useContext, useEffect, useState } from 'react';
import xhrGetUserOrganizations from '../adapters/users/xhrGetUserOrganizations';
import Organization from '../types/Organization';
import OrganizationBoardTemplate from '../types/OrganizationBoardTemplate';
import OrganizationColumnTemplate from '../types/OrganizationColumnTemplate';
import { UserContext } from './UserProvider';
import xhrGetCopyBoardToOrgDetails from '../adapters/jobAppBoards/xhrGetCopyBoardToOrgDetails';

type AppOrganizationViewContextData = {
  organizations: Organization[] | null
  organizationId: string | null,
  selectedOrganization: Organization | null,
  switchToOrganization: (organizationId: string) => Promise<void>
  switchToPersonal: () => void
  getCopyBoardToOrgDetails: (organizationId: string) => Promise<{
    boardTemplate: OrganizationBoardTemplate,
    columnTemplates: OrganizationColumnTemplate[],
  }>
  refetchOrganizations: () => Promise<void>
};

const defaultFunction = () => {
  throw new Error('Not implemented');
};

export const AppOrganizationViewContext = React.createContext<AppOrganizationViewContextData>({
  organizations: null,
  organizationId: null,
  selectedOrganization: null,
  switchToOrganization: defaultFunction,
  switchToPersonal: defaultFunction,
  getCopyBoardToOrgDetails: defaultFunction,
  refetchOrganizations: defaultFunction,
});

export const ORGANIZATION_ID_KEY = 'organizationId'

export const AppOrganizationViewProvider: React.FC = ({ children }) => {
  const [organizations, setOrganizations] = useState<Organization[] | null>(null);
  const [organizationId, setOrganizationId] = useState<string | null>(null)
  const { getAccessToken } = useContext(UserContext)
  const selectedOrganization = !organizationId ? null : organizations?.find((org) => org.id === organizationId) || null

  const fetchOrganizations = useCallback(async () => {
    getAccessToken()
      .then(xhrGetUserOrganizations)
      .then(response => {
        const { organizationMembersOf }: { organizationMembersOf: Organization[] } = response
        setOrganizations(organizationMembersOf)
      })
      .catch(err => {
        console.error(err)
        setOrganizationId('')
        setOrganizations([])
      })
  }, [getAccessToken])

  useEffect(() => {
    fetchOrganizations()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const switchToOrganization: AppOrganizationViewContextData['switchToOrganization'] = useCallback(async (organizationId) => {
    if (!organizations) {
      return;
    }
    if (!organizations.find(o => o.id === organizationId)) {
      throw new Error("Cannot switch to invalid organization")
    }
    setOrganizationId(organizationId);
  }, [organizations])

  const switchToPersonal: AppOrganizationViewContextData['switchToPersonal'] = useCallback(() => {
    setOrganizationId('');
  }, [])

  const getCopyBoardToOrgDetails: AppOrganizationViewContextData['getCopyBoardToOrgDetails'] = useCallback(async (organizationId) => {
    const accessToken = await getAccessToken()
    return xhrGetCopyBoardToOrgDetails(organizationId, accessToken);
  }, [getAccessToken]);

  useEffect(() => {
    if (organizationId === null) {
      return
    }
    localStorage.setItem(ORGANIZATION_ID_KEY, organizationId || '')
  }, [organizationId])

  useEffect(() => {
    if (!organizations) {
      return;
    }
    const storedOrgId = localStorage.getItem(ORGANIZATION_ID_KEY)
    if (!storedOrgId) {
      switchToPersonal()
    } else {
      if (organizations?.find((o) => o.id === storedOrgId)) {
        switchToOrganization(storedOrgId).catch(err => console.error(err))
      } else {
        switchToPersonal()
      }
    }
  }, [organizations, switchToOrganization, switchToPersonal])

  return (
    <AppOrganizationViewContext.Provider value={{
      organizations,
      organizationId,
      selectedOrganization,
      switchToOrganization,
      switchToPersonal,
      getCopyBoardToOrgDetails,
      refetchOrganizations: fetchOrganizations
    }}
    >
      {children}
    </AppOrganizationViewContext.Provider>
  );
};

import { SettingFilled } from "@ant-design/icons";
import { Button, Dropdown } from "antd";
import { useContext, useState } from "react";
import themeColors from "../../../../constants/themeColors";
import { ConfirmModalContext } from "../../../../contexts/ConfirmModalProvider";
import { JobAppBoardContext } from "../../../../contexts/JobAppBoardProvider";
import JobAppBoard from "../../../../types/JobAppBoard";
import notifyError from '../../../../util/notifyError';
import { navigate } from "@reach/router";
import pages from "../../../../constants/pages";
import EditJobBoardModal from "../../EditJobBoardModal";

interface Props {
  board: JobAppBoard
  isOrganization?: boolean
}

const BoardSettingsDropdown: React.FC<Props> = ({
  board,
  isOrganization = false
}) => {
  const { showConfirmModal } = useContext(ConfirmModalContext);
  const { deleteJobAppBoard } = useContext(JobAppBoardContext);
  const [showEditBoardModal, setShowEditBoardModal] = useState(false);

  const onClickDelete = () => {
    showConfirmModal({
      title: `Are you sure you want to delete this board?`,
      content: 'This will permanently delete all jobs within this board.',
      okButton: <Button danger type="primary">Delete</Button>,
      onOk: async () => {
        try {
          await deleteJobAppBoard(board.id);
          navigate(pages.BOARD)
        } catch (err) {
          notifyError('Unable to delete board', (err as Error).message);
        }
      },
    });
  }

  const menuItems =  [{
    key: 'delete',
    onClick: onClickDelete,
    label: 'Delete Board',
    danger: true
  }]
  

  if (!isOrganization) {
    menuItems.unshift({
      key: 'rename',
      onClick: () => setShowEditBoardModal(true),
      label: 'Rename Board',
      danger: false
    })
  }

  return <>
    <EditJobBoardModal
      onClose={() => setShowEditBoardModal(false)}
      visible={showEditBoardModal}
      selectedBoard={board}
    />
    <Dropdown 
      trigger={['click']}
      menu={{
        items: menuItems
      }}
    >
      <Button icon={<SettingFilled />} type="ghost" style={{
        background: themeColors.background.faintBlue,
        marginLeft: '1rem'
      }}>
      </Button>
    </Dropdown>
  </>
}

export default BoardSettingsDropdown

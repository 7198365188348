import dayjs from "dayjs";
import xhrGetJobAppInterviews from "../../../adapters/jobAppInterviews/xhrGetJobAppInterviews";

interface Options {
  offset: number
  limit: number
  filters: {
    gte: Date,
    lte: Date
  };
}

export const getInterviews = ({
  limit,
  offset,
  filters: {
    gte,
    lte,
  },
}: Options, accessToken: string) => {
  return xhrGetJobAppInterviews(accessToken, {
    limit,
    offset,
    filters: {
      date: {
        gte: dayjs(gte).utc().toISOString(),
        lte: dayjs(lte).utc().toISOString(),
      },
    }
  })
}

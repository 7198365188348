import { Segmented, Typography } from "antd";
import { GoalViewType } from "../../types"

interface Props {
  viewType: GoalViewType;
  onChangeViewType: (viewType: GoalViewType) => void
}

export const OrganizationGoalsHeader = ({
  viewType,
  onChangeViewType,
}: Props) => {
  return     <div style={{
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: '4px',
    alignItems: 'center',
  }}>
    <Typography.Title level={1} style={{
      fontSize: '16px',
      margin: '0'
    }}>
      {viewType === GoalViewType.Current ? "Current Goals" : "Past Goals"}
    </Typography.Title>
    <Segmented
      options={[{
        value: 'current',
        label: 'Current'
      }, {
        value: 'past',
        label: 'Past'
      }]}
      value={viewType}
      onResize={() => {}}
      onResizeCapture={() => {}}
      onChange={e => onChangeViewType(e as GoalViewType)} 
    />
  </div>
}
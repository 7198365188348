import React, { useContext } from 'react';
import { Popover } from 'antd';
import { CompactPicker } from 'react-color';
import { InferProps } from 'prop-types';
import styled from 'styled-components';
import notifyError from '../../../util/notifyError';
import { jobAppPropTypes } from '../types/jobApp';
import { JobAppContext } from '../../../contexts/JobAppProvider';

interface ColorButtonProps {
  color: string;
}

const ColorButton = styled.button<ColorButtonProps>`
  height: 24px;
  width: 24px;
  background: ${({ color }) => color};
  margin-right: 10px;
`;

function JobAppColorSelection({ jobApp }: InferProps<typeof JobAppColorSelection.propTypes>) {
  const { updateJobApp } = useContext(JobAppContext);

  async function edit(color: string) {
    try {
      const toPost = {
        color,
      };
      await updateJobApp(jobApp.id, toPost);
    } catch (err) {
      notifyError('Unable to edit color', (err as Error).message);
    }
  }

  return (
    <Popover
      trigger={['click']}
      placement="bottomLeft"
      arrowPointAtCenter
      content={<CompactPicker onChangeComplete={(e) => edit(e.hex)} />}
    >
      <ColorButton type="button" color={jobApp.color || ''} title="Change color" />
    </Popover>
  );
}

JobAppColorSelection.propTypes = {
  jobApp: jobAppPropTypes.isRequired,
};

export default JobAppColorSelection;

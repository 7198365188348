import styled from "styled-components";
import themeColors from "../../../constants/themeColors";

export const MenuSideBarViewSelectorRow = styled.div`
  color: ${themeColors.background.offWhite};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  flex: 1;
  height: 50px;
  `;

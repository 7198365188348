import { Button, Form, Input, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { Store } from 'antd/lib/form/interface';
import React, { useContext, useState } from 'react';
import BulkJobApp from '../../../../types/BulkJobApp';
import notifyError from '../../../../util/notifyError';
import StepTitle from '../StepTitle';
import notifySuccess from '../../../../util/notifySuccess';
import { JobAppImportContext } from '../../../../contexts/JobAppImportProvider';

interface UploadingCsvJobsProps {
  onGoBack: () => void,
  onUploaded: () => void,
  csvMapping: BulkJobApp[],
}

const UploadingCsvJobs: React.FC<UploadingCsvJobsProps> = ({
  onGoBack,
  csvMapping,
  onUploaded,
}) => {
  const [uploading, setUploading] = useState(false);
  const [form] = useForm();
  const { importJobApps } = useContext(JobAppImportContext);

  const uploadJobApps = async (data: Store) => {
    const { boardName } = data;
    setUploading(true);
    try {
      await importJobApps(boardName as string, csvMapping);
      notifySuccess('Your job applications have been imported!');
      onUploaded();
    } catch (err) {
      notifyError('Unable to import job apps', (err as Error).message);
    } finally {
      setUploading(false);
    }
  };

  return (
    <div>
      <StepTitle>What will your board name be?</StepTitle>
      <Form
        form={form}
        layout="vertical"
        onFinish={uploadJobApps}
      >
        <Form.Item
          name="boardName"
          rules={[{
            required: true,
            message: 'Please input a board name',
          }]}
        >
          <Input placeholder="Board name" />
        </Form.Item>
        <br />
        <Form.Item>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Button
              style={{ width: '100%' }}
              disabled={uploading}
              onClick={onGoBack}
            >
              Back
            </Button>
            <Button
              style={{ width: '100%' }}
              htmlType="submit"
              type="primary"
              disabled={uploading}
            >
              Create
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default UploadingCsvJobs;

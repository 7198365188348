import { useInfiniteQuery } from "react-query"
import useAuthZero from '../../../hooks/useAuthZero';
import { getOrganizationGoals, GetOrganizationGoalsInput } from "../api";

export const useOrganizationGoals = (input: GetOrganizationGoalsInput) => {
  const { getAccessToken } = useAuthZero()

  const {
    data,
    status,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage
  } = useInfiniteQuery(['organization-goals', input], async ({
    pageParam
  }) => {
    const accessToken = await getAccessToken()()

    return getOrganizationGoals({
      ...input,
      limit: pageParam?.limit || input.limit,
      offset: pageParam?.offset || input.offset,
    }, accessToken)
  }, {
    keepPreviousData: true,
    getNextPageParam: (lastPage, pages) => {
      const { results: lastPageResults } = lastPage

      const countOfAllPage = pages.map((p) => p.results).flat().length

      if (lastPageResults.length < input.limit) {
        return undefined
      }

      return {
        limit: input.limit,
        offset: countOfAllPage
      }
    }
  })

  return {
    data,
    status,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage
  }
}

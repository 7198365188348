import { Select } from "antd";
import OrganizationColumnTemplate from "../../../../types/OrganizationColumnTemplate";

interface Props {
  onSelect: (columnId: string) => void
  loading?: boolean
  columnTemplates: OrganizationColumnTemplate[]
}

const SelectOrgColumnDropdown: React.FC<Props> = ({
  onSelect,
  columnTemplates,
  loading
}) => {
  return <Select 
    loading={loading}
    onChange={(columnId: string) => onSelect(columnId)} 
    style={{
      width: '175px'
    }}>
    {columnTemplates.map((colTemplate) => <Select.Option 
      key={colTemplate.id}
      value={colTemplate.id}>
      {colTemplate.name}
    </Select.Option>)}
  </Select>
}

export default SelectOrgColumnDropdown;

import { Button, Space } from "antd";
import styled from "styled-components";
import themeColors from "../../../../../constants/themeColors";

interface NotificationSeenButtonProps {
  isSeen: boolean
}

export const NotificationContainer = styled(Space)<NotificationSeenButtonProps>`
  display: flex;
  justify-content: space-between;
  background: ${props => props.isSeen ? 'none' : themeColors.background.faintBlue};
  padding: 0.5rem;
  border-radius: 0.5rem;
  &:hover {
    background: ${props => props.isSeen ? themeColors.background.offWhite : themeColors.background.faintBlue};
  }
  &:hover, &:focus-within {
    svg {
      color: ${props => props.isSeen ? themeColors.background.gray : themeColors.brand.primary};
    }
  }
`

export const NotificationSeenButton = styled(Button)<NotificationSeenButtonProps>`
  svg {
    color: ${props => props.isSeen ? 'transparent' : themeColors.brand.primary};
    transition: all .2s;
  }
`;

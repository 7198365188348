import { faCheck, faEdit, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Form, Input, Popconfirm, Space } from "antd";
import { Store } from "rc-field-form/lib/interface";
import React, { useContext, useState } from "react";
import themeColors from "../../../../../constants/themeColors";
import { JobAppNoteContext } from "../../../../../contexts/JobAppNoteProvider";
import JobAppNote from "../../../../../types/JobAppNote";
import notifyError from "../../../../../util/notifyError";
import HTMLContent from "../../../InterviewComponents/HTMLContent/HTMLContent";
import RichEditor from "../../../RichEditor";
import { NoteCollapsePanel, NoteExtraButtons } from "./Note.styles";

interface Props {
  note: JobAppNote;
  onUpdated: (updatedNote: JobAppNote) => void
  onRemoved: () => void
}

const Note: React.FC<Props> = ({
  note,
  onUpdated,
  onRemoved,
  ...props
}) => {
  const {
    updateJobAppNote,
    deleteJobAppNote,
  } = useContext(JobAppNoteContext)
  const [editingName, setEditingName] = useState(false);
  const [editingText, setEditingText] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const stopPropagationOnEnter = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.stopPropagation();
    }
  };
  
  const onClickEdit = (e: React.MouseEvent) => {
    e.stopPropagation()
    setEditingName(true)
  }

  const onClickDelete = (e: React.MouseEvent) => {
    e.stopPropagation()
  }

  const onFinishForm = async (data: Store) => {
    const { name, text } = data
    
    try {
      setUpdating(true)
      const payload: Partial<JobAppNote> = {}
      if (name != null) {
        payload.name = name
      }
      if (text != null) {
        payload.text = text
      }
      const updatedNote = await updateJobAppNote(note.id, payload)
      onUpdated(updatedNote)
      setEditingName(false)
      setEditingText(false)
    } catch (err) {
      notifyError(`Failed to update note`, (err as Error).message)
    } finally {
      setUpdating(false)
    }
  }

  const onConfirmDelete = async () => {
    try {
      setDeleting(true)
      await deleteJobAppNote(note.id);
      onRemoved()
    } catch (err) {
      notifyError(`Failed to delete note`, (err as Error).message)
    } finally {
      setDeleting(false)
    }
  }

  const onClickEditText = () => {
    setEditingText(true)
  }

  const onClickCancelEditText = () => {
    setEditingText(false)
  }

  const EditHeader = () => {
    return <Form
      onClick={(e) => e.stopPropagation()}
      onFinish={onFinishForm}
      style={{
        display: 'inline-block'
      }}
    >
      <Space style={{ display: 'flex', flexWrap: 'wrap' }} align="baseline">
        <Form.Item
          name="name"
          rules={[{
            required: true,
            message: 'Note name is required',
          }]}
          style={{ margin: 0, width: '18rem' }}
          initialValue={note.name}
        >
          <Input placeholder="Note name" onKeyPress={stopPropagationOnEnter} />
        </Form.Item>
        <Form.Item
          style={{ margin: 0, width: '5rem' }}
        >
          <Space>
            <Button
              type="text"
              htmlType="submit"
              size="small"
              disabled={updating}
              icon={(
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: themeColors.semantic.success }}
                />
              )}
              onKeyPress={stopPropagationOnEnter}
            />
            <Button
              type="text"
              htmlType="button"
              size="small"
              disabled={updating}
              icon={(
                <FontAwesomeIcon
                  icon={faTimes}
                />
              )}
              onClick={() => setEditingName(false)}
              onKeyPress={stopPropagationOnEnter}
            />
          </Space>
        </Form.Item>
      </Space>
    </Form>
  }
  
  return (
    <NoteCollapsePanel
      {...props}
      header={editingName
        ? EditHeader()
        :       <NoteExtraButtons>
          <span>
            {note.name}
          </span>
          <Space className='note-buttons' hidden={editingName}>
            <Button 
              type="text"
              size="small"
              icon={<FontAwesomeIcon icon={faEdit} />} 
              onClick={onClickEdit}
            />
            <Popconfirm
              title="Are you sure you want to remove this note?"
              onConfirm={onConfirmDelete}
              // Prevents the collapse from expanding/collapsing
              onOpenChange={(visible, e) => e?.stopPropagation()}
              okButtonProps={{
                disabled: deleting,
              }}
              cancelButtonProps={{}}
            >
              <Button 
                type="text"
                size="small"
                disabled={deleting}
                onClick={onClickDelete}
                icon={<FontAwesomeIcon icon={faTrash} />}
              />
            </Popconfirm>
          </Space>
        </NoteExtraButtons>
      } 
      key={note.id}>
      {editingText && <RichEditor
        initialValue={note.text}
        onCancel={onClickCancelEditText}
        onSave={(newText) => onFinishForm({ text: newText })}
      />}
      {!note.text && !editingText && <span style={{color: themeColors.background.gray}}>No notes set</span>}
      {note.text && !editingText && <HTMLContent
        style={{
          padding: 0,
          border: 'none',
          minHeight: 'initial',
        }}
        dangerouslySetInnerHTML={{
          __html: note.text
        }}
      />}
      <Button
        hidden={editingText}
        size="small"
        style={{
          marginTop: '0.5rem',
          display: 'block'
        }}
        onClick={onClickEditText}
      >
        Edit
      </Button>
    </NoteCollapsePanel>
  )
}

export default Note;


import { useQuery } from "react-query"
import { getOrganizationInvites, updateOrganizationInvite } from "../api"
import useAuthZero from '../../../hooks/useAuthZero';

export const useOrganizationInvites = () => {
  const { getAccessToken } = useAuthZero()

  const {
    data,
    status,
    error,
  } = useQuery('org-invites', async () => {
    const accessToken = await getAccessToken()()
    return getOrganizationInvites(accessToken)
  })

  const acceptInvite = async (inviteId: string) => {
    return updateOrganizationInvite(inviteId, {
      status: 'accepted'
    }, await getAccessToken()())
  }

  const declineInvite = async (inviteId: string) => {
    return updateOrganizationInvite(inviteId, {
      status: 'rejected'
    }, await getAccessToken()())
  }

  return {
    data,
    status,
    error,
    acceptInvite,
    declineInvite
  }
}

/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import styled from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { transparentize } from 'polished';
import { Button, Typography } from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import colors from '../../constants/colors';
import {ConfirmModalContext} from '../../contexts/ConfirmModalProvider';
import themeColors from '../../constants/themeColors';
import notifyError from '../../util/notifyError';
import JobApp from '../../types/JobApp';
import JobAppColumn from '../../types/JobAppColumn';
import JobAppAvatar from './JobAppAvatar';
import { JobAppContext } from '../../contexts/JobAppProvider';

dayjs.extend(utc);
dayjs.extend(relativeTime);

interface ContainerProps {
  isDragging: boolean;
}

const Container = styled.div<ContainerProps>`
  position: relative;
  /* background: #F8FAFB; */
  background: ${themeColors.background.white};
  border: solid 1px ${themeColors.background.offWhite};
  margin-bottom: 10px;
  /** 10px is the color bar */
  padding: 5px 10px 5px calc(6px + .5rem);
  height: 80px;
  color: ${colors.gray};
  .hover-buttons {
    opacity: 0;
  }
  box-shadow: ${(props) => (props.isDragging ? `0px 5px 5px ${transparentize(0.9, themeColors.background.dark)}` : `0px 1px 5px ${transparentize(0.9, themeColors.background.dark)}`)};
  &:hover, &:focus, &:focus-within {
    cursor: pointer;
    box-shadow: 0px 5px 5px ${transparentize(0.9, themeColors.background.dark)};
    .hover-buttons {
      opacity: 1;
    }
  }
  border-radius: 10px;
`;

interface ColorBarProps {
  color: string | number;
}

const ColorBar = styled.div<ColorBarProps>`
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  background: ${({ color }) => color};
  width: 6px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
`;

const HoverButtons = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 7px 15px 7px 7px;
  button {
    background: transparent;
    border-style: none;
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px !important;
  width: 25px !important;
  border-radius: 5px;
  background: transparent !important;
  color: ${themeColors.background.gray} !important;
  svg {
    font-size: 1.05rem;
  }
  &:hover, &:focus {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
    color: ${(props) => (props.color === 'red' ? themeColors.semantic.danger : themeColors.background.dark)} !important;
  }
  transition: color .2s, box-shadow .2s;
`;

const CardAvatarAndText = styled.div`
   display: flex;
   align-items: center;
   height: 100%;
`;

const TextDisplay = styled.div`
  margin-right: 50px;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

interface JobAppCardProps {
  jobApp: JobApp;
  index: number;
  column: JobAppColumn;
  disableDrag: boolean;
}

const JobAppCard: React.FC<JobAppCardProps> = ({
  jobApp,
  index,
  column,
  disableDrag,
}) => {
  const confirmModalContext = useContext(ConfirmModalContext);
  const { setEditingJobAppId, deleteJobApp } = useContext(JobAppContext);

  const {
    title,
    id,
    company,
  } = jobApp;

  function showEditModal() {
    setEditingJobAppId(jobApp.id);
  }

  async function onClickDelete(event: React.MouseEvent) {
    event.stopPropagation();
    try {
      confirmModalContext.showConfirmModal({
        title: `Are you sure you want to delete this job application (${jobApp.title ? `${jobApp.title} at ` : ''}${jobApp.company})?`,
        content: 'This cannot be undone.',
        okButton: <Button autoFocus danger type="primary">Delete</Button>,
        onOk: async () => {
          await deleteJobApp(column.boardId, column.id, jobApp.id);
        },
      });
    } catch (err) {
      notifyError('Unable to delete job application', (err as Error).message);
    }
  }

  function onClickEdit(event: React.MouseEvent) {
    // We could not stop propagation and the modal will still show on click, but
    // this needs to be done for accessiblility
    event.stopPropagation();
    setEditingJobAppId(jobApp.id);
  }

  return (
    <Draggable draggableId={id} index={index} isDragDisabled={!!disableDrag}>
      {(provided, snapshot) => (
        <Container
          // title={company}
          // size="small"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          onClick={showEditModal}
          isDragging={snapshot.isDragging}
        >
          <ColorBar color={jobApp.color || ''} />
          <CardAvatarAndText>
            <JobAppAvatar
              size={42}
              style={{
                marginRight: '.5rem',
              }}
              jobApp={jobApp}
            />
            <TextDisplay>
              <Typography.Text type="secondary">{dayjs(jobApp.updatedAt).local().fromNow()}</Typography.Text>
              <Typography.Text strong>{company}</Typography.Text>
              {
              /**
               * \u200b is a "zero-width space" character. Basically "invisible" text used to align
               * the company text on every card by moving it up if there is no job position text.
               */
              }
              <Typography.Text>{title || '\u200b'}</Typography.Text>
            </TextDisplay>
          </CardAvatarAndText>
          <HoverButtons className="hover-buttons">
            <StyledButton aria-label="Edit job app" onClick={onClickEdit}>
              <EditOutlined />
            </StyledButton>
            <StyledButton aria-label="Delete job app" onClick={onClickDelete} color="red">
              <DeleteOutlined />
            </StyledButton>
          </HoverButtons>
        </Container>
      )}
    </Draggable>
  );
};

export default JobAppCard;

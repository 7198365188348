import { getJson } from '../xhr';

interface UserProfileResponse {
  id: string;
  firstName?: string;
  lastName?: string;
}

export default function xhrGetUserInformation(
  accessToken: string,
): Promise<UserProfileResponse> {
  return getJson(`/users/@me/`, accessToken);
}

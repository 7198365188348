import { Avatar } from "antd";
import styled from "styled-components";
import themeColors from "../../../constants/themeColors";

export const MenuSideBarPersonalCardAvatar = styled(Avatar)`
  background: ${themeColors.brand.primary};
  min-width: 30px;
  min-height: 30px;
  height: 30px;
  width: 30px;
  line-height: 29px;
`;

export const MenuSideBarPersonalCardName = styled.div`
  display: inline;
`

import "shepherd.js/dist/css/shepherd.css";
import './index.css';
import ReactDOM from 'react-dom';
import './i18n';
import { Auth0Provider } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'react-quill/dist/quill.snow.css';
import 'simplebar/dist/simplebar.min.css';
import { QueryClientProvider, QueryClient } from 'react-query'
import { ConfigProvider, theme } from 'antd'
import themeColors from "./js/constants/themeColors";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
    }
  }
})

// NODE_ENV is set to production during build process
// NODE_ENV is set to development with the dev server
if (process.env.NODE_ENV === 'production') {
  // put this as an env variable when we push to production.
  Sentry.init({ dsn: 'https://42c4bbd65aa24e42a7ce7bf01ebd9c75@o434382.ingest.sentry.io/5391378' });
}

ReactDOM.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
    clientId={process.env.REACT_APP_AUTH0_CLIENTID || ''}
    audience={process.env.REACT_APP_AUTH0_AUDIENCE || ''}
    scope={process.env.REACT_APP_AUTH0_SCOPE || ''}
    redirectUri={window.location.origin}
  >
    <QueryClientProvider client={queryClient}>
      <ConfigProvider theme={{
        algorithm: (token) => ({
          ...theme.defaultAlgorithm(token),
          fontFamily: "'Poppins', 'Segoe UI', sans-serif, Tahoma, Geneva, Verdana",
          colorText: themeColors.background.dark,
          colorPrimary: themeColors.brand.primary,
          colorError: themeColors.semantic.danger,
          colorTextSecondary: themeColors.background.mediumGray,
        })
      }}>
        <App />
      </ConfigProvider>
    </QueryClientProvider>
  </Auth0Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
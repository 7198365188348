import React, { useEffect, useState } from 'react';
import { Button, Input } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import themeColors from '../constants/themeColors';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;

const SearchBar = styled(Input)`
  padding-left: 15px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;

const SearchButton = styled(Button)`
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  margin-bottom: 0 !important;
  flex: 0;
`;

/* clearFunction is used for any cleaning up you need to do in the parent component
 when input gets emptied or user presses 'X' */
interface JobSearchbarProps {
  style?: React.CSSProperties,
  size?: SizeType,
  placeholder: string,
  searchFunction: Function,
  clearFunction: Function
}

const JobSearchBar: React.FC<JobSearchbarProps> = ({
  placeholder,
  searchFunction,
  clearFunction,
  style,
  size = 'middle',
}) => {
  const [searchInput, setSearch] = useState('');

  const search = () => {
    if (searchInput.length) {
      searchFunction(searchInput);
    }
  };

  const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') search();
  };

  const clearSearch = () => {
    setSearch('');
    clearFunction();
  };

  // Clear the search on unmount
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => clearSearch(), []);

  return (
    <Wrapper style={style}>
      <SearchBar
        placeholder={` ${placeholder}`}
        suffix={(
          <>
            <span
              hidden={!searchInput.length}
              onClick={clearSearch}
              role="button"
              tabIndex={0}
              onKeyDown={(e) => (e.key === 'Enter' ? clearSearch() : null)}
              style={{cursor: 'pointer'}}
            >
              <FontAwesomeIcon icon={faTimes} style={{ color: themeColors.background.gray }} />
            </span>
          </>
        )}
        size={size}
        value={searchInput}
        onChange={(e) => {
          setSearch(e.target.value);
          if (e.target.value === '') {
            clearFunction();
          }
        }}
        onKeyDown={handleEnter}
      />
      <SearchButton type="primary" onClick={search} size={size}>
        {/* <SearchOutlined style={{ marginLeft: '-3px' }} /> */}
        <FontAwesomeIcon icon={faSearch} style={{ marginLeft: '-3px' }} />
      </SearchButton>
    </Wrapper>
  );
};

export default JobSearchBar;

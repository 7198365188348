const pages = {
  HOME: 'https://stipplo.com',
  SEND_RESET_PASSWORD: '/send-reset-password',
  LOGIN: '/login',
  REGISTER: '/register',
  APP: '/app',
  BOARD: '/app/boards',
  IMPORT_BOARD: '/app/boards/import',
  DOCUMENTS: '/app/documents',
  SETTINGS_ACCOUNT: '/app/account-settings',
  SETTINGS_SUBSCRIPTION: '/app/subscription-settings',
  INTERVIEWS: '/app/interviews',
  COPY_TO_ORG: '/app/boards/copy-to-org',
  ABOUT_US: 'https://stipplo.com/about-us',
  PRIVACY_POLICY: 'https://stipplo.com/privacy-policy',
  TERMS_OF_SERVICE: 'https://stipplo.com/terms-of-service',
  COOKIES_POLICY: 'https://stipplo.com/cookies-policy',
  // This routes to reset password, recover email, or verify email.
  ACCOUNT_ACTIONS: '/account-actions',
};

export default pages;

import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Typography, Space } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import React, { useState } from 'react';
import ThemedModal from './ThemedModal';

const iconStyles = (color?: string): React.CSSProperties => ({
  fontSize: '3rem',
  color,
});

interface SupportModalProps {
  trigger: React.ReactElement;
}

const SupportModal: React.FC<SupportModalProps> = ({ trigger }) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      {React.cloneElement(trigger, {
        onClick: () => setVisible(true),
      })}
      <ThemedModal
        title="Feedback & Support"
        open={visible}
        onCancel={() => setVisible(false)}
        destroyOnClose
        footer={(
          <Button
            onClick={() => setVisible(false)}
          >
            Close
          </Button>
        )}
      >
        <Paragraph>
          We&apos;d love to hear your questions or feedback. Please feel
          free to contact us via email!
        </Paragraph>
        <Space size="middle">
          <FontAwesomeIcon
            style={iconStyles()}
            icon={faEnvelope}
          />
          <div>
            <Typography.Text strong style={{display: 'block'}}>Email</Typography.Text>
            <Typography.Link href="mailto:support@stipplo.com">
              support@stipplo.com
            </Typography.Link>
          </div>
        </Space>
      </ThemedModal>
    </>
  );
};

export default SupportModal;

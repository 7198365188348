import React, {
  useCallback, useContext,
} from 'react';
import { UserContext } from './UserProvider';
import Contact from '../types/Contact';
import xhrCreateContactForJobApp from '../adapters/contacts/xhrCreateContactForJobApp';
import xhrUpdateContact from '../adapters/contacts/xhrUpdateContact';
import xhrRemoveContactFromJobApp from '../adapters/contacts/xhrRemoveContactFromJobApp';
import xhrSearchContacts from '../adapters/contacts/xhrSearchContacts';

type ContactsContextData = {
  createContactForJobApp(jobAppId: string, contact: Partial<Contact>): Promise<Contact>
  editContact: (id: string, contact: Partial<Contact>) => Promise<Contact>
  removeContactFromJobApp: (jobAppId: string, contactId: string) => Promise<void>
  searchContacts: (query: string) => Promise<Contact[]>
};

const defaultFunction = () => {
  throw new Error('Not implemented');
};

export const ContactsContext = React.createContext<ContactsContextData>({
  createContactForJobApp: defaultFunction,
  editContact: defaultFunction,
  removeContactFromJobApp: defaultFunction,
  searchContacts: defaultFunction,
});

export const ContactsProvider: React.FC = ({ children }) => {
  const { getAccessToken } = useContext(UserContext);

  const createContactForJobApp: ContactsContextData['createContactForJobApp'] = useCallback(async (jobAppId: string, contact: Partial<Contact>) => {
    const accessToken = await getAccessToken()
    const jobApp = await xhrCreateContactForJobApp(jobAppId, contact, accessToken);
    return jobApp;
  }, [getAccessToken]);

  const editContact: ContactsContextData['editContact'] = useCallback(async (contactId: string, updates: Partial<Contact>) => {
    const accessToken = await getAccessToken()
    const jobApp = await xhrUpdateContact(contactId, updates, accessToken);
    return jobApp;
  }, [getAccessToken]);

  const removeContactFromJobApp: ContactsContextData['removeContactFromJobApp'] = useCallback(async (jobAppId: string, contactId: string) => {
    const accessToken = await getAccessToken()
    const jobApp = await xhrRemoveContactFromJobApp(jobAppId, contactId, accessToken);
    return jobApp;
  }, [getAccessToken]);

  const searchContacts: ContactsContextData['searchContacts'] = useCallback(async (query: string) => {
    const accessToken = await getAccessToken()
    const contacts = await xhrSearchContacts(query, accessToken);
    return contacts;
  }, [getAccessToken]);

  return (
    <ContactsContext.Provider value={{
      createContactForJobApp,
      editContact,
      removeContactFromJobApp,
      searchContacts,
    }}
    >
      {children}
    </ContactsContext.Provider>
  );
};

import { SyncOutlined } from "@ant-design/icons";
import { Tooltip, Typography } from "antd"
import dayjs from "dayjs"
import { GoalStatus } from "../../types";
import relativeTime from 'dayjs/plugin/relativeTime'
import styled from "styled-components";
import { transparentize } from "polished";
import themeColors from "../../../../constants/themeColors";

dayjs.extend(relativeTime)

const CardContainer = styled.div`
  border-radius: 0.5rem;
  padding: 0.5rem;
  flex-grow: 1;
  box-shadow: 0px 1px 5px ${transparentize(0.9, themeColors.background.dark)};
  cursor: pointer;
  &:hover, &:focus, &:focus-within {
    cursor: pointer;
    box-shadow: 0px 5px 5px ${transparentize(0.9, themeColors.background.dark)};
  }
  border: solid 1px ${themeColors.background.offWhite};
`

interface Props {
  onClick?: () => void
  title: string
  status: GoalStatus;
  dueTimestamp?: number | null,
  recurringInterval?: {
    value: number,
    unit: string
  } | null
}

export const OrganizationGoalCard = ({
  onClick,
  title,
  status,
  dueTimestamp,
  recurringInterval
}: Props) => {
  const displayDiff = dueTimestamp ? dayjs.unix(dueTimestamp).fromNow() : null

  return <CardContainer
    role="button"
    onClick={onClick}
  >
    <div>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        <Typography.Text type="secondary" style={{display: 'block'}}>
          {status === GoalStatus.Complete && 'Completed'}
          {status === GoalStatus.Incomplete && 'Incomplete'}
        </Typography.Text>
        {recurringInterval && displayDiff && <Tooltip
          placement="leftBottom"
          title={`Resets ${displayDiff}`}
        >
          <SyncOutlined />
        </Tooltip>
        }
      </div>
      <Typography.Text strong>
        {title}
      </Typography.Text>
    </div>
    <div>
      <Typography.Text>
        {!dueTimestamp && 'No due date'}
        {dueTimestamp && `Due on ${dayjs.unix(dueTimestamp).format('MMM D, YYYY')}`}
      </Typography.Text>
    </div>
  </CardContainer>
}

import {
  Alert, Button, Form, Input,
} from 'antd';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import themeColors from '../../../constants/themeColors';
import { UserContext } from '../../../contexts/UserProvider';

const LabelStyles = styled.span`
  font-weight: 600;
`

const SettingsAccountPasswordForm: React.FC = () => {
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);
  const { isSocialSignin, updatePassword } = useContext(UserContext);
  const [failReason, setFailReason] = useState('');
  const [saving, setSaving] = useState(false);

  const onCancel = () => {
    setFailReason('');
    setIsEditing(false);
    form.resetFields();
  };

  async function setNewPassword({ newPassword }: { newPassword: string }) {
    setSaving(true);
    setFailReason('');
    try {
      await updatePassword(newPassword);
    } catch (err) {
      setFailReason((err as Error).message);
      form.resetFields();
    }
    setSaving(false);
    setIsEditing(false);
    // Auth0 invalidates the current session after a password change for some reason
    // So we have to force the user to reload
    window.location.href = '/login';
  }

  if (isSocialSignin) {
    return <div />;
  }

  return (
    <Form
      layout="vertical"
      autoComplete="off"
      form={form}
      onFinish={(vals: any) => setNewPassword(vals)}
    >
      {!isEditing && (
        <>
          <Form.Item label={<LabelStyles>Password</LabelStyles>}>
            <span className="ant-form-text">********</span>
            <Button onClick={() => setIsEditing(true)}>
            Edit
            </Button>
            <p style={{
              color: themeColors.background.gray,
              marginTop: '0.5rem',
            }}
            >
            Changing your password will require you to login again.
            </p>
          </Form.Item>
        </>
      )}
      {isEditing && (
        <>
          <Form.Item
            label={<LabelStyles>New Password</LabelStyles>}
            name="newPassword"
            /**
           * All of these rules are required for Auth0 to accept the new password. Our backend
           * will throw a 500 if Auth0 rejects the new password, with Auth0 giving a generic
           * "Password is too weak" error.
           */
            rules={[{
              required: true,
              min: 8,
              message: 'Must be at least 8 characters long',
            }, {
              required: true,
              pattern: /^(?=.*[a-z])/,
              message: 'Must contain a least one lowercase letter',
            }, {
              required: true,
              pattern: /^(?=.*[A-Z])/,
              message: 'Must contain at least one uppercase letter',
            }, {
              required: true,
              pattern: /^(?=.*[0-9])/,
              message: 'Must contain at least one number',
            }]}
          >
            <Input.Password
              autoComplete="off"
              /** LastPass ignore, if user consents the ignore in LP settings */
              data-lpignore="true"
            />
          </Form.Item>
          <Form.Item
            label={<LabelStyles>Confirm New Password</LabelStyles>}
            name="confirmNewPassword"
            rules={[{
              required: true,
              message: 'This is a required field',
            }, ({ getFieldValue }) => ({
              async validator(rule, value) {
                if (value && getFieldValue('newPassword') !== value) {
                  throw new Error('Passwords do not match');
                }
              },
            })]}
          >
            <Input.Password
              autoComplete="off"
              /** LastPass ignore, if user consents the ignore in LP settings */
              data-lpignore="true"
            />
          </Form.Item>
          {failReason.length > 0 && <Alert type="error" message="Failed to save new password" description={failReason} />}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={saving}
              style={{
                marginRight: '0.5rem',
              }}
            >
            Save
            </Button>
            <Button onClick={onCancel} disabled={saving}>Cancel</Button>
          </Form.Item>
        </>
      )}
    </Form>
  );
};

export default SettingsAccountPasswordForm;

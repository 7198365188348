import React from 'react';
import styled from 'styled-components';
import { lighten, darken, transparentize } from 'polished';
import { Button as AntButton } from 'antd';
import themeColors from '../../../constants/themeColors';

interface StyledButtonProps {
  bgcolor: string;
  shadow?: 'basic'|'colored'
}

const StyledButton = styled(AntButton)<StyledButtonProps>`
  ${(props) => props.type !== 'link' && `background: ${props.bgcolor};`};
  border: none;
  padding: 0 20px;
  transition-property: background-color, box-shadow;
  transition-duration: 0.2s;
  ${(props) => props.type !== 'link' && `color: ${themeColors.background.faintBlue};`}
  ${(props) => (props.disabled ? 'color: white !important;' : '')}
  outline: none;
  text-align: center;
  ${(props) => props.shadow === 'basic' && 'box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);'};
  ${(props) => props.shadow === 'colored' && `box-shadow: 0px 1px 15px 0px ${transparentize(0.75, props.bgcolor)};`};
  ${(props) => !props.shadow && 'box-shadow: none;'};
  cursor: pointer;
  user-select: none;
  &:hover, &:focus {
    ${(props) => props.type !== 'link' && `background: ${lighten(0.05, props.bgcolor)};`};
    ${(props) => props.type !== 'link' && `color: ${themeColors.background.faintBlue};`};
  }
  &:active {
    ${(props) => props.type !== 'link' && `background: ${darken(0.025, props.bgcolor)};`};
    ${(props) => props.shadow === 'basic' && 'box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);'};
    ${(props) => props.shadow === 'colored' && `box-shadow: 0px 1px 7px 0px ${transparentize(0.75, props.bgcolor)};`};
    ${(props) => !props.shadow && 'box-shadow: none;'};
  }
`;

const DisabledButton = styled(StyledButton)`
  cursor: not-allowed;
  color: ${lighten(0.2, themeColors.background.faintBlue)};
  opacity: 0.5;
  background: ${(props) => props.bgcolor} !important;
`;

type AntButtonProps = React.ComponentProps<typeof AntButton>;

type ButtonProps = {
  children?: React.ReactNode;
  color?: 'red'|'green'|'blue'|'orange';
  style?: React.CSSProperties;
  shadow?: 'basic'|'colored'
  disabled?: boolean;
  size?: AntButtonProps['size'];
  href?: AntButtonProps['href'];
  target?: AntButtonProps['target'];
  type?: AntButtonProps['type'];
  className?: AntButtonProps['className'];
  shape?: AntButtonProps['shape'];
  icon?: AntButtonProps['icon'];
  htmlType?: AntButtonProps['htmlType'];
  form?: AntButtonProps['form'];
  rel?: AntButtonProps['rel'];
  onClick?: () => void;
};

const Button: React.FC<ButtonProps> = (props) => {
  const {
    children,
    color,
    disabled,
    shadow,
    style = {},
    target,
    href,
    rel,
    size,
    type = 'text',
    shape = 'round',
    icon,
    onClick,
    className,
    htmlType,
    form,
  } = props;
  let backgroundColor: string;
  if (color === 'red') {
    backgroundColor = themeColors.semantic.danger;
  } else if (color === 'green') {
    backgroundColor = themeColors.semantic.success;
  } else if (color === 'blue') {
    backgroundColor = themeColors.semantic.info;
  } else if (color === 'orange') {
    backgroundColor = themeColors.accents.orange;
  } else {
    backgroundColor = themeColors.background.gray;
  }

  if (disabled) {
    return (
      <DisabledButton
        bgcolor={backgroundColor}
        style={style}
        disabled
        size={size}
        type={type}
        className={className}
        shape={shape}
        icon={icon}
        htmlType={htmlType}
        form={form}
      >
        {children}
      </DisabledButton>
    );
  }

  return (
    <StyledButton
      type={type}
      bgcolor={backgroundColor}
      style={style}
      shadow={shadow}
      onClick={onClick}
      href={href}
      target={target}
      size={size}
      className={className}
      shape={shape}
      icon={icon}
      htmlType={htmlType}
      form={form}
      rel={rel || 'noopener noreferrer'}
    >
      {children}
    </StyledButton>
  );
};

export default Button;

import JobAppColumn from '../../types/JobAppColumn';
import { patchForJson } from '../xhr';

export default function xhrUpdateJobAppColumn(
  boardId: string,
  columnId: string,
  data: Partial<JobAppColumn>,
  accessToken: string,
) {
  return patchForJson(`/jobappboards/${boardId}/columns/${columnId}`, data, accessToken);
}

import Contact from '../../types/Contact';
import { patchForJson } from '../xhr';

export default function xhrUpdateContact(
  contactId: string,
  updates: Partial<Contact>,
  accessToken: string,
) {
  return patchForJson(
    `/contacts/${contactId}`,
    updates,
    accessToken,
  );
}

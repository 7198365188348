type AuthZeroTypes = {
  audience: string,
  scope: {
    [index: string]: string
  }
};

const AuthZero: AuthZeroTypes = {
  audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
  scope: {
    read: 'read:current_user',
    update: 'update:current_user_metadata',
  },
};

export default AuthZero;

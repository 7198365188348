import React, { useState, useContext } from 'react';
import { Dropdown, Button } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { lighten } from 'polished';
import {ConfirmModalContext} from '../../contexts/ConfirmModalProvider';
import themeColors from '../../constants/themeColors';
import notifyError from '../../util/notifyError';
import JobApp from '../../types/JobApp';
import JobAppColumn from '../../types/JobAppColumn';
import { JobAppContext } from '../../contexts/JobAppProvider';
import { JobAppColumnContext } from '../../contexts/JobAppColumnProvider';
import { ItemType } from 'antd/lib/menu/hooks/useItems';

const DropdownButtonWrapper = styled.div`
  > button {
    transform: rotate(90deg);
    border-style: none;
    box-shadow: none;
    background: none !important;
    color: ${themeColors.background.dark};
    padding: 5px !important;
    &:hover, &:focus, &:active, &:focus-within {
      color: ${lighten(0.3, themeColors.background.dark)};
    }
    .anticon {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.5rem;
    }
  }
`;

type ColumnSettingsOption = 'rename'|'sort'|'delete'

interface ColumnSettingsDropdownProps {
  column: JobAppColumn
  jobApps: JobApp[]
  onClickEdit?: () => void
  excludeOptions?: ColumnSettingsOption[]
}

const ColumnSettingsDropdown: React.FC<ColumnSettingsDropdownProps> = ({
  column,
  jobApps,
  onClickEdit,
  excludeOptions = []
}) => {
  const [visible, setVisible] = useState(false);
  const confirmModalContext = useContext(ConfirmModalContext);
  const { sortJobAppColumn } = useContext(JobAppContext);
  const { deleteJobAppColumn } = useContext(JobAppColumnContext);

  async function deleteColumn() {
    setVisible(false);
    confirmModalContext.showConfirmModal({
      title: `Are you sure you want to delete this column (${column.name})?`,
      content: jobApps.length > 0 ? `This will delete ${jobApps.length} ${jobApps.length > 1 ? 'jobs' : 'job'} in this list.` : '',
      okButton: <Button danger type="primary">Delete</Button>,
      onOk: async () => {
        try {
          await deleteJobAppColumn(column.boardId, column.id);
        } catch (err) {
          notifyError('Unable to delete column', (err as Error).message);
        }
      },
    });
  }

  function openEditModal() {
    setVisible(false);
    if (onClickEdit) {
      onClickEdit();
    }
  }

  async function onClickSort(e: React.MouseEvent, by: 'title' | 'company' | 'appliedAt', descending = false) {
    setVisible(true);
    e.stopPropagation();
    try {
      await sortJobAppColumn(column.boardId, column.id, {
        by,
        descending,
      });
    } catch (err) {
      notifyError('Unable to sort', (err as Error).message);
    }
  }


  const dropdownMenuItems: ItemType[] = []

  if (!excludeOptions.includes('rename')) {
    dropdownMenuItems.push({
      key: 'rename',
      onClick: () => openEditModal(),
      label: 'Rename List'
    })
  }

  if (!excludeOptions.includes('sort')) {
    dropdownMenuItems.push({
      key: 'sort',
      label: 'Sort by',
      children: [
        {
          key: 'company',
          onClick: (e) => onClickSort(e.domEvent as React.MouseEvent, 'company'),
          label: 'Company Name',
        },
        {
          key: 'title',
          onClick: (e) => onClickSort(e.domEvent as React.MouseEvent, 'title'),
          label: 'Position Name',
        },
        {
          key: 'appliedAt',
          onClick: (e) => onClickSort(e.domEvent as React.MouseEvent, 'appliedAt'),
          label: 'Oldest Added',
        },
        {
          key: 'appliedAtDesc',
          onClick: (e) => onClickSort(e.domEvent as React.MouseEvent, 'appliedAt', true),
          label: 'Newest Added',
        },
      ]
    })
  }

  if (!excludeOptions.includes('delete')) {
    dropdownMenuItems.push({
      key: 'delete',
      onClick: () => deleteColumn(),
      label: 'Delete List',
      danger: true,
    })
  }


  return (
    <Dropdown
      trigger={['click']}
      open={visible}
      menu={{
        items: dropdownMenuItems,
      }}
      onOpenChange={setVisible}
    >
      <DropdownButtonWrapper>
        <Button
          size="large"
        >
          <EllipsisOutlined />
        </Button>
      </DropdownButtonWrapper>
    </Dropdown>
  );
};

export default ColumnSettingsDropdown;

import React, {
  useCallback,
  useContext,
} from 'react';
import xhrImportJobApps from '../adapters/jobAppImport/xhrImportJobApps';
import BulkJobApp from '../types/BulkJobApp';
import JobApp from '../types/JobApp';
import JobAppBoard from '../types/JobAppBoard';
import JobAppColumn from '../types/JobAppColumn';
import { JobAppBoardContext } from './JobAppBoardProvider';
import { UserContext } from './UserProvider';

type ImportResponse = {
  board: JobAppBoard,
  columns: JobAppColumn,
  jobApps: JobApp[]
};

type JobAppImportContextData = {
  importJobApps: (boardName: string, jobApps: BulkJobApp[]) => Promise<ImportResponse>
};

const defaultFunction = () => {
  throw new Error('Provider is missing');
};

export const JobAppImportContext = React.createContext<JobAppImportContextData>({
  importJobApps: defaultFunction,
});

export const JobAppImportProvider: React.FC = ({ children }) => {
  const { getAccessToken } = useContext(UserContext);
  const { boards, setBoards } = useContext(JobAppBoardContext);

  const importJobApps: JobAppImportContextData['importJobApps'] = useCallback(async (boardName, jobApps) => {
    const accessToken = await getAccessToken();
    const res: ImportResponse = await xhrImportJobApps({
      board: boardName,
      jobApps,
    }, accessToken);
    setBoards(!boards ? null : [
      ...boards,
      res.board,
    ]);
    return res;
  }, [getAccessToken, boards, setBoards]);

  return (
    <JobAppImportContext.Provider value={{
      importJobApps,
    }}
    >
      {children}
    </JobAppImportContext.Provider>
  );
};

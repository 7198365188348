import styled from "styled-components";
import { HEADER_HEIGHT } from "../../../../constants/headerHeight";
import themeColors from "../../../../constants/themeColors";

export const PageConstrainedWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
`

export const PageConstrainedCentered = styled.div<{
  size?: "small"|"md"
}>`
  display: flex;
  flex-direction: column;
  max-width: ${props => props.size === 'md' ? '1400px' : '760px'};
  width: 100%;
  /** 45px for the top header, 30px for margin under heading */
  height: calc(100vh - 60px - "${HEADER_HEIGHT}px");
`

export const PageConstrainedTopContent = styled.div`
  margin: 0 30px;
`;

export const Footer = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: auto;
  padding: 1rem 0;
  overflow: auto;
  a {
    margin-left: 1rem;
    margin-right: 1rem;
    flex: wrap;
    color: ${themeColors.background.dark};
  }
`;
import { stringify } from "qs";
import { getJson } from "../../../adapters/xhr";
import { OrganizationGoal } from "../types/organization-goal.type";

export interface GetOrganizationGoalsInput {
  offset: number
  limit: number
  filters: {
    type: 'current' | 'past'
    organizationId: string
  };
}

export interface GetOrganizationGoalsOutput {
  results: Array<OrganizationGoal>
}

export const getOrganizationGoals = (options: GetOrganizationGoalsInput, accessToken: string): Promise<GetOrganizationGoalsOutput> => {
  const filtersQuery = stringify(options)
  return getJson(
    `/user-checklist-tasks?${filtersQuery}`,
    accessToken
  )
}

import BulkJobApp from '../../types/BulkJobApp';
import { postForJson } from '../xhr';

export default function xhrImportJobApps(
  details: {
    board: string,
    jobApps: BulkJobApp[]
  },
  accessToken: string,
) {
  return postForJson(
    '/import',
    details,
    accessToken,
  );
}

import styled from "styled-components";
import themeColors from "../../../../constants/themeColors";

export const InterviewHeadingEditableInput = styled.input`
  border-style: none;
  background: none;
  width: 100%;
  outline: none;
  border-radius: 3px;
  padding-left: 0;
  font-weight: 600;
  font-size: 1.65rem;
  &:focus-visible {
    outline: none;
  }
  color: ${themeColors.background.dark};
  &:focus {
    padding-left: 2px;
    outline: solid 3px ${themeColors.brand.primaryLight};
  }
`

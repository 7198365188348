import React, { useContext, useState } from 'react';
import {
  Alert,
  Button,
  Form,
  Input,
  Space,
} from 'antd';
import { Store } from 'antd/lib/form/interface';
import { Helmet } from 'react-helmet';
import JobApp from '../../types/JobApp';
import ThemedModal from '../ThemedModal';
import notifyError from '../../util/notifyError';
import AutoCompleteCompanyInput from './AutoCompleteCompanyInput';
import JobAppColumn from '../../types/JobAppColumn';
import { JobAppContext } from '../../contexts/JobAppProvider';
import { JobAppBoardContext } from '../../contexts/JobAppBoardProvider';

interface AddJobAppFormProps {
  column: JobAppColumn,
  visible: boolean,
  onClose: () => void
}

const AddJobAppModal: React.FC<AddJobAppFormProps> = ({
  column,
  visible,
  onClose,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { createJobApp, setEditingJobAppId } = useContext(JobAppContext);
  const { boards, setBoards } = useContext(JobAppBoardContext)

  // Logo URL is not handled by forms since it's via auto-complete, and is a "hidden field"
  const [companyLogoUrl, setCompanyLogoUrl] = useState<string|undefined>();

  function clear() {
    form.resetFields();
  }

  function resetAndClose() {
    clear();
    onClose();
  }

  async function onOk(data: Store) {
    setLoading(true);
    const {
      company, title, description, url,
    } = data;
    const toPost = {} as JobApp;
    if (company) {
      toPost.company = company;
      if (companyLogoUrl) {
        toPost.companyLogoUrl = companyLogoUrl;
      }
    }
    if (title) {
      toPost.title = title;
    }
    if (description) {
      toPost.description = description;
    }
    if (url) {
      toPost.url = url;
    }

    try {
      const createdJobApp = await createJobApp(column.boardId, column.id, toPost);
      setBoards(boards?.map((board) => ({
        ...board,
        jobCount: column.boardId === board.id ? board.jobCount + 1 : board.jobCount,
      })) || boards)
      resetAndClose();
      setEditingJobAppId(createdJobApp.id);
    } catch (err) {
      notifyError('Unable to add job app', (err as Error).message);
    }

    setLoading(false);
  }

  const formName = 'add-job-app';

  return (
    <ThemedModal
      title={`Add a new job to ${column?.name}`}
      open={visible}
      onCancel={resetAndClose}
      footer={(
        <Space>
          <Button onClick={resetAndClose}>
            Close
          </Button>
          <Button
            disabled={loading}
            type="primary"
            htmlType="submit"
            form={formName}
          >
            Add Job
          </Button>
        </Space>
      )}
    >
      <Helmet>
        <title>{`Add a new ${column ? `${column.name} ` : ' '}job application`}</title>
      </Helmet>
      {!localStorage.getItem('add-job-extension-alert-seen') && <Alert 
        afterClose={() => localStorage.setItem('add-job-extension-alert-seen', '1')}
        message={<a
          href="https://chrome.google.com/webstore/detail/stipplo-job-application-t/eelojdbiagbabehiijgghfmdgnggdfna"
          target="_blank"
          rel="noreferrer"
          style={{
            fontWeight: 500
          }}
        >
          Download Stipplo's Chrome Extension
        </a>}
        type="info"
        description="Add jobs with just a few clicks on various job listing sites!" 
        style={{marginBottom: '1rem', marginTop: '1rem'}}
      />}
      <Form
        name={formName}
        onFinish={onOk}
        layout="vertical"
        form={form}
      >
        <Form.Item
          label="Company"
          name="company"
          rules={[{
            required: true,
            message: 'Company name is required',
          }, {
            type: 'string',
            max: 200,
            message: 'Company name is too long',
          }]}
        >
          <AutoCompleteCompanyInput onChange={(option) => {
            // Company logo is uncontrolled by form, so we manually track it
            setCompanyLogoUrl(option.logoUrl);
            // Let the form handle the "company" field as Form.Item
            form.setFieldsValue({
              company: option.value,
            });
          }}
          />
        </Form.Item>
        <Form.Item
          label="Position/Job Title"
          name="title"
          rules={[{
            type: 'string',
            max: 200,
            message: 'Position name is too long',
          }]}
        >
          <Input
            placeholder="Your job title"
          />
        </Form.Item>
      </Form>
    </ThemedModal>
  );
};

export default AddJobAppModal;

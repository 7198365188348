import { useContext, useRef, useState } from "react";
import { JobAppInterviewContext } from "../../../../contexts/JobAppInterviewProvider";
import JobAppInterview from "../../../../types/JobAppInterview";
import notifyError from "../../../../util/notifyError";
import { InterviewHeadingEditableInput } from "./InterviewHeadingEditable.styles";

interface Props {
  interview: JobAppInterview;
}

const InterviewHeadingEditable: React.FC<Props> = ({
  interview,
}) => {
  const {
    updateJobAppInterview
  } = useContext(JobAppInterviewContext);
  const [showTitle, setShowTitle] = useState(interview.title)
  const inputRef = useRef<HTMLInputElement>(null);

  const saveChanges = async () => {
    try {
      await updateJobAppInterview(interview.id, {
        title: showTitle
      })
      inputRef.current?.blur()
    } catch (err) {
      notifyError(`Failed to update interview`, (err as Error).message)
      setShowTitle(interview.title)
    }
  }

  const onBlur = () => {
    if (!showTitle) {
      setShowTitle(interview.title)
      return;
    }
    saveChanges()
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowTitle(e.target.value)
  }

  return <InterviewHeadingEditableInput
    ref={inputRef}
    value={showTitle} 
    onBlur={onBlur} 
    onKeyPress={e => {
      return e.key === 'Enter' ? onBlur() : null
    }}
    onChange={onChange}
  />
}

export default InterviewHeadingEditable;

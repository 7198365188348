import React, { useContext, useState } from 'react';
import { Input } from 'antd';
import notifyError from '../../../util/notifyError';
import JobApp from '../../../types/JobApp';
import { JobAppContext } from '../../../contexts/JobAppProvider';

interface JobAppInputPropTypes {
  property: 'salary'|'url';
  jobApp: JobApp;
}

// eslint-disable-next-line react/prop-types
const JobAppInput: React.FC<JobAppInputPropTypes> = ({ property, jobApp }) => {
  const [newValue, setNewValue] = useState<string | undefined>(undefined);
  const { updateJobApp } = useContext(JobAppContext);

  async function edit(value: string) {
    try {
      const toPost = {
        [property]: value,
      };
      await updateJobApp(jobApp.id, toPost);
      setNewValue(undefined);
    } catch (err) {
      notifyError('Unable to edit job app', (err as Error).message);
    }
  }

  return (
    <Input
      value={newValue ?? (jobApp[property] || undefined)}
      onChange={(e) => setNewValue(e.target.value)}
      onBlur={() => (newValue != null ? edit(newValue) : null)}
    />
  );
};

export default JobAppInput;

import styled from 'styled-components';
import { ColumnHeaderStyles } from '../Column/Column.styles'

export const ColumnOrganizationHeaderStyles = styled(ColumnHeaderStyles)`
> div:first-child {
  > * {
    margin-left: 0;
  }
}
`;

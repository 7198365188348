import { useContext, useState } from "react";
import { JobAppContext } from "../../../../../contexts/JobAppProvider";
import { Breadcrumb, Select } from 'antd'
import { debounce } from "lodash";
import JobAppSearchResult from "../../../../../types/JobAppSearchResult";
import notifyError from "../../../../../util/notifyError";
import themeColors from "../../../../../constants/themeColors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import JobApp from "../../../../../types/JobApp";

interface Props {
  onJobAppSelected: (jobApp: JobApp) => void
}

const SearchJobAppsInputSelect: React.FC<Props> = ({
  onJobAppSelected
}) => {
  const {
    searchJobApps,
  } = useContext(JobAppContext);
  const [searching, setSearching] = useState(false);
  const [matchedJobApps, setMatchedJobApps] = useState<JobAppSearchResult[]>([]);
  const [searchValue, setSearchValue] = useState("");

  const onSearch = async (value: string) => {
    setSearchValue(value)
    setMatchedJobApps([])
    if (!value) {
      return
    }
    setSearching(true);
    try {
      const foundJobApps = await searchJobApps(value);
      setMatchedJobApps(foundJobApps);
      // console.log('setting ', foundJobApps)
    } catch (err) {
      notifyError(`Failed to search for job applications`, (err as Error).message)
    } finally {
      setSearching(false);
    }
  }

  const onChange = (jobAppId: string) => {
    const jobApp = matchedJobApps.find((app) => app.id === jobAppId);
    if (!jobApp) {
      return;
    }
    onJobAppSelected(jobApp);
  }

  return <Select
    suffixIcon={<FontAwesomeIcon icon={faSearch} />}
    style={{width: '100%'}}
    showSearch
    onSearch={debounce(onSearch, 300)}
    onChange={onChange}
    loading={searching}
    placeholder="Search for a job application"
    allowClear
    onClear={() => onSearch("")}
    notFoundContent={searching
      ? 'Searching...'
      : searchValue
        ? 'No job applications found. Try a search for a different company or position.'
        : 'Search for a job application name or position'}
    filterOption={(input, option) =>
      option?.key?.toString?.()?.toLowerCase?.()?.includes(input.toLowerCase()) || false
    }
  >
    {matchedJobApps.map(jobApp => (
      <Select.Option key={` ${jobApp.company} ${jobApp.title} ${jobApp.id}`} value={jobApp.id}>
        <Breadcrumb separator=">" style={{
          display: 'inline'
        }}>
          <Breadcrumb.Item>
            <span style={{
              color: themeColors.background.gray
            }}>
              {jobApp.column.board.name}
            </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span style={{
              color: themeColors.background.gray
            }}>
              {jobApp.column.name}
            </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span style={{
              color: themeColors.background.dark
            }}>
              {jobApp.company}{jobApp.title && ` (${jobApp.title})`}
            </span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Select.Option>
    ))}
  </Select>
}

export default SearchJobAppsInputSelect;

import React, { useCallback, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import AuthZero from '../constants/AuthZero';
import {
  patch, GENERIC_NETWORK_ERROR, getJson,
} from '../adapters/xhr';
import xhrGetUserInformation from '../adapters/users/xhrGetUserInformation'
import User from '../types/User';
import UserProfile from '../types/UserProfile';
import generateFullName from '../util/generateFullName';
import xhrUpdateUser from '../adapters/users/xhrUpdateUser';


type UserContextData = {
  user: User|null,
  userProfile: UserProfile|null,
  userProfileError: string|null,
  isSocialSignin: boolean,
  getAccessToken: (scope?: string) => Promise<string>;
  updateDisplayName: (data: {
    firstName: string,
    lastName: string
  }) => Promise<void>
  updatePassword: (newPassword: string) => Promise<void>
  createCustomerPortalSession: () => Promise<string>
  createCheckoutSession: (priceId: string) => Promise<string>
  logout: () => void,
};

export const UserContext = React.createContext<UserContextData>({
  user: null,
  userProfile: null,
  userProfileError: null,
  getAccessToken: async () => '',
  isSocialSignin: false,
  updateDisplayName: async () => {},
  updatePassword: async () => {},
  createCustomerPortalSession: async () => '',
  createCheckoutSession: async () => '',
  logout: () => {},
});

export const UserProvider: React.FC = ({ children }) => {
  const { getAccessTokenSilently, user, logout: auth0Logout } = useAuth0();
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [userProfileError, setUserProfileError] = useState<string | null>(null);

  const getAccessToken = useCallback((scope: string = 'read') => getAccessTokenSilently({
    audience: AuthZero.audience,
    scope: AuthZero.scope[scope],
  }), [getAccessTokenSilently]);

  useEffect(() => {
    async function findUserProfileCreateIfNotExist(){
      try {
        const accessToken = await getAccessToken();
        const res = await xhrGetUserInformation(accessToken);
        setUserProfile({
          ...res,
          fullName: generateFullName(res.firstName, res.lastName)
        });
      } catch (err){
        setUserProfileError((err as Error).message);
      }
    }
    if(user){
      findUserProfileCreateIfNotExist();
    }
  }, [user, getAccessToken]);

  const updateDisplayName: UserContextData['updateDisplayName'] = useCallback(async ({
    firstName,
    lastName
  }) => {
    const token = await getAccessToken();
    const newProfile = await xhrUpdateUser({
      firstName,
      lastName
    }, token)

    setUserProfile({
      ...newProfile,
      fullName: generateFullName(newProfile.firstName, newProfile.lastName)
    })
  
  }, [getAccessToken]);

  const updatePassword = useCallback(async (password: string) => {
    const token = await getAccessToken();
    const res = await patch('/users/@me', {
      password,
    }, token);
    if (res.status === 400) {
      const body = await res.json();
      const errors = body.errors.join('\n');
      throw new Error(errors);
    } else if (!res.ok) {
      throw new Error(GENERIC_NETWORK_ERROR);
    }
  }, [getAccessToken]);

  const createCustomerPortalSession = useCallback(async () => {
    const accessToken = await getAccessToken();
    const { url } = await getJson('/payments/portal-session', accessToken);
    return url;
  }, [getAccessToken]);

  const createCheckoutSession = useCallback(async (priceId: string) => {
    const token = await getAccessToken();
    const { sessionId } = await getJson(`/payments/checkout-session?priceId=${priceId}`, token);
    return sessionId;
  }, [getAccessToken]);

  const logout = useCallback(() => {
    auth0Logout({ returnTo: window.location.origin });
  }, [auth0Logout]);

  return (
    <UserContext.Provider value={{
      user: user as User || null,
      userProfile,
      userProfileError,
      getAccessToken,
      isSocialSignin: !user?.sub?.startsWith('auth0'),
      updateDisplayName,
      updatePassword,
      createCustomerPortalSession,
      createCheckoutSession,
      logout,
    }}
    >
      {children}
    </UserContext.Provider>
  );
};

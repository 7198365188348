import { navigate } from '@reach/router';
import {Typography, Select, List} from 'antd';
import { useContext, useEffect, useState } from 'react';
import SelectOrgColumnDropdown from '../components/JobAppBoard/BoardCopyToOrg/SelectOrgColumnDropdown/SelectOrgColumnDropdown';
import PageTitle from '../components/JobAppBoard/common/PageTitle/PageTitle';
import Button from '../components/theme/Button/Button';
import pages from '../constants/pages';
import themeColors from '../constants/themeColors';
import { AppOrganizationViewContext } from '../contexts/AppOrganizationViewProvider';
import { JobAppBoardContext } from '../contexts/JobAppBoardProvider';
import { JobAppColumnContext } from '../contexts/JobAppColumnProvider';
import CopyBoardToOrgBody from '../types/CopyBoardToOrgBody';
import JobAppColumn from '../types/JobAppColumn';
import OrganizationColumnTemplate from '../types/OrganizationColumnTemplate';
import notifyError from '../util/notifyError';
import notifySuccess from '../util/notifySuccess';


const BoardCopyToOrg: React.FC = () => {
  const {
    selectedOrganization,
    getCopyBoardToOrgDetails
  } = useContext(AppOrganizationViewContext)
  const {
    boards,
    copyToOrganization
  } = useContext(JobAppBoardContext);
  const {
    getJobAppColumns,
  } = useContext(JobAppColumnContext)
  const [selectedBoardId, setSelectedBoardId] = useState<string>('');
  const [columns, setColumns] = useState<JobAppColumn[]|null>(null);
  const [columnTemplates, setColumnTemplates] = useState<OrganizationColumnTemplate[]|null>(null)
  const personalBoards = boards?.filter((board) => !board.organizationId) || null; 
  const [columnMapToOrgColumn, setColumnMapToOrgColumn] = useState<Map<string, string>>(new Map());
  const [saving, setSaving] = useState(false)
  const allColumnsAreMapped = columns?.length && columns.every((col) => columnMapToOrgColumn.has(col.id))

  useEffect(() => {
    if (!selectedBoardId || !selectedOrganization) {
      setColumns([])
      setColumnTemplates([])
    } else {
      setColumns(null)
      getJobAppColumns(selectedBoardId)
        .then((cols) => setColumns(cols))
        .catch(err => {
          console.error(err)
        })
      getCopyBoardToOrgDetails(selectedOrganization.id)
        .then(({ columnTemplates }) => setColumnTemplates(columnTemplates))
        .catch(err => {
          console.error(err)
        })
    }
  }, [selectedBoardId, getJobAppColumns, selectedOrganization, getCopyBoardToOrgDetails])

  const onColumnMapped = (col: JobAppColumn, orgColumnId: string) => {
    const columnMapCopy = new Map(columnMapToOrgColumn)
    columnMapCopy.set(col.id, orgColumnId)
    setColumnMapToOrgColumn(columnMapCopy)
  }

  const onConfirm = async () => {
    if (!allColumnsAreMapped || !selectedOrganization) {
      return;
    }
    const selectedBoard = boards?.find((b) => b.id === selectedBoardId)
    if (!selectedBoard) {
      return;
    }
    setSaving(true)
    const columnMapping: CopyBoardToOrgBody['columnMapping'] = []
    columnMapToOrgColumn.forEach((organizationColumnTemplateId, personalColumnId) => {
      columnMapping.push({
        organizationColumnTemplateId,
        personalColumnId,
      })
    })
    try {
      const createdBoard = await copyToOrganization(selectedBoard, {
        organizationId: selectedOrganization.id,
        columnMapping,
      })
      notifySuccess(`Successfully copied board to ${selectedOrganization.name}`)
      navigate(`${pages.BOARD}/${createdBoard.id}`)
    } catch (err) {
      notifyError('Failed to copy board', (err as Error).message)
    } finally {
      setSaving(false)
    }
  }

  return <div>
    <PageTitle>Copy Personal Board to {selectedOrganization?.name}</PageTitle>
    <Typography.Paragraph>
      Select the personal board you'd like to copy, and then select which organization columns
      will apply to which personal columns of that board.
    </Typography.Paragraph>
    <Typography.Title level={5} style={{
      marginTop: '4rem',
      color: themeColors.background.dark
    }}>
      Select your personal board to copy from
    </Typography.Title>
    <Select
      defaultActiveFirstOption
      style={{width: '100%', marginBottom: "3rem"}} 
      loading={personalBoards === null}
      onChange={(val: string) => setSelectedBoardId(val)}
    >
      {personalBoards?.map((board) => <Select.Option 
        key={board.id}
        value={board.id}
      >
        {board.name}
      </Select.Option>)}
    </Select>
    <Typography.Title 
      style={{
        color: themeColors.background.dark
      }}
      level={5}
    >
        Select the columns where your applications will be copied to
    </Typography.Title>
    <Typography.Paragraph>
      All your applications in each respective column will be moved to the new column you specify.
    </Typography.Paragraph>
    <div style={{
      display: !selectedBoardId ? 'none' : 'flex', 
      justifyContent: 'space-between',
      marginTop: '2rem'
    }}>
      <Typography.Title level={5} style={{
        margin: 0,
        color: themeColors.background.dark,
        fontWeight: 500,
      }}>
        Personal Board Columns
      </Typography.Title>
      <Typography.Title level={5} style={{
        margin: 0,
        color: themeColors.background.dark,
        fontWeight: 500,
      }}>
        {selectedOrganization?.name} Columns
      </Typography.Title>
    </div>
    <List
      loading={columns === null}
      dataSource={columns || []}
      renderItem={(col: JobAppColumn) => <List.Item style={{
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        {col.name}
        <SelectOrgColumnDropdown
          columnTemplates={columnTemplates || []}
          loading={columnTemplates === null}
          onSelect={orgColId => onColumnMapped(col, orgColId)}
        />
      </List.Item>}
      locale={{
        emptyText: <span>Waiting for a board to be selected...</span>,
      }}
    >
    </List>
    <div style={{
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '2rem',
      marginBottom: '2rem',
    }}>
      <Button 
        size="large" 
        style={{
          marginRight: "0.5rem"
        }}
        onClick={() => navigate(pages.BOARD)}
      >
          Cancel
      </Button>
      <Button 
        disabled={!allColumnsAreMapped || saving}
        onClick={onConfirm}
        color="blue"
        shadow="colored"
        size="large"
      >
        Confirm
      </Button>
    </div>
  </div>
}

export default BoardCopyToOrg

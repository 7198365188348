import React from 'react';
import themeColors from '../../constants/themeColors';

const WarningText: React.FC = ({ children }) => (
  <span style={{
    color: themeColors.semantic.warning,
  }}
  >
    {children}
  </span>
);

export default WarningText;

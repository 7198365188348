/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import colors from '../constants/colors';
import themeColors from '../constants/themeColors';

interface StyledInputProps {
  style?: React.CSSProperties;
  inverted?: boolean;
}

const invertedColor = themeColors.brand.primaryLight

const StyledInput = styled.input<StyledInputProps>`
  font-size: ${({ style }) => style?.fontSize || '1em'};
  background: transparent;
  /* border-style: none; */
  outline: none;
  width: 100%;
  padding: 0 2px;
  border: solid 3px transparent;
  &:focus {
    border-top: solid 3px transparent;
    border-right: solid 3px transparent;
    border-left: solid 3px transparent;
    border-bottom: solid 3px ${({ inverted }) => (inverted ? 'white' : invertedColor)};
    background: transparent;
  }
  &::selection {
    background: ${({ inverted }) => (inverted ? themeColors.background.offWhite : colors.primary)};
    color: ${({ inverted }) => (inverted ? themeColors.background.dark : 'white')};
  }
`;

const FocusableDiv = styled.div<StyledInputProps>`
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${({ style }) => style?.fontSize || '1em'};
  cursor: pointer;
  border: solid 3px transparent;
  padding: 0 2px;
  &:focus {
    border: solid 3px ${({ inverted }) => (inverted ? 'white' : invertedColor)};
  }
`;

interface EditableInputProps {
  value: string;
  onChange: (change: string) => Promise<void>|void;
  fontSize?: string;
  fontWeight?: 'normal' | 'bold' | 'bolder' | 'lighter' | number;
  textLabel: string;
  inputLabel: string;
  fallbackText?: string;
  inputProps?: React.HTMLAttributes<HTMLInputElement>;
  textProps?: React.HTMLAttributes<HTMLDivElement>;
  characterLimit?: number;
  inverted?: boolean;
  requiredValue?: boolean;
}

const EditableInput: React.FC<EditableInputProps> = ({
  value,
  onChange,
  fontSize = '1em',
  fontWeight = 'normal',
  textLabel,
  inputLabel,
  fallbackText,
  characterLimit,
  inputProps = {},
  textProps = {},
  inverted,
  requiredValue,
}) => {
  const [editing, setEditing] = useState(false);
  const [newValue, setNewValue] = useState<string|undefined>();
  const inputElement = useRef<HTMLInputElement>(null);
  const divElement = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (inputElement.current && editing) {
      inputElement.current.focus();
      inputElement.current.select();
    }
  }, [inputElement, editing]);

  function reset() {
    setEditing(false);
    setNewValue(undefined);
  }

  function onChangeInput(e: React.ChangeEvent<HTMLInputElement>) {
    if (!characterLimit) {
      setNewValue(e.target.value);
    } else if (e.target.value.length <= characterLimit) {
      setNewValue(e.target.value);
    }
  }

  function onClickDiv() {
    setEditing(true);
  }

  function onKeyDownDiv(e: React.KeyboardEvent<HTMLDivElement>) {
    if (e.key !== 'Enter') {
      return;
    }
    setEditing(true);
  }

  async function saveInputValue() {
    if (requiredValue && !newValue) {
      reset();
      return;
    }
    if (newValue !== undefined && newValue !== value) {
      await onChange(newValue);
    }
    reset();
  }

  function onKeyDownInput(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      saveInputValue();
    } if (e.key === 'Escape') {
      reset();
    }
  }

  function onBlurInput() {
    saveInputValue();
  }

  if (editing) {
    return (
      <StyledInput
        aria-label={inputLabel}
        value={newValue === undefined ? value : newValue}
        onChange={onChangeInput}
        ref={inputElement}
        onKeyDown={onKeyDownInput}
        onBlur={onBlurInput}
        inverted={inverted}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...inputProps}
        style={{
          fontWeight,
          fontSize,
          ...inputProps?.style,
        }}
      />
    );
  }

  return (
    <FocusableDiv
      ref={divElement}
      title={textLabel}
      onClick={onClickDiv}
      onKeyDown={onKeyDownDiv}
      role="button"
      tabIndex={0}
      inverted={inverted}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...textProps}
      style={{
        fontWeight,
        fontSize,
        ...textProps?.style,
      }}
    >
      <span>{value || fallbackText}</span>
    </FocusableDiv>
  );
};

export default EditableInput;

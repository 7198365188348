import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import styled from 'styled-components';
import themeColors from '../constants/themeColors';
import Button from './theme/Button/Button';
import StipploLogoWithText from '../../images/logo-with-text-horizontal.svg';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
  padding: 0 10px 10px 10px;
`;

const Company = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  span {
    font-size: 2em;
    font-weight: bold;
  }
`;

const Content = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  flex: 1;
  margin-top: 150px;
  max-width: 670px;
  h1 {
    color: ${themeColors.background.dark};
    font-weight: bold;
    font-size: 1.35rem;
    margin: 0;
  }
  p {
    color: ${themeColors.background.dark};
    font-size: 1.25rem;
  }
`;

function GenericError({ title, description }: InferProps<typeof GenericError.propTypes>) {
  return (
    <Wrapper>
      <Company as="a" href="/">
        <img src={StipploLogoWithText} alt="Stipplo Logo" style={{ height: '45px' }} />
      </Company>
      <Content style={{
        minWidth: '670px',
      }}
      >
        <h1 style={{ color: themeColors.semantic.danger }}>
          {title}

        </h1>
        <p>{description}</p>
        <br />
        <Button
          color="blue"
          size="large"
          type="link"
          href="/"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          Go to Home Page
        </Button>
      </Content>

    </Wrapper>
  );
}

GenericError.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
};

export default GenericError;

import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'antd';
import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import themeColors from '../../../constants/themeColors';
import { JobAppContext } from '../../../contexts/JobAppProvider';
import JobApp from '../../../types/JobApp';
import notifyError from '../../../util/notifyError';
import LocationsAutocomplete from '../../LocationsAutocomplete';

const EditCompanyContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 0.5rem;
`;

const ClickToEditContainer = styled.div`
  margin-top: 0.5rem;
  cursor: pointer;
  &:hover {
    > span {
    color: ${themeColors.background.gray} !important;
    }
  }
`;

interface Props {
  jobApp: JobApp;
}

const JobAppEditLocation: React.FC<Props> = ({
  jobApp,
}) => {
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false)
  const [selectedValue, setSelectedValue] = useState<string|null>(null);
  const { updateJobApp } = useContext(JobAppContext);

  useEffect(() => {
    setSelectedValue(null);
  }, [editing]);

  const save = async () => {
    if (!jobApp) {
      return;
    }
    try {
      setSaving(true)
      const toUpdate = {
        locationPlaceId: selectedValue || '',
      };
      await updateJobApp(jobApp.id, toUpdate);
      setEditing(false);
      setSelectedValue(null)
    } catch (err) {
      notifyError('Unable to edit job app', (err as Error).message);
    } finally {
      setSaving(false)
    }
  };

  if (editing) {
    return (
      <EditCompanyContainer>
        <LocationsAutocomplete onChange={setSelectedValue} value={jobApp.location?.name} />
        <Button
          type="text"
          htmlType="submit"
          disabled={saving}
          icon={(
            <FontAwesomeIcon
              icon={faCheck}
              size="lg"
              style={{ color: themeColors.semantic.success }}
            />
          )}
          onClick={save}
        />
        <Button
          type="text"
          htmlType="button"
          disabled={saving}
          icon={(
            <FontAwesomeIcon
              icon={faTimes}
              size="lg"
            />
          )}
          onClick={() => setEditing(false)}
        />
      </EditCompanyContainer>
    );
  }

  return (
    <ClickToEditContainer
      style={{
        marginTop: '0.5rem'
      }}
      onKeyPress={(e) => (e.key === 'Enter' ? setEditing(true) : null)}
      onClick={() => setEditing(true)}
      role="button"
      tabIndex={0}
    >
      {jobApp.location?.name || <span style={{
        color: themeColors.background.mediumGray
      }}>No location specified</span>}
    </ClickToEditContainer>
  );
};

export default JobAppEditLocation;

import React, { useContext } from 'react'
import { AppOrganizationViewContext } from '../../../contexts/AppOrganizationViewProvider';
import MenuSideBarPersonalCard from "./MenuSideBarPersonalCard"
import MenuSideBarOrgDropdown from "./MenuSideBarOrgDropdown"
import { MenuSideBarViewSelectorRow } from './MenuSideBarViewSelector.styles';
import { Skeleton } from "antd";

const MenuSideBarViewSelector: React.FC = () => {
  const {
    organizations,
  } = useContext(AppOrganizationViewContext);

  return <div className="product-tour-org-dropdown-btn">{
    organizations === null
      ? <MenuSideBarViewSelectorRow>
        <Skeleton.Button block size="large" />
      </MenuSideBarViewSelectorRow>
      : organizations.length === 0
        ? <MenuSideBarViewSelectorRow><MenuSideBarPersonalCard /></MenuSideBarViewSelectorRow>
        : <MenuSideBarOrgDropdown />}
  </div>

}

export default MenuSideBarViewSelector

import { Button, Form, Input } from 'antd';
import { useContext, useEffect, useState } from 'react';
import ThemedModal from '../../../../components/ThemedModal'
import { UserContext } from '../../../../contexts/UserProvider';
import notifyError from '../../../../util/notifyError';

export const ForceNamePrompt = () => {
  const [form] = Form.useForm();
  const { userProfile, updateDisplayName } = useContext(UserContext);
  const [saving, setSaving] = useState(false);
  const isLoadingUserProfile = !userProfile

  const defaultFirstName = userProfile?.firstName || ''
  const defaultLastName = userProfile?.lastName || ''

  useEffect(() => {
    form.resetFields()
  }, [form, defaultFirstName, defaultLastName])

  if (!isLoadingUserProfile && userProfile?.firstName && userProfile?.lastName) {
    return null
  }

  const onDisplayNameSubmit = async ({ firstName, lastName }: { firstName: string, lastName: string }) => {
    try {
      setSaving(true)
      await updateDisplayName({
        firstName,
        lastName
      });
    } catch (err) {
      notifyError(`Failed to save`, err as Error)
    } finally {
      setSaving(false)
    }
  }

  return <ThemedModal
    title="Update your information to continue"
    open={!isLoadingUserProfile}
    closable={false}
    footer={<div style={{display: 'flex', justifyContent: 'flex-end'}}>
      <Button
        disabled={saving}
        type="primary"
        htmlType="submit"
        style={{ marginRight: '0.5rem' }}
        form="force-name-prompt"
      >
        Save
      </Button>
    </div>}
  >
    <Form
      id="force-name-prompt"
      form={form}
      layout="vertical"
      onFinish={(vals: any) => onDisplayNameSubmit(vals)}
      initialValues={{
        firstName: defaultFirstName,
      }}
    >
      <>
        <Form.Item
          label="First name"
          name="firstName"
          initialValue={defaultFirstName}
          rules={[{ required: true, message: 'Please input your first name' }]}
        >
          <Input placeholder={defaultFirstName} defaultValue={defaultFirstName} />
        </Form.Item>
        <Form.Item
          label="Last name"
          name="lastName"
          initialValue={defaultLastName}
          rules={[{ required: true, message: 'Please input your last name' }]}
        >
          <Input placeholder={defaultLastName} />
        </Form.Item>
      </>
    </Form>
  </ThemedModal>
}

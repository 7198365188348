import { useCallback, useContext, useMemo, useState } from "react"
import { LocationsContext } from "../contexts/LocationsProvider"
import LocationAutocomplete from "../types/LocationAutocomplete"
import * as Sentry from '@sentry/react';
import { AutoComplete } from "antd";
import { debounce } from 'lodash'

interface Props {
  style?: React.CSSProperties
  value?: string
  onChange: (locationPlaceId: string) => void
}

const LocationsAutocomplete: React.FC<Props> = ({
  style,
  value,
  onChange,
}) => {
  const {
    getLocationsAutocomplete
  } = useContext(LocationsContext)
  const [searchTerm, setSearchTerm] = useState<string|null>(null)
  const [searchResults, setSearchResults] = useState<LocationAutocomplete[]>([])
  
  const executeSearch = useCallback((searchTerm: string) => {
    getLocationsAutocomplete(searchTerm)
      .then(setSearchResults)
      .catch(err => {
        console.error(err)
        Sentry.captureException(err)
      })
  }, [getLocationsAutocomplete])

  const debouncedExecuteSearch = useMemo(() => debounce(executeSearch, 400), [executeSearch])

  const onSearchChanged = (searchTerm: string) => {
    setSearchTerm(searchTerm)
    debouncedExecuteSearch(searchTerm)
  }

  return <AutoComplete
    onSearch={onSearchChanged}
    value={searchTerm !== null ? searchTerm : value}
    placeholder="Search for a location"
    style={{
      width: '100%',
      ...style,
    }}
    getInputElement={() => <input />}
    onSelect={(placeDescription: string, optionData) => {
      const placeId = optionData.key as string;
      setSearchTerm(placeDescription)
      onChange(placeId)
    }}
  >
    {searchResults.map(location => (
      <AutoComplete.Option key={location.id} value={location.description}>
        {location.description}
      </AutoComplete.Option>
    ))}
  </AutoComplete>
}

export default LocationsAutocomplete

import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Input } from 'antd';
import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import themeColors from '../../../constants/themeColors';
import { JobAppContext } from '../../../contexts/JobAppProvider';
import JobApp from '../../../types/JobApp';
import notifyError from '../../../util/notifyError';

const EditContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 0.5rem;
`;

const ClickToEditContainer = styled.div`
  margin-top: 0.5rem;
  cursor: pointer;
  &:hover {
    > span {
    color: ${themeColors.background.gray} !important;
    }
  }
`;

interface Props {
  jobApp: JobApp;
}

const JobAppEditTitle: React.FC<Props> = ({
  jobApp,
}) => {
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false)
  const [newValue, setNewValue] = useState<string|null>(null);
  const { updateJobApp } = useContext(JobAppContext);

  useEffect(() => {
    setNewValue(null);
  }, [editing]);

  const save = async () => {
    if (!jobApp) {
      return;
    }

    setSaving(true)
    try {
      const toUpdate = {
        title: newValue,
      };
      await updateJobApp(jobApp.id, toUpdate);
      setEditing(false)
    } catch (err) {
      notifyError('Unable to edit job app', (err as Error).message);
    } finally {
      setSaving(false)
    }
  };

  if (editing) {
    return (
      <EditContainer>
        <Input 
          value={newValue || jobApp.title || ''}
          onChange={e => setNewValue(e.target.value)} 
          style={{
            marginRight: '0.5rem'
          }} 
          onKeyPress={e => {
            if (e.key === 'Enter') {
              save()
            }
          }}
        />
        <Button
          type="text"
          disabled={saving}
          htmlType="submit"
          icon={(
            <FontAwesomeIcon
              icon={faCheck}
              size="lg"
              style={{ color: themeColors.semantic.success }}
            />
          )}
          onClick={save}
        />
        <Button
          type="text"
          htmlType="button"
          disabled={saving}
          icon={(
            <FontAwesomeIcon
              icon={faTimes}
              size="lg"
            />
          )}
          onClick={() => setEditing(false)}
        />
      </EditContainer>
    );
  }

  return (
    <ClickToEditContainer
      onKeyPress={(e) => (e.key === 'Enter' ? setEditing(true) : null)}
      onClick={() => setEditing(true)}
      role="button"
      tabIndex={0}
    >
      {jobApp.title || <span style={{
        color: themeColors.background.mediumGray
      }}>No title specified</span>} 
    </ClickToEditContainer>
  );
};

export default JobAppEditTitle;

import { getJson } from "../../../adapters/xhr"
import { OrganizationInvite } from "../types"

export interface GetOrganizationInvitesResponse {
  results: OrganizationInvite[];
}

export const getOrganizationInvites = (accessToken: string): Promise<GetOrganizationInvitesResponse> => {
  return getJson(`/user-organization-invites`, accessToken)
}

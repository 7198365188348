import JobAppInterview from '../../types/JobAppInterview';
import { patchForJson } from '../xhr';

export default function xhrUpdateJobAppInterview(
  interviewId: string,
  details: Partial<JobAppInterview>,
  accessToken: string,
) {
  return patchForJson(
    `/interviews/${interviewId}`,
    details,
    accessToken,
  );
}

import styled from 'styled-components';
import themeColors from '../../constants/themeColors';
import StipploLogoWithTextImage from '../../../images/text-light.svg';
import MenuSideBarContents from './MenuSideBarContents';
import { ChromeFilled, CloseOutlined } from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import { Button, Typography } from 'antd';
import localStorageConstants from '../../constants/localStorage';
import { useState } from 'react';

const FULL_WIDTH = '275px';

const Sider = styled.div`
  position: relative;
  background: ${themeColors.background.dark};
  min-width: ${FULL_WIDTH};
  max-width: ${FULL_WIDTH};
  transition: width .2s;
  height: 100%;
  overflow: auto;
`;

const Logo = styled.div`
  background: url(${StipploLogoWithTextImage}) no-repeat;
  background-size: contain;
  max-width: 100%;
  height: 40px;
  margin: 10px 20px 0;
`;

const MenuItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const ExtensionAdContainer = styled.div`
  border-radius: 1rem;
  display: flex;
  /* justify-content: space-between; */
  padding: 0.5rem 0.5rem;
  margin: 1rem;
  background-color: ${themeColors.background.faintBlue};
  font-size: 12px;
  > div:first-child {
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
  }
`

function MenuSideBarDesktop() {
  const [extensionAdClosed, setExtensionAdClosed] = useState(localStorage.getItem(localStorageConstants.sidebarChromeExtensionAdClosed))

  const onExtensionAdClosed = () => {
    localStorage.setItem(localStorageConstants.sidebarChromeExtensionAdClosed, '1')
    setExtensionAdClosed('1')
  }

  return (
    <Sider>
      <MenuItemContainer>
        <div>
          <Logo />
          <MenuSideBarContents />
        </div>
        <ExtensionAdContainer style={{
          display: extensionAdClosed ? 'none' : 'flex'
        }}>
          <div>
            <ChromeFilled style={{
              fontSize: '2rem',
              color: themeColors.brand.primary
            }} />
          </div>
          <div>
            <Typography.Link
              style={{
                fontWeight: 600,
                display: 'block',
                fontSize: '14px'
              }}
              href="https://chrome.google.com/webstore/detail/stipplo-job-application-t/eelojdbiagbabehiijgghfmdgnggdfna"
              target="_blank"
              rel="noreferrer noopener"
            >
              Get Chrome Extension
            </Typography.Link>
            <Text>Save jobs from any site with just a few clicks!</Text>
          </div>
          <div>
            <Button 
              type='text'
              icon={<CloseOutlined />} 
              size="small" 
              onClick={onExtensionAdClosed}
            />
          </div>
        </ExtensionAdContainer>
      </MenuItemContainer>
    </Sider>
  );
}

export default MenuSideBarDesktop;

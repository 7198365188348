import { Avatar, Button, CalendarProps, Spin, Typography, Space } from "antd";
import { PageTitleHeading } from "../components/JobAppBoard/common/PageTitle/PageTitle.styles";
import JobApp from "../types/JobApp";
import JobAppInterview from "../types/JobAppInterview";
import AddInterviewButton from "../components/JobAppBoard/InterviewsComponents/AddInterviewButton/AddInterviewButton";
import { navigate } from "@reach/router";
import pages from "../constants/pages";
import { useInterviews } from "../features/interviews/hooks";
import dayjs from "dayjs";
import Calendar from "../components/Calendar";
import { useMemo, useState } from "react";
import JobAppInterviewWithJobApp from "../types/JobAppInterviewWithJobApp";
import styled from "styled-components";
import ThemedModal from "../components/ThemedModal";
import Text from "antd/lib/typography/Text";
import { RightOutlined } from "@ant-design/icons";
import { hexToRgb } from "../util/hexToRgba";

const StyledCalendar = styled(Calendar)`
  // Hides the month/year picker - we should always be using months
  .ant-picker-calendar-mode-switch {
    display: none !important;
  }
`

const StyledButton = styled(Button)`
  display: flex;
  height: initial;
  text-align: left;
  /* justify-content: space-between; */
  align-items: center;
  &:hover {
    .anticon {
      opacity: 1;
    }
  }
  .anticon {
    opacity: 0;
    transition: all 0.5s;
  }
`

const Interviews: React.FC = () => {
  const [{
    startDateFilter,
    endDateFilter
  }, setDateFilters] = useState({
    startDateFilter: dayjs().startOf('month').toDate(),
    endDateFilter: dayjs().endOf('month').add(14, 'days').toDate()
  })
  const [selectedDate, setSelectedDate] = useState<string>()
  const [selectedInterviewsDialogOpen, setSelectedInterviewsDialogOpen] = useState(false)
  const { data, status } = useInterviews({
    limit: 9999,
    offset: 0,
    filters: {
      gte: startDateFilter,
      // Calendar may show the next month as well
      lte: endDateFilter
    }
  })

  const interviewsBucketedByDates = useMemo(() => data?.data.reduce((acc, interview) => {
    const date = dayjs(interview.date).format('YYYY-MM-DD')
    if (!acc[date]) {
      acc[date] = []
    }
    acc[date].push(interview)
    return acc
  }, {} as Record<string, JobAppInterviewWithJobApp[]>), [data])
  const selectedDateInterviews = selectedDate && interviewsBucketedByDates?.[selectedDate] ? interviewsBucketedByDates[selectedDate] : []

  const onAddedInterview = (interview: JobAppInterview & { jobApp: JobApp }) => {
    navigate(`${pages.INTERVIEWS}/${interview.id}`)
  }

  const onCalendarDateChange = (date: dayjs.Dayjs) => {
    setDateFilters({
      startDateFilter: date.startOf('month').utc().toDate(),
      endDateFilter: date.endOf('month').add(14, 'days').utc().toDate(),
    })
  }

  const onCalendarDateSelect = (date: dayjs.Dayjs) => {
    const formattedDateKey = date.format('YYYY-MM-DD')
    setSelectedDate(formattedDateKey)
    // Only show the dalog for selected date if we're not traversing to other months/years
    const interviewsOnDate = interviewsBucketedByDates?.[formattedDateKey]

    if (date.isSame(startDateFilter, 'month') && date.isSame(startDateFilter, 'year') && interviewsOnDate?.length) {
      setSelectedInterviewsDialogOpen(true)
    } else {
      setSelectedInterviewsDialogOpen(false)
    }
  }

  const onInterviewClick = (interview: JobAppInterviewWithJobApp) => {
    navigate(`${pages.INTERVIEWS}/${interview.id}`)
  }

  const dateCellRender: CalendarProps<dayjs.Dayjs>['dateCellRender'] = (date) => {
    const dateFormatted = date.format('YYYY-MM-DD')

    if (!interviewsBucketedByDates || !interviewsBucketedByDates[dateFormatted]) {
      return null
    }

    return <Space direction="vertical" size="small" style={{width: '100%'}}>
      {interviewsBucketedByDates[dateFormatted].map(interview => {

        const color = interview.jobApp.color ? hexToRgb(interview.jobApp.color) : undefined

        const background = color ? `rgba(${color.r}, ${color.g}, ${color.b}, 0.2)` : undefined

        return <div 
          key={interview.id} 
          style={{
            background,
            borderRadius: '1rem',
            paddingLeft: '0.5rem',
            paddingRight: '0.5rem',
          }}
        >
          {interview.jobApp.company}
        </div>
      })}
    </Space>
  }

  return <Space 
    size="middle"
    direction="vertical"
    style={{
      marginBottom: '40px'
    }}>
    <ThemedModal 
      open={selectedInterviewsDialogOpen} 
      onCancel={() => setSelectedInterviewsDialogOpen(false)} 
      title={`Interviews on ${dayjs(selectedDate).format('MMMM D, YYYY')}`}
      destroyOnClose
      footer={null}
    >
      {selectedDateInterviews.map((interview) => {
        const { jobApp, title } = interview

        return <StyledButton
          key={interview.id}
          type="text"
          block
          onClick={() => onInterviewClick(interview)}
          icon={<Avatar src={interview.jobApp.companyLogoUrl} size="large">{jobApp.company}</Avatar>}
        >
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            marginLeft: '1rem',
            overflow: 'hidden',
          }}>
            <div style={{
              overflow: 'hidden',
              marginRight: "1rem"
            }}>
              <Text style={{
                display: 'block',
                fontSize: '1rem',
                textOverflow: 'ellipsis',
                overflow: 'hidden'
              }}
              strong
              >
                {jobApp.company}
              </Text>
              <Text style={{
                display: 'block',
                textOverflow: 'ellipsis',
                overflow: 'hidden'
              }}>
                {title}
              </Text>
              {jobApp.title && <Text style={{
                display: 'block',
                textOverflow: 'ellipsis',
                overflow: 'hidden'
              }}
              type="secondary"
              >
                {jobApp.title}
              </Text>}
            </div>
            <RightOutlined />
          </div>
        </StyledButton>
      })}
    </ThemedModal>
    <div>
      <PageTitleHeading style={{marginBottom: '5px'}}>Interviews</PageTitleHeading>
      <Typography.Paragraph style={{margin: 0}}>
        These are your interviews throughout all your job applications. You can add new interviews by going to the interviews tab in a job application on your board, or by clicking the button below.
      </Typography.Paragraph>
    </div>
    <Space direction="vertical" size="large" style={{width: '100%'}}>
      <AddInterviewButton onAddedInterview={onAddedInterview} />
      {!data && <Spin size="large" />}
      <div style={{
        background: 'white',
        borderRadius: '1rem',
        padding: '0.5rem 1rem',
      }}>

        {data && <StyledCalendar 
          dateCellRender={dateCellRender}
          onChange={onCalendarDateChange}
          mode="month"
          onSelect={onCalendarDateSelect}
          disabledDate={() => {
            if (status === "loading") {
              return true
            }

            return false
          }}
        />}
      </div>
    </Space>
  </Space>
}

export default Interviews;
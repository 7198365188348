/* eslint-disable import/no-extraneous-dependencies */
import { Dayjs } from 'dayjs';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import generatePicker from 'antd/es/date-picker/generatePicker';

/**
 * A custom DatePicker based off of Ant Design to accept dayjs objects rather than the default
 * moment objects
 *
 * https://ant.design/docs/react/replace-moment
 */
const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig);

export default DatePicker;

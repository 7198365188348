import { patchForJson } from '../xhr';

export default function xhrSortJobApps(
  boardId: string,
  columnId: string,
  sortOptions: {
    by: 'title'|'company'|'appliedAt',
    descending: boolean,
  },
  accessToken: string,
) {
  return patchForJson(`/jobappboards/${boardId}/columns/${columnId}/jobapps/sort`, sortOptions, accessToken);
}

import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Form, Input } from "antd";
import { useContext, useState } from "react";
import { DATE_FORMAT_WITH_TIME } from "../../../../constants/dateTimeFormats";
import themeColors from "../../../../constants/themeColors";
import { JobAppInterviewContext } from "../../../../contexts/JobAppInterviewProvider";
import JobApp from "../../../../types/JobApp";
import JobAppInterview from "../../../../types/JobAppInterview";
import ThemedModal from "../../../ThemedModal";
import SearchJobAppsInputSelect from "./SearchJobAppsInputSelect/SearchJobAppsInputSelect";
import DatePicker from '../../../../components/DatePicker'
import dayjs from "dayjs";
import notifyError from "../../../../util/notifyError";

interface FormValues {
  interviewDate?: dayjs.Dayjs
  title?: string
  jobApp?: JobApp
}

interface Props {
  onAddedInterview: (interview: JobAppInterview & {
    jobApp: JobApp
  }) => void;
}

const AddInterviewButton: React.FC<Props> = ({
  onAddedInterview,
}) => {
  const {
    createInterview
  } = useContext(JobAppInterviewContext);
  const [visible, setVisible] = useState(false);
  const [saving, setSaving] = useState(false);
  const [form] = Form.useForm();

  const createLabel = (text: string) => <span
    style={{
      color: themeColors.background.dark,
    }}>
    {text}
  </span>

  const onSubmit = async (values: FormValues) => {
    const { interviewDate, jobApp, title } = values;
    if (!interviewDate || !jobApp || !title) {
      return;
    }
    try {
      setSaving(true)
      const createdInterview = await createInterview({
        jobAppId: jobApp.id,
        date: interviewDate.utc().toISOString(),
        title,
      });
      onAddedInterview({
        ...createdInterview,
        jobApp,
      });
      setVisible(false)
      form.resetFields();
    } catch (err) {
      notifyError(`Failed to create interview`, (err as Error).message)
    } finally {
      setSaving(false)
    }
  }

  const onJobAppSelected = (jobApp: JobApp) => {
    form.setFieldsValue({
      jobApp,
    })
  }

  const onTitleChanged = (title: string) => {
    form.setFieldsValue({
      title,
    })
  }

  const onDateChanged = (interviewDate: dayjs.Dayjs | null) => {
    form.setFieldsValue({
      interviewDate,
    })
  }
  
  return <>
    <ThemedModal 
      title="Add Interview"
      open={visible}
      onCancel={() => setVisible(false)}
      onOk={() => form.submit()}
      okText="Save"
      destroyOnClose
      okButtonProps={{
        disabled: saving,
      }}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
        onKeyPress={(e) => e.key === 'Enter' && form.submit()}
      >
        <Form.Item
          name="jobApp"
          label={createLabel('Job Application')}
          rules={[{
            required: true,
            message: 'Please select a job application',
          }]}
        >
          <SearchJobAppsInputSelect onJobAppSelected={onJobAppSelected} />
        </Form.Item>
        <Form.Item 
          label={createLabel('Interview Date')}
          name="interviewDate"
          rules={[{
            required: true,
            message: 'Please select an interview date',
          }]}
        >
          <DatePicker
            value={dayjs(form.getFieldValue('interviewDate')) || undefined}
            style={{width: '100%'}}
            showTime={{ format: 'h:mm A' }}
            format={DATE_FORMAT_WITH_TIME}
            suffixIcon={<FontAwesomeIcon icon={faCalendar} />}
            onChange={(dayjsDate, dateString) => {
              onDateChanged(dayjsDate)
              // onDateChanged(dayjsDate?.utc().toISOString() || "")
            }}
          />
        </Form.Item>
        <Form.Item 
          name="title"
          label={createLabel('Interview Title')}
          rules={[{
            required: true,
            message: 'Please set an interview title',
          }]}
        >
          <Input onChange={(e) => onTitleChanged(e.target.value)} placeholder='Technical Interview' />
        </Form.Item>
      </Form>
    </ThemedModal>
    <Button 
      type="primary"
      style={{
        width: 'min-content'
      }}
      onClick={() => setVisible(true)}
    >
    Add Interview
    </Button>
  </>
}

export default AddInterviewButton;

import React, { useState } from 'react';
import styled from 'styled-components';
import ThemedModal from './ThemedModal';
import { ConfirmModalContextData } from '../contexts/ConfirmModalProvider';
import { Button } from 'antd';

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
  button:first-child {
    margin-right: 1rem;
  }
`;

const Title = styled.h1`
  font-size: 1.25em;
  font-weight: 600;
  margin-top: .5em;
`;

type Props = {
  visible: boolean,
  confirmModalData: ConfirmModalContextData,
  onClose: () => void
};

const ConfirmModal: React.FC<Props> = ({
  visible,
  confirmModalData,
  onClose,
}) => {
  const [disabled, setDisabled] = useState(false);
  const {
    onOk, content, title, okButton,
  } = confirmModalData;

  function close() {
    onClose();
    setDisabled(false);
  }

  async function confirm() {
    setDisabled(true);
    await onOk();
    close();
  }

  return (
    <ThemedModal
      visible={visible}
      maskClosable
      onCancel={close}
      footer={null}
    >
      {title && <Title>{title}</Title>}
      {content && <p>{content}</p>}
      <Buttons>
        <Button disabled={disabled} onClick={close}>Close</Button>
        {okButton
          ? React.cloneElement(okButton, {
            onClick: confirm,
            disabled,
          })
          : <Button
            disabled={disabled}
            type="primary"
            onClick={confirm}
            autoFocus
          >
            Confirm
          </Button>}
      </Buttons>
    </ThemedModal>
  );
};

export default ConfirmModal;

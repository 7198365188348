import { deleteFor } from '../xhr';

export default function xhrDeleteInterviewQuestion(
  accessToken: string,
  questionId: string,
) {
  return deleteFor(
    `/interview-questions/${questionId}`, accessToken
  )
}

import React, { useContext } from 'react';
import {
  Form, Input,
} from 'antd';
import { Helmet } from 'react-helmet';
import { Store } from 'antd/lib/form/interface';
import ThemedModal from '../ThemedModal';
import Button from '../theme/Button/Button';
import notifyError from '../../util/notifyError';
import JobAppColumn from '../../types/JobAppColumn';
import { JobAppColumnContext } from '../../contexts/JobAppColumnProvider';

interface EditJobAppBoardColumnModalProps {
  column: JobAppColumn
  visible: boolean
  onClose: () => void
}

const EditJobAppBoardColumnModal: React.FC<EditJobAppBoardColumnModalProps> = ({
  column,
  onClose,
  visible,
}) => {
  const [form] = Form.useForm();
  const { updateJobAppColumn } = useContext(JobAppColumnContext);
  // TODO: Pressing escape while editing input will close the modal. This should not happen.

  function resetAndClose() {
    onClose();
  }

  async function onOk(data: Store) {
    if (!column) {
      throw new Error('Column undefined for edit column modal');
    }
    try {
      await updateJobAppColumn(column.boardId, column.id, {
        name: data.name,
      });
      resetAndClose();
    } catch (err) {
      notifyError('Unable to edit job column', (err as Error).message);
    }
  }

  const formName = 'edit-column';

  return (
    <ThemedModal
      title={`Rename ${column?.name || ''}`}
      open={visible}
      destroyOnClose
      onCancel={resetAndClose}
      footer={(
        <>
          <Button onClick={resetAndClose}>
            Close
          </Button>
          <Button color="blue" htmlType="submit" form={formName}>
            Save
          </Button>
        </>
      )}
    >
      <Helmet>
        <title>{`Edit ${column?.name || 'unknown'} column`}</title>
      </Helmet>
      <Form
        name={formName}
        onFinish={onOk}
        layout="vertical"
        form={form}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{
            required: true,
            message: 'Name is required',
          }]}
          initialValue={column?.name}
        >
          <Input
            autoFocus
            placeholder="New column name"
          />
        </Form.Item>
      </Form>
    </ThemedModal>
  );
};

export default EditJobAppBoardColumnModal;

import { Input } from "antd";
import styled from "styled-components";
import themeColors from "../../../../../../constants/themeColors";

export const InterviewQuestionQuestionInputStyled = styled(Input)`
  background: none;
  border: none;
  border-radius: 5px;
  padding: 5px;
  &:focus {
    background: white !important;
  }
  &:hover {
    background: ${themeColors.background.offWhite};
  }
  font-weight: 500;
`

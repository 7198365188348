import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import {
  List, message, Empty, Skeleton, Button,
} from 'antd';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Document from '../types/Document';
import EditableInput from '../components/EditableText';
import SearchBar from '../components/SearchBar';
import UploadDocumentButton from '../components/UploadDocumentButton';
import DocumentListItem from '../components/JobAppBoard/DocumentListItem';
import {ConfirmModalContext} from '../contexts/ConfirmModalProvider';
import notifyError from '../util/notifyError';
import { DocumentContext } from '../contexts/DocumentProvider';
import PageTitle from '../components/JobAppBoard/common/PageTitle/PageTitle';

const Wrapper = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 30px;
`;

const SearchOrUpload = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin: 0 0 20px 0;
  button {
    width: 100%;
    margin-bottom: 10px;
  }
  @media screen and (min-width: 600px) {
    flex-direction: row;
    button {
      width: initial;
      margin-bottom: 0;
    }
  }
`;

const SkeletonDocument = styled(Skeleton)`
  h3 {
    height: 45px !important;
  }
`;

function SkeletonDocuments() {
  const roughDocumentCount = Math.ceil(window.innerHeight / 70) - 4;
  const skeletonDocuments = new Array(roughDocumentCount)
    .fill(<SkeletonDocument active paragraph={false} />);

  return (
    <div style={{ overflow: 'hidden' }}>
      {skeletonDocuments}
    </div>
  );
}

function Documents() {
  const { documents, deleteDocument, updateDocument } = useContext(DocumentContext);
  const confirmModal = useContext(ConfirmModalContext);
  const [deletingDocIds, setDeletingDocIds] = useState<string[]>([]);
  const [documentsDisplayed, setDocumentsDisplayed] = useState<Document[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const loadedDocs = documents !== null;

  useEffect(() => {
    if (searchTerm.length === 0) {
      setDocumentsDisplayed(documents || []);
    } else {
      const filteredDocs = documents?.filter((doc) => {
        const docName = doc.name.toLowerCase();
        const searchTermLowerCase = searchTerm.toLowerCase();
        if (docName.includes(searchTermLowerCase) || searchTermLowerCase.includes(docName)) {
          return true;
        }
        return false;
      });
      setDocumentsDisplayed(filteredDocs || []);
    }
  }, [searchTerm, documents]);

  async function deleteDoc(doc: Document) {
    try {
      confirmModal.showConfirmModal({
        title: `Are you sure you want to delete ${doc.name}?`,
        okButton: <Button danger type="primary">Delete</Button>,
        onOk: async () => {
          setDeletingDocIds([...deletingDocIds, doc.id]);
          await deleteDocument(doc.id);
          message.success(`Deleted ${doc.name}`);
          const deletingDocIdsClone = [...deletingDocIds];
          deletingDocIdsClone.splice(deletingDocIdsClone.indexOf(doc.id), 1);
          setDeletingDocIds(deletingDocIdsClone);
        },
      });
    } catch (err) {
      notifyError('Unable to delete document', (err as Error).message);
    }
  }

  async function changeDocName(doc: Document, newName: string) {
    try {
      await updateDocument(doc.id, {
        name: newName,
      });
    } catch (err) {
      notifyError('Unable to change document name', (err as Error).message);
    }
  }

  return (
    <>
      <Helmet>
        <title>Documents</title>
      </Helmet>
      <Wrapper>
        <PageTitle>Documents</PageTitle>
        <SearchOrUpload>
          <SearchBar
            placeholder="Search your documents"
            searchFunction={setSearchTerm}
            clearFunction={() => setSearchTerm('')}
            style={{ marginRight: '15px' }}
          />
          <UploadDocumentButton trigger={(
            <Button
              type="primary"
              icon={<FontAwesomeIcon icon={faPlus} style={{ marginRight: '0.5rem' }} />}
            >
              Upload New
            </Button>
          )}
          />
        </SearchOrUpload>
        {!loadedDocs && <SkeletonDocuments />}
        {loadedDocs
          && documentsDisplayed.length === 0
          && !searchTerm
          && <Empty description="You have no uploaded documents" />}
        {loadedDocs
          && documentsDisplayed.length === 0
          && searchTerm
          && <Empty description={`No results matching "${searchTerm}"`} />}
        {loadedDocs && documentsDisplayed.length > 0 && (
          <List
            style={{ overflow: 'auto' }}
            dataSource={documentsDisplayed}
            renderItem={(doc) => (
              <DocumentListItem
                key={doc.id}
                doc={doc}
                onDelete={() => deleteDoc(doc)}
                disabledDelete={deletingDocIds.includes(doc.id)}
                style={{ fontSize: '1rem' }}
              >
                <EditableInput
                  value={doc.name}
                  textLabel={`Edit document name ${doc.name}`}
                  inputLabel={`New document name for ${doc.name}`}
                  characterLimit={200}
                  onChange={(newName) => changeDocName(doc, newName)}
                  inputProps={{ style: { marginTop: '1rem', marginBottom: '1rem' } }}
                  textProps={{ style: { marginTop: '1rem', marginBottom: '1rem' } }}
                />
              </DocumentListItem>
            )}
          />
        )}
      </Wrapper>
    </>
  );
}

export default Documents;

import * as Sentry from '@sentry/react';

export class StatusCodeError extends Error {
  code: number;

  requestUrl?: string;

  constructor(code: number, requestUrl?: string) {
    super(`An internal error happened (code ${code}). Please try again later, or contact support@stipplo.com for assistance.`);
    this.code = code;
    this.requestUrl = requestUrl;
  }
}

const GENERIC_NETWORK_ERROR = 'Internal error. Please try again later, or contact support@stipplo.com for assistance.';

export const get = async (
  url: string,
  authToken: string,
  options: RequestInit = {},
) => {
  try {
    const res = await fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      ...options,
    });
    return res;
  } catch (err) {
    Sentry.captureException(err);
    throw new Error(GENERIC_NETWORK_ERROR);
  }
};

export const getFile = async (
  url: string,
  authToken: string,
  options: RequestInit = {},
) => fetch(url, {
  method: 'GET',
  headers: {
    Authorization: `Bearer ${authToken}`,
  },
  ...options,
});

export const post = async (
  url: string,
  body: Object,
  authToken: string,
  options: RequestInit = {},
) => {
  try {
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(body),
      ...options,
    });
    return res;
  } catch (err) {
    Sentry.captureException(err);
    throw new Error(GENERIC_NETWORK_ERROR);
  }
};

export const patch = async (
  url: string,
  body: Object,
  authToken: string,
  options: RequestInit = {},
) => {
  try {
    const res = await fetch(url, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(body),
      ...options,
    });
    return res;
  } catch (err) {
    Sentry.captureException(err);
    throw new Error(GENERIC_NETWORK_ERROR);
  }
};

export const deleteFor = async (
  url: string,
  authToken: string,
  options: RequestInit = {},
) => {
  try {
    const res = await fetch(url, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      ...options,
    });
    if (!res.ok) {
      throw new StatusCodeError(res.status, url);
    }
  } catch (err) {
    Sentry.captureException(err);
    throw err;
  }
};

export const putFor = async (
  url: string,
  authToken: string,
  options: RequestInit = {},
) => {
  try {
    const res = await fetch(url, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      ...options,
    });
    if (!res.ok) {
      throw new StatusCodeError(res.status, url);
    }
  } catch (err) {
    Sentry.captureException(err);
    throw err;
  }
};

export const postForJson = async (
  url: string,
  body: Object,
  authToken: string,
  options: RequestInit = {},
) => {
  let res;
  try {
    res = await post(url, body, authToken, options);
    if (!res.ok) {
      throw new StatusCodeError(res.status, url);
    }
    return res.json();
  } catch (err) {
    if (err instanceof StatusCodeError && err.code === 403) {
      const json = await res?.json();
      throw new Error(json.message);
    } else {
      Sentry.captureException(err);
      throw err;
    }
  }
};

export const postFormForJson = async (
  url: string,
  form: FormData,
  authToken: string,
  options: RequestInit = {},
) => {
  try {
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      body: form,
      ...options,
    });
    if (!res.ok) {
      throw new StatusCodeError(res.status, url);
    }
    return res.json();
  } catch (err) {
    Sentry.captureException(err);
    throw err;
  }
};

export const patchForJson = async (
  url: string,
  body: Object,
  authToken: string,
  options: RequestInit = {},
) => {
  try {
    const res = await patch(url, body, authToken, options);
    if (!res.ok) {
      throw new StatusCodeError(res.status, url);
    }
    const json = await res.json();
    return json;
  } catch (err) {
    Sentry.captureException(err);
    throw err;
  }
};

export const getJson = async (url: string, authToken: string, options: RequestInit = {}) => {
  try {
    const res = await get(url, authToken, options);
    if (!res.ok) {
      throw new StatusCodeError(res.status, url);
    }
    const json = await res.json();
    return json;
  } catch (err) {
    Sentry.captureException(err);
    throw err;
  }
};

import * as Sentry from '@sentry/react';

type ClearBitCompany = {
  name: string, // Company name
  domain: string, // Company domain
  logo: string // Logo URL
};

async function searchForCompanies(searchTerm: string): Promise<ClearBitCompany[]> {
  // Putting illegal search characters in a URL will make the browser misunderstand the request
  const encodedSearch = encodeURIComponent(searchTerm);
  // Clearbit's API does not require authentication
  const url = `https://autocomplete.clearbit.com/v1/companies/suggest?query=${encodedSearch}`;
  try {
    const res = await fetch(url);
    if (!res.ok) {
      Sentry.captureMessage(`Bad status response from Clearbit (${res.status}, url: ${url})`);
      return [];
    }
    return res.json();
  } catch (err) {
    Sentry.captureMessage(`Network request failed for Clearbit request to ${url}`);
    return [];
  }
}

export default searchForCompanies;

import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: solid 1px #d9d9d9;
  background: white;
  // Override react-quill's default toolbar padding
  padding: 3px !important;
`;

interface RichTextToolbarProps {
}

const RichTextToolbar: React.FC<RichTextToolbarProps> = () => (
  /** toolbar id must remain constant for react-quill, as well as all classNames */
  <Container id="toolbar">
    <span className="ql-formats">
      <select className="ql-header" defaultValue="0" onChange={(e) => e.persist()}>
        <option value="0" aria-label="Normal" />
        <option value="3" aria-label="Heading 3" />
        <option value="2" aria-label="Heading 2" />
        <option value="1" aria-label="Heading 1" />

      </select>
    </span>
    <span className="ql-formats">
      <button className="ql-bold" type="button" aria-label="Bold" />
      <button className="ql-italic" type="button" aria-label="Italic" />
      <button className="ql-underline" type="button" aria-label="Underline" />
    </span>
    <span className="ql-formats">

      <button className="ql-list" value="ordered" type="button" aria-label="Ordered List" />
      <button className="ql-list" value="bullet" type="button" aria-label="Bullet List" />
    </span>
  </Container>
);

export default RichTextToolbar;
